import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';

import { User } from '../../../api/src/models/user.entity';
import { GameName } from '../../../api/src/types/game';
import { GroupedPlanInfo, SubscriptionRequest } from '../../../api/src/types/payment';
import axios from '../config/axios';

//we need a copy of this on the front and end since it's a const enum
export enum SubscriptionLevels {
  Small = 10,
  Medium = 20,
  Large = 30
}

export const GamePlanAccess: { [key in GameName]: SubscriptionLevels } = {
  "Guess Monster": SubscriptionLevels.Small,
  "Drag n Drop": SubscriptionLevels.Small,
  "One by One": SubscriptionLevels.Small,
  "Reveal": SubscriptionLevels.Small,
  "Check Off": SubscriptionLevels.Small,
  "Catch": SubscriptionLevels.Medium,
  "Sort": SubscriptionLevels.Medium,
}

export type PlanDetail = {
  level: SubscriptionLevels,
  name: string,
  label: string,
  color: SemanticCOLORS
}

export const PlanDetails : { [key in SubscriptionLevels] : PlanDetail } = {
  10: { level: SubscriptionLevels.Small, name: "Pika", label: "Basics", color: "teal" },
  20: { level: SubscriptionLevels.Medium, name: "Yeti", label: "Serious", color: "green" },
  30: { level: SubscriptionLevels.Large, name: "Godzilla", label: "All In", color: "orange" },
}

export function getSubscriptionPlans() {
  return axios.get<GroupedPlanInfo>(`/pay/plan`);
}

export function subscribeUser(details: SubscriptionRequest) {
  return axios.post<User>(`/pay/subscribe`, details);
}

export function getPaymentInfo() {
  return axios.get<any>(`/pay/current`);
}

// works for downgrade too
export function upgradeUser(details: SubscriptionRequest) {
  return axios.post<User>(`/pay/subscribe`, details);
}

export function updateUserCard(details: SubscriptionRequest) {
  return axios.post<void>(`/pay/update-card`, details);
}

export function unsubscribeUser(reason: string) {
  return axios.post<User>(`/pay/unsubscribe`, {
    reason: reason
  });
}

export function validatePromoCode(code: string) {
  return axios.post<any>(`/pay/validate-promo`, {
    code: code
  });
}