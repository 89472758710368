import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { BrowserRouter, Link, Redirect, Route, Switch } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import { Container, Divider, Grid, Header, List, Segment } from 'semantic-ui-react';

import AdminHome from './components/admin/Home';
import GamesHome from './components/games/Home';
import FAQ from './components/pages/FAQ';
import HelpPage from './components/pages/HelpPage';
import HomePage from './components/pages/HomePage';
import LoginPage from './components/pages/LoginPage';
import PricingPage from './components/pages/Pricing';
import Thanks from './components/pages/Thanks';
import ErrorDisplayer from './components/shared/ErrorDisplayer';
import NewsletterSubscription from './components/shared/NewsletterSubscription';
import TopMenu from './components/shared/TopMenu';
import ConfettiTool from './components/tools/ConfettiTool';
import Profile from './components/user/Profile';
import { AuthContext, AuthContextProvider } from './contexts/auth';
import { ErrorContext, ErrorContextProvider } from './contexts/error';
import { LookupsContextProvider } from './contexts/lookups';
import { ToolsContextProvider } from './contexts/tools';
import { ExternalLink } from './services/html';

function App() {
  return useObserver(() => (
    <BrowserRouter>
      <ErrorContextProvider>
        <AuthContextProvider>
          <LookupsContextProvider>
            <ToolsContextProvider>
              <AuthContext.Consumer>
                {auth => auth.firebase_checked ? (
                  <Container>
                    <ErrorContext.Consumer>
                      {e => e.type && !e.viewed && <Redirect to={`/error/${e.type}`} />}
                    </ErrorContext.Consumer>

                    <TopMenu />

                    <ConfettiTool />

                    <Segment basic>
                      <Switch>
                        <Route path="/error/:error_type?"><ErrorDisplayer /></Route>

                        <Route path="/login"><LoginPage /></Route>

                        {auth.user && (
                          <Route path="/profile"><Profile /></Route>
                        )}

                        {auth.user?.is_admin && (
                          <Route path="/admin"><AdminHome /></Route>
                        )}
                        
                        <Route path="/game"><GamesHome /></Route>
                        <Route path="/faq"><FAQ /></Route>
                        <Route path="/thanks"><Thanks /></Route>
                        <Route path="/pricing"><PricingPage /></Route>
                        <Route path="/help"><HelpPage /></Route>
                        
                        <Route path="/"><HomePage /></Route>
                      </Switch>
                    </Segment>
                    <Segment basic>
                      <Divider />
                      <Grid columns="equal" stackable>
                        <Grid.Row>
                          <Grid.Column>
                            <Header size="small"><strong>The Future of Speech Therapy</strong></Header>
                            <p>Guess Monster&reg; combines cutting edge Machine Learning with both classic and new games kids <em>love!</em></p>
                            <p>Over 300 lessons curated by internationally-recognized Speech-Language Pathologists, thousands of items, and <em>91+ quadrillion</em> game recipe combinations. All growing daily.</p>
                            <p>Plus, add <strong>custom items, themes, and recipes</strong> - create personalized games that surprise, delight and <em>teach.</em></p>
                          </Grid.Column>
                          <Grid.Column>
                            <Header size="small"><strong>Quick Links</strong></Header>
                            <List>
                              <List.Item as={Link} to="/game">Game & Recipe Search</List.Item>
                              <List.Item as={Link} to="/pricing">Pricing</List.Item>
                              <List.Item as={Link} to="/faq">FAQ</List.Item>
                              <List.Item as={Link} to="/help">Help, Support, & Feedback</List.Item>
                              <List.Item><ExternalLink href="mailto:info@guessmonster.com?subject=I need more Guess Monster in my life!">Want to Contribute?</ExternalLink></List.Item>
                            </List>
                          </Grid.Column>
                          <Grid.Column>
                            {!auth.user && <div style={{ marginBottom: "1em" }}><NewsletterSubscription /></div> }
                            
                            <div>
                              <SocialIcon target="_blank" url="https://www.instagram.com/guessmonstergames/" style={{ height: 30, width: 30, marginRight: ".5em" }} />
                              <SocialIcon target="_blank" url="https://www.facebook.com/guessmonstergames/" style={{ height: 30, width: 30 }} />
                            </div>

                            <Divider />

                            <p>
                              Proudly headquartered in Boston, MA <br />
                              <ExternalLink href="mailto:info@guessmonster.com?subject=I need more Guess Monster in my life!">info@guessmonster.com</ExternalLink>
                            </p>
                            <p>
                              <ExternalLink href="/legal/GuessMonster-TOS.pdf">Terms of Service</ExternalLink> &bull; <ExternalLink href="/legal/GuessMonster-PP.pdf">Privacy Policy</ExternalLink>
                            </p>
                            <p>
                              Copyright &copy; 2021 &bull; All rights reserved &bull; <Link to="/thanks">Attributions</Link>
                            </p>                            
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Container>
                ) : null}
              </AuthContext.Consumer>
            </ToolsContextProvider>
          </LookupsContextProvider>
        </AuthContextProvider>
      </ErrorContextProvider>
    </BrowserRouter>
  ));
}

export default App;
