import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../contexts/auth';

const UpgradeLink:React.FC = (props) => {
  const auth = useContext(AuthContext);

  return (
    <Link to={auth.user ? "/profile/billing/upgrade" : "/login"} {...props} />
  )
}

export default UpgradeLink;