import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { Route, Switch, useRouteMatch, Link } from 'react-router-dom';
import { Header, Button } from 'semantic-ui-react';

import { allMyGameThemes } from '../../services/gamethemes';
import PageHeader from '../shared/PageHeader';
import AddEditTheme from '../themes/AddEditTheme';
import ThemeList from '../themes/ThemeList';
import MustBeSubscribed from '../shared/MustBeSubscribed';
import { SubscriptionLevels } from '../../services/payment';

function UserThemes() {
  const { path } = useRouteMatch();
 
  return useObserver(() => allMyGameThemes.current() ? (
    <>
      <PageHeader title="Game Themes" backText="Back to My Themes" />

      <Switch>
        <Route path={`${path}/theme/:theme_id?`}><AddEditTheme /></Route>
        <Route>
          <Header className="relaxed">
            My Themes
            <Header.Subheader>
              We have an ever-growing collection of awesome themes - from space to unicorns, but you're welcome to make your own!
              You can customize pretty much everything about your games - backgrounds, items, fonts, colors. We're trying to make it easy for everyone,
              but it's a bit complicated still. Please reach out if you'd like some help making a custom theme!
            </Header.Subheader>
          </Header>

          <MustBeSubscribed minLevel={SubscriptionLevels.Small}>
            <div className="profile-section">
              {allMyGameThemes.current().length > 0 ?
                <ThemeList showList="my" /> :
                <em>You don't have any themes yet. Make one!</em>
              }
            </div>

            <Button positive as={Link} to={`${path}/theme`}>Add a Theme</Button>
          </MustBeSubscribed>
        </Route>
      </Switch>
    </>
  ): null)
}

export default UserThemes;