import React from 'react';
import { Header } from 'semantic-ui-react';

import PriceSheet from '../payments/PriceSheet';
import { PlanDetails, SubscriptionLevels } from '../../services/payment';

function PricingPage() {
  return (
    <>
      <Header className="relaxed">
        Guess Monster Pricing
        <Header.Subheader>
          To get the most out of Guess Monster, you have to subscribe, but it's absolutely worth it! The <strong>{PlanDetails[SubscriptionLevels.Small].name}</strong> is 
          our most popular plan for parents and small practices, but we're here to answer any questions you have to help you decide what's right for you!
        </Header.Subheader>
      </Header>
      
      <PriceSheet />
    </>
  )
}

export default PricingPage