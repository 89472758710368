import React, { useContext } from 'react';
import CanvasDraw from 'react-canvas-draw';

import { ToolsContext, DrawModes } from '../../contexts/tools';

function DrawOnGames() {
  const tools = useContext(ToolsContext);

  return tools.draw > DrawModes.Hidden && <CanvasDraw disabled={tools.draw === DrawModes.Behind} style={{ position: "absolute", zIndex: tools.draw, backgroundColor: null, width: "100%", height: "100%" }} hideGrid />
}

export default DrawOnGames;