import 'react-vis/dist/style.css';

import _ from 'lodash';
import { useObserver } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useMediaQuery } from 'react-responsive';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Button, Card, Header, Icon, Input, Pagination, Placeholder } from 'semantic-ui-react';
import { SemanticWIDTHS } from 'semantic-ui-react/dist/commonjs/generic';

import { FeaturedRecipe, GameRecipe } from '../../../../api/src/models/gamerecipe.entity';
import { AuthContext } from '../../contexts/auth';
import { ErrorContext } from '../../contexts/error';
import { PlayContextProvider } from '../../contexts/play';
import { logSearchEvent } from '../../services/analytics';
import { getFeaturedGameRecipes, searchRecipes } from '../../services/gamerecipes';
import { ExternalLink } from '../../services/html';
import { toFriendlyString } from '../../services/intl';
import { GamePlanAccess } from '../../services/payment';
import { PagedSearchResult } from '../../services/search';
import { GameComponents } from '../shared/GameBase';
import LessonSearchDropdown from '../shared/searchers/LessonSearchDropdown';
import { FeaturedGameRecipeCard, GameRecipeCard } from './GameRecipeCard';
import PlayGame from './PlayGame';


function GamesHome () {
  const [pagedResults, setPagedResults] = useState<PagedSearchResult<GameRecipe>>();
  const [filter, setFilter] = useState("");
  const [lessonFilter, setLessonFilter] = useState<{lesson_id: number, name: string}>();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [featured, setFeatured] = useState<FeaturedRecipe[]>();

  const { path } = useRouteMatch();
  const isMobilePortrait = useMediaQuery({ maxWidth: 768, orientation: "portrait" });

  const auth = useContext(AuthContext);
  const { processApiError } = useContext(ErrorContext);
  
  useEffect(() => {
    search();
  }, 
    // eslint-disable-next-line
    [filter, lessonFilter, pageNumber, auth.user]
  );

  useEffect(() => {
    getFeaturedGameRecipes()
      .then(r => setFeatured(r.data))
      .catch(processApiError);
  }, 
    // eslint-disable-next-line 
    []
  );

  const search = () => {
    setLoading(true);

    searchRecipes({
      query: filter,
      lesson_id: lessonFilter?.lesson_id,
      page: pageNumber,
      pageSize: 24
    })
    .then(r => {
      setPagedResults(r.data);
      setLoading(false);
      logSearchEvent(filter);
    })
    .catch(processApiError)
  }

  const placeholder = (
    <Placeholder style={{ width: 200, marginTop: "2em" }}>
      <Placeholder.Image square />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder>
  );

  return useObserver(() => (
    <PlayContextProvider>
      <Switch>
        <Route path={`${path}/play/:game_name?`} component={PlayGame} />
        <Route>
          <Header size="large">
            <strong>Start Here</strong> - Featured Recipes
            <Header.Subheader>Check out the backstory to some of our featured recipes on our instagram - <ExternalLink href="https://www.instagram.com/guessmonstergames/">@guessmonstergames</ExternalLink></Header.Subheader>
          </Header>

          {featured && (
            <Card.Group itemsPerRow="5" stackable>
              {featured.map(r => <FeaturedGameRecipeCard key={r.recipe_id} featured={r} />)}
            </Card.Group>

          )}

          {!isMobilePortrait && (
            <>
              <Header size="large">
                <strong>Advanced Mode</strong> - Build Your Own Game
                <Header.Subheader>Pick a game to start and design your own recipe. You can always do that starting from any existing recipe too!</Header.Subheader>
              </Header>

              <Card.Group stackable itemsPerRow={GameComponents.length as SemanticWIDTHS}>
                {GameComponents.map(c => {
                  const has_access = GamePlanAccess[c.name] <= auth.user?.subscription_level_id || 0;

                  return (
                    <Card key={c.name} color="green" link as={Link}
                      to={has_access ? `/game/play/${_.kebabCase(c.name)}` : (auth.user ? "/profile/billing/upgrade" : "/login")}
                      style={{ backgroundColor: !has_access ? "#eeeeee" : null }}
                    >
                      <Card.Content style={has_access ? {fontWeight: "bold"} : undefined}>
                        {!has_access && <Icon style={{ float: "right" }} name="lock" color="yellow" />}
                        {c.name}
                      </Card.Content>
                    </Card>
                  )
                }
                )}
              </Card.Group>
            </>
          )}

          <Header size="large">
            <strong>Explore</strong> - Search All Public Recipes
            <Header.Subheader>Use the <strong>magic search</strong> or just start typing!</Header.Subheader>
          </Header>
          <Input icon="search" iconPosition="left" size="huge" fluid
            placeholder={`Type anything to search ${toFriendlyString(pagedResults?.total)}${lessonFilter ? ` ${lessonFilter.name}` : ""} recipes...`}
            onChange={_.debounce((_, d) => ReactDOM.unstable_batchedUpdates(() => { setFilter(d.value); setPageNumber(1); }), 300)}
            action={<LessonSearchDropdown mode="tree" onItemSelected={setLessonFilter} trigger={<Button content="Magic Search" icon="magic" labelPosition="right" />} />}
          />
        
          {loading || !pagedResults ? placeholder : (
            <Card.Group stackable itemsPerRow="3">
              {pagedResults.data.map(r => <GameRecipeCard key={r.recipe_id} recipe={r} onDelete={() => search()} />)}
            </Card.Group>
          )}

          {pagedResults && pagedResults.total === 0 && <em style={{ marginTop: "1.5em", display: "block" }}>No public recipes fit your filter but that's OK - there are over 91 quadrillion game options - pick a Build Your Own game above and create a recipe in seconds!</em>}

          {pagedResults && pagedResults.pageCount > 1 && (
            <div style={{ marginTop: "3em", display: "flex", justifyContent: "center" }}>
              <Pagination activePage={pageNumber} totalPages={pagedResults.pageCount} onPageChange={(_, d) => setPageNumber(d.activePage as number)} />
            </div>
          )}
        </Route>
      </Switch>
    </PlayContextProvider>
  ));
}

export default GamesHome;