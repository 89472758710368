import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

import { TextWithEmoji } from '../../services/html';
import { SubscriptionLevels } from '../../services/payment';
import { allMyStudents, CurrentStudent } from '../../services/students';
import MustBeSubscribed from '../shared/MustBeSubscribed';

function StudentChooser() {
  const isMobilePortrait = useMediaQuery({ maxWidth: 768, orientation: "portrait" });
  const student_text = `Students${CurrentStudent.student ? ` (${CurrentStudent.student.name})` : ""}`;

  return useObserver(() => allMyStudents.current() && allMyStudents.current().length > 0 ? (
    <Dropdown className="tour-students" item icon={isMobilePortrait ? "student" : null} text={isMobilePortrait ? null : student_text}>
      <Dropdown.Menu>
        <MustBeSubscribed minLevel={SubscriptionLevels.Small} fallback={<React.Fragment />}>
          {allMyStudents.current().length > 0 && <Dropdown.Header>Current Student</Dropdown.Header>}

          {allMyStudents.current().map(s => (
            <Dropdown.Item active={CurrentStudent.student?.student_id === s.student_id} onClick={() => CurrentStudent.set(CurrentStudent.student?.student_id !== s.student_id ? s : null)} key={s.student_id}>{s.name}</Dropdown.Item>
          ))}
          <Dropdown.Divider />
        </MustBeSubscribed>
        <Dropdown.Item as={NavLink} to="/profile/student"><TextWithEmoji emoji="gear" text="Manage Students" /></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  ) : null);
}

export default StudentChooser;