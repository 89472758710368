import React, { useContext } from 'react';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Divider, Grid, Menu } from 'semantic-ui-react';

import { AuthContext } from '../../contexts/auth';
import { TextWithEmoji } from '../../services/html';
import BasicProfile from './BasicProfile';
import UserBilling from './UserBilling';
import UserItems from './UserItems';
import UserStudents from './UserStudents';
import UserThemes from './UserThemes';

function Profile() {
  const { path } = useRouteMatch();
  const auth = useContext(AuthContext);

  return auth.user ? (
    <Grid stackable>
      <Grid.Column width={4}>
        <Menu secondary vertical stackable>
          <Menu.Item as={NavLink} to={`${path}/`} exact><TextWithEmoji text="Basic" emoji="beach_with_umbrella" /></Menu.Item>
          <Menu.Item as={NavLink} to={`${path}/student`}><TextWithEmoji text="Students" emoji="school_satchel" /></Menu.Item>
          <Divider />
          <Menu.Item as={NavLink} to={`${path}/item`}><TextWithEmoji text="My Items & Collections" emoji="cat" /></Menu.Item>
          {/* <Menu.Item as={NavLink} to={`${path}/attribute`}><TextWithEmoji text="My Attributes" emoji="v" /></Menu.Item>
          <Menu.Item as={NavLink} to={`${path}/lesson`}><TextWithEmoji text="My Lessons" emoji="school" /></Menu.Item> */}
          <Menu.Item as={NavLink} to={`${path}/theme`}><TextWithEmoji text="My Themes" emoji="artist" /></Menu.Item>
          <Divider />
          <Menu.Item as={NavLink} to={`${path}/billing`}><TextWithEmoji text="Billing" emoji="credit_card" /></Menu.Item>
          <Menu.Item as={NavLink} to={`/help`}><TextWithEmoji text="Help" emoji="woman-tipping-hand" /></Menu.Item>
        </Menu>
      </Grid.Column>
      <Grid.Column width={12}>
        <Switch>
          <Route path={path} exact><BasicProfile /></Route>
          <Route path={`${path}/student`}><UserStudents /></Route>
          <Route path={`${path}/item`}><UserItems /></Route>
          <Route path={`${path}/theme`}><UserThemes /></Route>
          <Route path={`${path}/billing`}><UserBilling /></Route>
        </Switch>
      </Grid.Column>
    </Grid>
  ) : !auth.firebase_checked ? null : <Redirect to="/login" />
}

export default Profile;