import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button, Card, Input, Pagination, Placeholder } from 'semantic-ui-react';

import { GameTheme } from '../../../../api/src/models/gametheme.entity';
import { AuthContext } from '../../contexts/auth';
import { ErrorContext } from '../../contexts/error';
import { getThemeBackgroundStyle } from '../../services/gamethemes';
import { PagedSearchResult, searchSpecific } from '../../services/search';
import ThemePreview from './ThemePreview';

interface ThemeListProps {
  showList: "all" | "my";
  relaxed?: boolean;
  showFilter?: boolean;
  onSelect?: (theme:GameTheme) => void
}

const placeholder = (
  <Placeholder style={{ width: 200 }}>
    <Placeholder.Image square />
    <Placeholder.Line />
    <Placeholder.Line />
  </Placeholder>
);

function ThemeList(props: ThemeListProps) {
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagedResults, setPagedResults] = useState<PagedSearchResult<GameTheme>>();
  const [filter, setFilter] = useState("");

  const { showList } = props;  
  const { path } = useRouteMatch();
  const auth = useContext(AuthContext);
  const { processApiError } = useContext(ErrorContext);

  useEffect(() => {
    setLoading(true);

    const fq = showList === "all" ? {
      "$and": [{
        "$or": [
          { "name": { "$contL": filter } }
        ]
      }]
    } : {
      "$and": [{
        "$or": [
          { "name": { "$contL": filter } },
        ]},
        { "is_public": false }
    ]}

    searchSpecific<GameTheme>("game-theme", fq, null, pageNumber, 40)
      .then(r => {
        setPagedResults(r.data);
        setLoading(false)
      })
      .catch(processApiError);
  }, 
    // eslint-disable-next-line
    [filter, pageNumber, showList]
  );

  return pagedResults ? (
    <>
      {props.showFilter && (
        <div className="relaxed">
          <Input action={auth.user.is_admin && props.onSelect == null ? { icon: 'add', primary: true, as: Link, to: `${path}/theme` } : undefined} placeholder={`Filter ${pagedResults.total} themes...`} onChange={_.debounce((_, d) => setFilter(d.value), 300)} fluid />
        </div>
      )}
      
      {loading ? placeholder : (
        <Card.Group itemsPerRow={4}>
          { pagedResults.data.map(t => (
            <Card key={t.theme_id} link={props.onSelect !== undefined} raised={props.onSelect !== undefined} onClick={props.onSelect !== undefined ? () => props.onSelect(t) : undefined}>
              <div style={{ ...getThemeBackgroundStyle(t, true), width: "100%", maxHeight: 250, height: 150}} />
              <Card.Content>
                <Card.Header>{t.name}</Card.Header>
                <Card.Meta>{t.is_public ? "Public" : "Private"} Theme</Card.Meta>
              </Card.Content>
              {props.onSelect === undefined && (
                <Card.Content extra>
                  <Button.Group size="mini" basic>
                    <ThemePreview hideButtonText background={t.background_url} containerOverride={JSON.stringify(t.container_style || {})} itemOverride={JSON.stringify(t.item_style || {})} textOverride={JSON.stringify(t.font_style || {})} />
                    <Button as={Link} to={`${path}/theme/${t.theme_id}`}>Edit</Button>
                    {auth.user.is_admin && <Button title="Save As" icon="copy" as={Link} to={`${path}/theme/${t.theme_id}?copy=true`} />}
                  </Button.Group>
                </Card.Content>
              )}
            </Card>
          ))}
        </Card.Group>
      )}

      {pagedResults.total === 0 && <em style={{ marginTop: "1.5em", display: "block" }}>No items fit your filter.</em>}

      {pagedResults.pageCount > 1 && (
        <div style={{ marginTop: "3em", display: "flex", justifyContent: "center" }}>
          <Pagination activePage={pageNumber} totalPages={pagedResults.pageCount} onPageChange={(_, d) => setPageNumber(d.activePage as number)} />
        </div>
      )}
  </>
  ) : placeholder
}

export default ThemeList;