import { User } from '../../../api/src/models/user.entity';
import axios from '../config/axios';
import firebase from '../config/firebase';
import { Tour } from '../../../api/src/types/tours';

export function logOut() {
  firebase.auth().signOut();
}

export async function loginUser(user: firebase.User) {
  return axios.post<User>(`/user/login`, user);
}

export async function updateCurrentUser(user: Partial<User>) {
  return axios.patch<User>(`/user/update`, user);
}

export async function changeUserPassword(new_password: string) {
  const fbuser = firebase.auth().currentUser;
  return fbuser.updatePassword(new_password);
}

export async function userWatchedTour(user: User, tour: Tour) {
  if (user) {
    return axios.post<User>(`/user/tour?tour=${tour}`);
  }
}

export async function subscribeToNewsletter(email: string) {
  return axios.post<void>(`/user/newsletter`, {email});
}
