import { GameRecipeDefinition } from '../../../api/src/models/gamerecipe.entity';
import { User } from '../../../api/src/models/user.entity';
import firebase from '../config/firebase';

export function logLoginEvent(user: User) {
  firebase.analytics().logEvent("login", {
    user_id: user.user_id,
    user_name: user.name
  })
}

export function logSearchEvent(query: string) {
  firebase.analytics().logEvent("search", {
    search_term: query
  });
}

/** This is different from our internal GamePlay. This logs every recipe try. Our GamePlays use a timer to register actual plays. */
export function logPlayEvent(recipe: GameRecipeDefinition) {
  if (recipe.theme_id) {
    firebase.analytics().logEvent("select_content", {
      content_type: "theme",
      content_id: recipe.theme_id.toString(),
    });
  }

  if (recipe.game_name) {
    firebase.analytics().logEvent("select_content", {
      content_type: "game",
      content_id: recipe.game_name,
    });
  }

  if (recipe.lesson_id) {
    firebase.analytics().logEvent("select_content", {
      content_type: "lesson",
      content_id: recipe.lesson_id.toString(),
    });
  }
}