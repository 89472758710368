import React from 'react';
import { Header } from 'semantic-ui-react';

import PriceSheet from '../payments/PriceSheet';

function Upgrade() {  
  return (
    <>
      <Header className="relaxed">
        Change Plan
      </Header>

      <PriceSheet />
    </>
  )
}

export default Upgrade;