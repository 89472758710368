import { Emoji } from 'emoji-mart';
import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import { Dropdown, Image, Menu } from 'semantic-ui-react';

import { AuthContext } from '../../contexts/auth';
import { ToolsContext } from '../../contexts/tools';
import { TextWithEmoji } from '../../services/html';
import { SubscriptionLevels } from '../../services/payment';
import { logOut } from '../../services/user';
import AdminMenuItems from '../admin/AdminMenuItems';
import StudentChooser from '../user/StudentChooser';
import MustBeSubscribed from './MustBeSubscribed';
import UpgradeLink from './UpgradeLink';

function TopMenu() {
  const auth = useContext(AuthContext);
  const tools = useContext(ToolsContext);
  const isMobilePortrait = useMediaQuery({ maxWidth: 768, orientation: "portrait" });

  return (
    <Menu secondary>
      <Menu.Item as={NavLink} to="/" exact style={{padding: ".4em"}}><img alt="Guess Monster" src='/monster.svg' style={{width: 50}} /></Menu.Item>
      <Menu.Item className="tour-games-link" as={NavLink} to="/game" icon={isMobilePortrait ? "game" : null} content={isMobilePortrait ? null : "Games"} />
      <AdminMenuItems />

      <Menu.Menu position="right">
        {auth.user && (
          <>
            <Dropdown className="tour-tools" item icon={isMobilePortrait ? "wrench" : null} text={isMobilePortrait ? null : "Tools"}>
              <Dropdown.Menu>
                <MustBeSubscribed minLevel={SubscriptionLevels.Medium} fallback={<Dropdown.Item as={UpgradeLink}><TextWithEmoji emoji="lock" text="Confetti!" style={{ color: "silver" }} /></Dropdown.Item>}>
                  <Dropdown.Item onClick={() => tools.toggleConfetti()}><TextWithEmoji emoji="tada" text="Confetti!" /></Dropdown.Item>
                </MustBeSubscribed>

                <Dropdown.Item onClick={() => tools.toggleSound()}><TextWithEmoji emoji={tools.sound ? "bell" : "no_bell"} text="Sounds" /></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <StudentChooser />
          </>
        )}
        
        { auth.user ? (
          <Dropdown className="tour-profile" item icon={false} trigger={(auth?.user?.photo_url || null) ? <Image size="mini" avatar src={auth.user.photo_url} /> : <Emoji emoji="japanese_ogre" size={35} />}>
            <Dropdown.Menu>
              <Dropdown.Item as={NavLink} to="/profile"><TextWithEmoji emoji="bust_in_silhouette" text="My Profile" /></Dropdown.Item>
              <Dropdown.Item onClick={logOut}><TextWithEmoji emoji="wave" text="Logout" /></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <>
            <Menu.Item as={NavLink} to="/pricing">Pricing</Menu.Item>
            <Menu.Item as={NavLink} to="/login"><strong>Register or Log In</strong></Menu.Item>
          </>
        ) }
        
      </Menu.Menu>
    </Menu>
  )
}

export default TopMenu;