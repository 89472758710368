import React, { useContext, useState } from 'react';
import { Form, Input, Message } from 'semantic-ui-react';

import { ErrorContext } from '../../contexts/error';
import { TextWithEmoji } from '../../services/html';
import { subscribeToNewsletter } from '../../services/user';

function NewsletterSubscription() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);

  const { processApiError } = useContext(ErrorContext);

  const subscribe = () => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Please use a real email address");
    }

    subscribeToNewsletter(email)
      .then(_ => setSuccess(true))
      .catch(err => {
        processApiError(err);
        setError("Something went wrong. Sorry - please try again!");
      });
  }

  return (
    <>
      <Message attached content='Our Newsletter' />
      <Form className="segment attached" onSubmit={subscribe}>
        {! success && (
          <Form.Field style={{ margin: 0 }}>
            <Input value={email} onChange={(_, d) => setEmail(d.value)} type="email" required
              placeholder="Your email" action={{ content: "Subscribe!", type: "submit" }} fluid
            />
          </Form.Field>
        )}

        <Message error size="mini" visible={error != null} content={error} />
        <Message success size="tiny" visible={success} content={<TextWithEmoji emoji="tada" text="You're in!" />} style={{margin: 0}} />
      </Form>
    </>
  )
}

export default NewsletterSubscription