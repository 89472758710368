import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Label } from 'semantic-ui-react';

import { FeaturedRecipe, GameRecipe } from '../../../../api/src/models/gamerecipe.entity';
import { AuthContext } from '../../contexts/auth';
import { deleteGameRecipe } from '../../services/gamerecipes';
import { getImageThumbUrl } from '../../services/images';
import { GamePlanAccess } from '../../services/payment';
import { stringToReact } from '../../services/html';

type GameRecipeCardProps = { 
  recipe: GameRecipe, 
  onDelete: (recipe_id: number) => void
}

export function GameRecipeCard(props: GameRecipeCardProps) {
  const auth = useContext(AuthContext);
  
  const { recipe } = props;
  const { game_name } = recipe.recipe;

  const [mouseOver, setMouseOver] = useState(false);
  const [obscure, setObscure] = useState(false);
  const [has_access] = useState(game_name && (GamePlanAccess[game_name] <= auth.user?.subscription_level_id || 0));

  useEffect(() => {
    if (!has_access && mouseOver)
      setObscure(true);
    else if (!has_access && !mouseOver)
      setObscure(false);
  }, [mouseOver, has_access])

  const onDeleteRecipe = async (recipe_id: number) => {
    await deleteGameRecipe(recipe_id);
    props.onDelete(recipe_id);
  }

  return (
    <Card link raised as={Link} to={has_access ? `/game/play?recipe_id=${recipe.recipe_id}` : (auth.user ? "/profile/billing/upgrade" : "/login")} 
      onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
        
      <div style={{...(recipe.preview.background_style), display: "flex", justifyContent: "center", alignItems: "center", height: 200, filter: obscure ? "blur(2px)" : null }}>
        <img alt={recipe.name} draggable="false" src={getImageThumbUrl(recipe.preview?.item_urls[0])} style={{ display: "flex", maxWidth: "50%", maxHeight: 150 }} />
      </div>

      <Card.Content>
        { obscure ? (
          <div>
            This recipe uses the <strong>{recipe.recipe.game_name}</strong> game which requires a {auth.user?.subscription_level_id ? "higher " : ""}plan. 
            <div style={{marginTop: ".5em"}}>Please click here to upgrade or enjoy our free featured recipes above.</div>
          </div>
        ) : (
          <>
            <div style={{ marginBottom: "1em" }}>{recipe.name}</div>
          
            <Label.Group>
              {game_name && <Label title="Game" content={game_name} icon="game" />}
              {recipe.preview.lesson_name && <Label title="Lesson" content={recipe.preview.lesson_name} icon="student" />}
              {recipe.preview.theme_name && <Label title="Theme" content={recipe.preview.theme_name} icon="paint brush" />}
              {recipe.recipe.lesson_match_depth && <Label title="Match On" content={recipe.recipe.lesson_match_depth} icon="target" />}
              <Label title="Number of items" content={recipe.recipe.number_of_items} icon="picture" />
              {recipe.is_public ? 
                <Label title="Public recipe" content="Public" icon="world" /> : 
                  recipe.created_by === auth.user?.user_id ? 
                    <Label title="Your recipe" content="My Recipe" icon="lock" color="yellow" /> :
                    <Label title="Another user's recipe" content="Someone's Recipe" icon="lock" color="grey" />
              }
            </Label.Group>
          </>
        )}
      </Card.Content>

      {auth.user?.is_admin && (
        <Card.Content extra>
          <Button.Group basic size="mini">
            <Button icon="trash" negative disabled={!recipe.is_public && recipe.created_by !== auth.user?.user_id} onClick={e => {e.preventDefault(); onDeleteRecipe(recipe.recipe_id);}} title="Delete" />
          </Button.Group>
        </Card.Content>
      )}
    </Card>
  )
}

export function FeaturedGameRecipeCard(props: {featured: FeaturedRecipe}) {
  const { featured } = props;

  return (
    <Card color="yellow" link raised as={Link} to={`/game/play?feature_id=${featured.feature_id}`} style={{ backgroundColor: "#fbbd08" }}>
      <Label attached="top" color="yellow" title="Game" content={featured.recipe.recipe.game_name} icon="game" />      
      <div style={{ ...(featured.recipe.preview.background_style), display: "flex", justifyContent: "center", alignItems: "center", height: 150 }}>
        <img alt={featured.recipe.name} draggable="false" src={getImageThumbUrl(featured.recipe.preview?.item_urls[0])} style={{ display: "flex", maxWidth: "70%", maxHeight: "70%" }} />
      </div>
      <Card.Content extra style={{ color: "white" }}>
        {featured.note ? stringToReact(featured.note) : featured.recipe.name}
      </Card.Content>
    </Card>
  )
}