import React, { useContext } from 'react';
import { Loader } from 'semantic-ui-react';

import { GameName } from '../../../../api/src/types/game';
import { PlayContext } from '../../contexts/play';
import Catch from '../games/Catch';
import CheckOff from '../games/CheckOff';
import DragAndDrop from '../games/DragAndDrop';
import GuessMonster from '../games/GuessMonster';
import OneByOne from '../games/OneByOne';
import RevealBehind from '../games/RevealBehind';
import Sort from '../games/Sort';
import { GameRecipeDefinition } from '../../../../api/src/models/gamerecipe.entity';

export interface GameProps {
  loaderText?: string;
  headless?: boolean;
}

const default_recipe:GameRecipeDefinition = {
  number_of_items: 8,
  emphasize: "item name"
}

export const GameComponents: { name: GameName, component: React.FC<GameProps>, default_recipe: GameRecipeDefinition }[] = [
  { name: "Guess Monster", component: GuessMonster, default_recipe },
  { name: "Drag n Drop", component: DragAndDrop, default_recipe },
  { name: "Catch", component: Catch, default_recipe},
  { name: "Check Off", component: CheckOff, default_recipe},
  { name: "Reveal", component: RevealBehind, default_recipe },
  { name: "Sort", component: Sort, default_recipe },
  { name: "One by One", component: OneByOne, default_recipe },
];

export function GameLoader<T extends GameProps>(props: T & {game: React.FC<T>}) {
  const play = useContext(PlayContext);
  const { game } = props;
  
  return play.theme && play.playItems ? 
    React.createElement(game, props) :
    <Loader active content={props.loaderText} />
}