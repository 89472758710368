import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Dropdown, DropdownMenuProps, Header, Modal } from 'semantic-ui-react';

import { GameTheme } from '../../../../../api/src/models/gametheme.entity';
import { ErrorContext } from '../../../contexts/error';
import { PlayContext } from '../../../contexts/play';
import { toDropDownSource } from '../../../services/html';
import { PagedSearchResult, searchSpecific } from '../../../services/search';
import ThemeList from '../../themes/ThemeList';

type GameThemeSearchDropdownProps = DropdownMenuProps & {
  onItemSelected?: (selected: GameTheme) => void;
  inGame?: boolean;
  mode: "dropdown" | "modal";
  trigger?: any;
}

function GameThemeSearchDropdown(props: GameThemeSearchDropdownProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState<GameTheme>(null);
  const [results, setResults] = useState<PagedSearchResult<GameTheme>>();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  
  const { processApiError } = useContext(ErrorContext);
  const play = useContext(PlayContext);

  useEffect(() => {
    if (props.inGame && play?.theme) {
      if (!selected || play.theme.theme_id !== selected.theme_id) {
        if (results && results.data.includes(play.theme)) {
          setSelected(play.theme);
        }
        else {
          ReactDOM.unstable_batchedUpdates(() => {
            setResults({ data: [play.theme], count: 1, page: 1, total: 1, pageCount: 1 })
            setSelected(play.theme);
          });
        }      
      }
    }
  },
    // eslint-disable-next-line
    [play?.theme]
  );

  useEffect(() => {
    if (searchQuery.trim() !== "") {
      setLoading(true);

      const fq = {
        "$or": [
          { "name": { "$contL": searchQuery } },
        ]
      }
        
      searchSpecific<GameTheme>("game-theme", fq, null, 1, 20)
        .then(r => setResults(r.data))
        .catch(processApiError)
        .finally(() => setLoading(false));
    }
  }, 
    // eslint-disable-next-line
    [searchQuery]
  );

  useEffect(() => {
    if (props.onItemSelected) {
      props.onItemSelected(selected);
    }
      
    if (props.inGame && play && selected && selected.theme_id !== play.theme?.theme_id) {
      play.setTheme(selected);
    }

    if (props.mode === "modal") {
      setModalOpen(false);
    }
  },
    // eslint-disable-next-line
    [selected]
  );

  const onUpdateQuery = useCallback(_.debounce(setSearchQuery, 300), [searchQuery]);

  if (props.mode === "dropdown") {
    return (
      <Dropdown {..._.omit(props, ["inGame", "onItemSelected"])} noResultsMessage="Type to search themes..."
        selectOnNavigation={false} selectOnBlur={false} loading={loading}
        onSearchChange={(_, d) => onUpdateQuery(d.searchQuery)}
        options={toDropDownSource(results?.data || [], "name", "theme_id", "icon", null, null)}
        value={selected?.theme_id} onChange={(_, d) => setSelected(results.data.find(l => l.theme_id === d.value as number))}
      />
    )
  }
  else {
    return (
      <Modal dimmer="inverted" trigger={props.trigger} open={modalOpen} onOpen={() => setModalOpen(true)}>
        <Header icon="picture" content="Theme Search" />
        <Modal.Content scrolling>
          <ThemeList showList="all" showFilter onSelect={setSelected} />
        </Modal.Content>
        <Modal.Actions>
          <Button icon="close" content="Cancel" labelPosition="right" onClick={() => setModalOpen(false)} />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default GameThemeSearchDropdown;