// ace-builds imports need to be below ace-editor or else things break
// but tshero reorgs them sometimes.

import { Emoji, Picker } from 'emoji-mart';
import React, { useContext, useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { Accordion, Button, Form, Header, Icon, Image, Label, Message, Popup, Segment } from 'semantic-ui-react';

import { GameTheme } from '../../../../api/src/models/gametheme.entity';
import { SearchResult } from '../../../../api/src/types/search';
import { AuthContext } from '../../contexts/auth';
import { ErrorContext } from '../../contexts/error';
import {
  addGameTheme,
  getGameTheme,
  getThemeFloaters,
  updateGameTheme,
  updateThemeBackgroundByFile,
} from '../../services/gamethemes';
import { FakeLink, TextWithEmoji, useQuery } from '../../services/html';
import { allImageTypes } from '../../services/images';
import MultiItemSelector from '../shared/MultiItemSelector';
import ThemePreview from './ThemePreview';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';

const container_override_example = {
  "paddingTop": "10%",
  "backgroundSize": "contain, contain, cover",
  "backgroundImage": "url(https://storage.googleapis.com/guess-monster.appspot.com/backgrounds/6col-dice.png), url(https://storage.googleapis.com/guess-monster.appspot.com/backgrounds/6col.png), url(https://storage.googleapis.com/guess-monster.appspot.com/backgrounds/underwater_1.jpg)",
  "background-repeat": "no-repeat, repeat, repeat"
};

const item_override_example = {
  "width": "16.66%",
  "margin": 0,
  "padding": "1em",
  "marginBottom": "5%"
};

const text_override_example = {
  "color": "white",
  "fontSize": "1.2em",
  "textShadow": "0px 2px 2px #23430C",
  "WebkitTextStroke": "1px white"
};

const jsonOrNull = (json: string) => json ? JSON.parse(json) : null;

function AddEditTheme() {
  const [theme, setTheme] = useState<Partial<GameTheme>>({});
  const [existingImageUrl, setExistingImageUrl] = useState("");
  const [containerOverride, setContainerOverride] = useState<string>(null);
  const [itemOverride, setItemOverride] = useState<string>(null);
  const [textOverride, setTextOverride] = useState<string>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [floaters, setFloaters] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState<string>(null);
  const [showDanger, setShowDanger] = useState(false);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({ onDropAccepted: setFiles,accept: allImageTypes });
  const auth = useContext(AuthContext);
  const { processApiError, setError } = useContext(ErrorContext);

  const { theme_id } = useParams();
  const query = useQuery();
  const copy = query.get("copy");

  useEffect(() => {
    if (theme_id) {
      setLoading(true);

      getGameTheme(parseInt(theme_id))
        .then(async d => {
          if (d.data.created_by === auth.user?.user_id || auth.user?.is_admin) {
            const fl = d.data.floater_ids ? (await getThemeFloaters(d.data)).data : [];

            setTheme(d.data);
            setFloaters(fl.map(f => ({
              id: f.item_id,
              name: f.name,
              which: "item",
              image_url: f.image_url
            })));

            setExistingImageUrl(d.data.background_url);
            setContainerOverride(d.data.container_style ? JSON.stringify(d.data.container_style) : null);
            setItemOverride(d.data.item_style ? JSON.stringify(d.data.item_style) : null);
            setTextOverride(d.data.font_style ? JSON.stringify(d.data.font_style) : null);
          }
          else {
            setError("401", "You can only edit your own themes.");
          }
        })
        .catch(processApiError)
        .finally(() => setLoading(false));
    }
  }, 
    // eslint-disable-next-line
    [theme_id, auth.user]
  );

  const save = async () => {
    setSaveError(null);
    setSaving(true);

    try {
      if (! theme_id || copy) {
        const t = await addGameTheme({
          ...theme,
          theme_id: copy ? null : theme.theme_id,
          floater_ids: floaters.map(f => f.id).join(","),
          container_style: jsonOrNull(containerOverride),
          item_style: jsonOrNull(itemOverride),
          font_style: jsonOrNull(textOverride)
        });

        if (files.length > 0) {
          await updateThemeBackgroundByFile(t.data.theme_id, files[0]);
        }

        setTheme({});
        setFloaters([]);
        setContainerOverride(null);
        setItemOverride(null);
        setTextOverride(null);
      }
      else {
        // TODO: no way to clear theme backgrounds - only replace.. that's ok for now
        await updateGameTheme(parseInt(theme_id), {
          ...theme,
          floater_ids: floaters.map(f => f.id).join(","),
          container_style: jsonOrNull(containerOverride),
          item_style: jsonOrNull(itemOverride),
          font_style: jsonOrNull(textOverride)
        })

        if (files.length > 0) {
          await updateThemeBackgroundByFile(parseInt(theme_id), files[0]);
        }   
      }

      setSuccessShow(true);
      setTimeout(() => setSuccessShow(false), 4000);
    }
    catch (err) {
      processApiError(err);
      setSaveError(err);
    }
    finally {
      setSaving(false);
    }
  }
 
  return (
    <>
      <Header className="relaxed">
        { theme_id ? "Update" : "Add New"}  Theme
        <Header.Subheader>
          Themes are a complicated but we are working on making them easier to work with! 
          Please reach out if you need help in the meantime!
        </Header.Subheader>
      </Header>

      <Form onSubmit={save} loading={loading}>
        <Form.Input required label="Theme name" placeholder='Please enter a descriptive theme name' fluid value={theme.name || ""} onChange={(_, d) => setTheme({...theme, name: d.value})} />

        <Form.Field>
          <label>Pick an emoji which will show up on the theme list</label>
          <Popup hoverable on="click" trigger={(
            <Label as='a' basic image>
              <TextWithEmoji text="Theme emoji" emoji={theme?.icon || "woman-shrugging"} size={30} />
            </Label>
          )}>
            <Popup.Content><Picker title='Pick your emoji!' emoji='point_up' onSelect={e => setTheme({ ...theme, icon: e.id })} /></Popup.Content>
          </Popup>
        </Form.Field>

        {theme_id && existingImageUrl && (
          <Form.Field>
            <Message warning visible icon>
              <Image src={existingImageUrl} size="tiny" style={{ marginRight: "1em" }} />
              <Message.Content>
                <Message.Header>
                  This is your current theme background
                </Message.Header>

                When updating a theme, if you leave the section below alone, the theme will retain the current background. Or you can replace it. Please note that if you're
                replacing, it can take a few minutes and up to an hour for it to be processed and the new background to appear in games and on the site.
              </Message.Content>
            </Message>
          </Form.Field>
        )}

        <Form.Field>
          <label>Theme Background</label>
          <div>This is optional. You can also do layered backgrounds using the container style override if you want to get fancy. To keep it simple, just upload a nice background picture.</div>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {
              <div className="drop-zone">
                {isDragActive ?
                  "Drop the file here..." :
                  <span><strong>Drag and drop a file or click to select one.</strong> Please make sure you're allowed to use this file, especially if you plan on making this theme public. SVG and PNG are best (in that order) - if possible, use those.</span>
                }
              </div>
            }
            {isDragReject && <strong>Please only use common image formats like PNGs, SVG, JPG, etc. Thanks!</strong>}
            {files && files.length > 0 && <div>{files[0].name}</div>}
          </div>
        </Form.Field>

        <Form.Field>
          <label>Floaters</label>
          <div>
            Some games use pictures from the recipe (Reveal, Check Off, etc) but some games can have specific images (Catch, etc). 
            We call those <em>floaters</em>. In order for your theme to be used in games that need floaters, pick some items to float. If you pick more than one,
            they will be randomly chosen for each game (you'll get a mix). Some games have a main floater and secondary floaters (think putting shells in a bucket).
            The <em>first</em> floater in the list will be the main floater (the bucket) for those games. You don't have to select floaters at all and then they'll all be cats. <Emoji emoji="cat" size={16}/>
          </div>
          <MultiItemSelector placeholder="Find items..." items={floaters} onChange={setFloaters} />
        </Form.Field>

        <Accordion as={Segment} color="red" style={{ marginTop: "3em" }}>
          <Accordion.Title as={Header} onClick={() => setShowDanger(!showDanger)} active={showDanger}>
            <Icon name='dropdown' />
            Danger Zone
            <Header.Subheader>
              Here, you can get fancy with your theme, but you can also break it. For all the style overrides, we're using
              the JSX version of CSS but it also needs to be valid JSON so property names have to be in quotes. If the previous
              sentence makes sense to you, please proceed with caution. If not, please contact us and we'll be happy to help you!
            </Header.Subheader>
          </Accordion.Title>
          <Accordion.Content active={showDanger}>
            <Form.Field>
              <label>Container override</label>
              <div>You can override padding, alignment and other things in the <em>main game container</em>. For example, <button className="link-button" onClick={() => setContainerOverride(JSON.stringify(container_override_example, null, 2))}>start with the underwater dice grid theme</button>.</div>
              <AceEditor name="code_container_overrides" placeholder="Container Overrides"
                mode="json" width="100%" height="300px" theme="monokai" fontSize={14} showPrintMargin={false} showGutter={true} highlightActiveLine={true}
                setOptions={{ enableBasicAutocompletion: false, enableLiveAutocompletion: false, enableSnippets: false, showLineNumbers: true, tabSize: 2, useWorker: false }} 
                value={containerOverride || ""} onChange={setContainerOverride}
              />
            </Form.Field>
            <Form.Field>
              <label>Item override</label>
              <div>You can override padding, alignment and other things of the <em>items</em> that come up in games. For example, <FakeLink style={{ cursor: "pointer" }} onClick={() => setItemOverride(JSON.stringify(item_override_example, null, 2))}>start with the underwater dice grid theme</FakeLink>.</div>
              <AceEditor name="code_item_overrides" placeholder="Item Overrides"
                mode="json" width="100%" height="300px" theme="monokai" fontSize={14} showPrintMargin={false} showGutter={true} highlightActiveLine={true}
                setOptions={{ enableBasicAutocompletion: false, enableLiveAutocompletion: false, enableSnippets: false, showLineNumbers: true, tabSize: 2, useWorker: false }}
                value={itemOverride || ""} onChange={setItemOverride}
              />
            </Form.Field>
            <Form.Field>
              <label>Text override</label>
              <div>You can override font styles and other things of the <em>text</em> that comes up in games. For example, <FakeLink style={{ cursor: "pointer" }} onClick={() => setTextOverride(JSON.stringify(text_override_example, null, 2))}>start with the underwater dice grid theme</FakeLink>.</div>
              <AceEditor name="code_text_overrides" placeholder="Text Overrides"
                mode="json" width="100%" height="300px" theme="monokai" fontSize={14} showPrintMargin={false} showGutter={true} highlightActiveLine={true}
                setOptions={{ enableBasicAutocompletion: false, enableLiveAutocompletion: false, enableSnippets: false, showLineNumbers: true, tabSize: 2, useWorker: false }}
                value={textOverride || ""} onChange={setTextOverride}
              />
            </Form.Field>
          </Accordion.Content>
        </Accordion>

        <ThemePreview background={files && files.length > 0 ? files[0] : theme.background_url} sampleItemUrl={floaters && floaters.length > 0 ? floaters[0].image_url : null} containerOverride={containerOverride} itemOverride={itemOverride} textOverride={textOverride} />
        <Button positive loading={saving} disabled={saving}>{copy ? "Copy" : theme_id ? "Update" : "Add"} Theme</Button>
        {successShow && <div className="saved-message"><TextWithEmoji emoji="tada" text="Saved!" /></div>}
        {saveError && <div className="error-message"><TextWithEmoji emoji="sweat" text={saveError} /></div>}
      </Form>
    </>
  )
}

export default AddEditTheme;