import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button, Card, Placeholder } from 'semantic-ui-react';

import { allMyCollections } from '../../services/items';

function ItemCollectionList() {
  const { path } = useRouteMatch();
  
  return useObserver(() => allMyCollections.current() ? (
    <Card.Group itemsPerRow={4}>
      { allMyCollections.current().map(c => (
        <Card key={c.collection_id}>
          <div style={{ width: "100%", maxHeight: 200, height: 100, background: `url(${c.cover_image_url})`, backgroundSize: "cover" }} />
          <Card.Content>
            <Card.Header>{c.name}</Card.Header>
            <Card.Meta>{c.item_count} item{c.item_count !== 1 ? "s" : ""}</Card.Meta>
          </Card.Content>
          <Card.Content extra>
            <Button basic size="mini" as={Link} to={`${path}/collection/${c.collection_id}`}>Edit</Button>
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  ) : (
    <Placeholder style={{ width: 200 }}>
      <Placeholder.Image square />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder>
  ))
}

export default ItemCollectionList;