import { GameName, GuessableResult } from '../../../api/src/types/game';
import { stringToReact } from "./html";

const guessable_prefixes = [
  ["I'm hungry for", "!"],
  ["I'm in the mood for", "."],
  ["I'm thinking about", "..."],
  ["Help me find", "."],
  ["Do you see", "?"],
  ["Can you spot", "?"],
  ["Find me", "!"],
  ["I've got a hankering for", "!"],
  ["I want to befriend", "."],
  ["I think", " is on the menu!"],
  ["My dinner is", "!"],
  ["My lunch is", "!"],
  ["I eat", " for breakfast!"],
  ["I'm craving", "..."],
  ["Where is", "?"],
]

export interface QuestionFormatterVariables {
  attribute_name: string;
  value: string;
}

export const GameDetails : { [key in GameName]?: any } = {
  //"Reveal": {} - stub for now
}

export function getGuessableQuestion(guessable: GuessableResult) {
  const format = guessable.question_format || 
  // eslint-disable-next-line 
    "something that has ${this.attribute_name.toLowerCase()} = <strong>${this.attribute_value.toLowerCase()}</strong>";
  
  // eslint-disable-next-line 
  const interpolated = new Function("return `" + format + "`;").call(guessable);
  const prefix = guessable_prefixes[Math.floor(Math.random() * guessable_prefixes.length)];
  return stringToReact(`${prefix[0]} ${interpolated}${prefix[1]}`, { textAlign: "right" });
}