import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import {
  FeaturedRecipe,
  GameRecipe,
  GameRecipeDefinition,
  RecipeGameOptions,
} from '../../../api/src/models/gamerecipe.entity';
import { GameTheme } from '../../../api/src/models/gametheme.entity';
import { Lesson } from '../../../api/src/models/lesson.entity';
import { GamePlaySearchResult } from '../../../api/src/types/game';
import { logPlayEvent } from '../services/analytics';
import { getFeaturedGameRecipe, getGameRecipe } from '../services/gamerecipes';
import { getGameTheme } from '../services/gamethemes';
import { useQuery } from '../services/html';
import { findItemsByRecipe } from '../services/items';
import { getLesson } from '../services/lessons';
import { ErrorContext } from './error';
import { ToolsContext } from './tools';

export type PlayContextOptions = {
  recipeDefinition: GameRecipeDefinition;
  initialFeatured: FeaturedRecipe;

  updateRecipeDefinition: (definition: Partial<GameRecipeDefinition>) => void;
  updateGameOptions: (options: RecipeGameOptions) => void;
  setRecipeById: (recipe_id: number) => void;
  setRecipeByFeatureId: (feature_id: number) => void;

  lesson?: Lesson;
  setLesson: (lesson:Lesson) => void;

  theme?: GameTheme;
  setTheme: (theme: GameTheme) => void;

  playItems: GamePlaySearchResult;
  requestNewItems: () => void;
  
  onFinish: () => void;
}

type PlayContextDefaults = {
  defaultDefinition?: GameRecipeDefinition
}

export const PlayContext = React.createContext<PlayContextOptions>(undefined);

export const PlayContextProvider:React.FC<PlayContextDefaults> = (props) => {
  const { processApiError, setError } = useContext(ErrorContext);
  const { toggleConfetti } = useContext(ToolsContext);

  const [lesson, setLesson] = useState<Lesson>();
  const [theme, setTheme] = useState<GameTheme>();
  const [playItems, setPlayItems] = useState<GamePlaySearchResult>();

  const query = useQuery();
  const initial_recipe_id = query.get("recipe_id");
  const initial_feature_id = query.get("feature_id");
  
  const [recipe, setRecipe] = useState({
    recipe: !initial_recipe_id && !initial_feature_id ? (props.defaultDefinition || {}) : {}
  } as Partial<GameRecipe>);

  const [feature, setFeature] = useState<FeaturedRecipe>(null);
  const [lastPulled, setLastPulled] = useState<GameRecipeDefinition>();

  useEffect(() => {
    // this fires as an effect so let's double check we need it.
    // if you want to force new items, call getRecipeItems directly
    if (recipe.recipe.number_of_items && !_.isEqual(recipe.recipe, lastPulled)) {
      //console.log("needing new items", "cur", recipe.recipe, "last", lastPulled)
      getRecipeItems();
    }
  },
    // eslint-disable-next-line
    [
      // this list should be only recipe properties that would
      // change the play items
      recipe.recipe.attribute_id,
      recipe.recipe.attribute_value_id,
      recipe.recipe.collection_id,
      recipe.recipe.item_type_id,
      recipe.recipe.lesson_id,
      recipe.recipe.lesson_match_depth,
      recipe.recipe.number_of_items,
    ]
  );

  useEffect(() => {
    if ((initial_recipe_id || initial_feature_id) && !recipe.recipe.theme_id) {
      // if there is an intial recipe but theme_id is null,
      // that means it hasn't loaded yet so don't waste a call on a random theme
      return;
    }

    if (!theme || (theme.theme_id !== recipe.recipe.theme_id || recipe.recipe.theme_id == null)) {
      getGameTheme(recipe.recipe.theme_id)
        .then(r => updateTheme(r.data))
        .catch(processApiError);
    }
  },
    // eslint-disable-next-line
    [recipe.recipe.theme_id]
  )

  useEffect(() => {
    if ((initial_recipe_id || initial_feature_id) && !recipe.recipe.lesson_id) {
      // if there is an intial recipe but lesson_id is null,
      // that means it hasn't loaded yet so don't waste a call
      return;
    }

    if (!lesson || lesson.lesson_id !== recipe.recipe.lesson_id) {
      if (recipe.recipe.lesson_id) {
        getLesson(recipe.recipe.lesson_id)
          .then(r => updateLesson(r.data))
          .catch(processApiError);
      }
      else {
        updateLesson(null);
      }
    }
  },
    // eslint-disable-next-line
    [recipe.recipe.lesson_id]
  )

  const setRecipeById = (recipe_id: number) => {
    if (recipe_id && (recipe.recipe_id !== recipe_id)) {
      getGameRecipe(recipe_id)
        .then(r => {
          if (r.data) {
            setRecipe(r.data)
          }
          else {
            setError("404", "There isn't a recipe over here but we have so many game combinations, go ahead and make your own!");
          }
        })
        .catch(processApiError);
    }
  }

  const setRecipeByFeatureId = (feature_id: number) => {
    getFeaturedGameRecipe(parseInt(initial_feature_id))
      .then(r => {
        if (r.data) {
          ReactDOM.unstable_batchedUpdates(() => {
            setRecipe(r.data.recipe);
            setFeature(r.data);
          });
        }
        else {
          setError("404", "There isn't a featured recipe over here but we have so many game combinations, go ahead and make your own!");
        }
      })
      .catch(processApiError);
  }

  const getRecipeItems = () => {
    findItemsByRecipe(recipe.recipe)
      .then(r => {
        setPlayItems(r.data);
        setLastPulled(recipe.recipe);
        logPlayEvent(recipe.recipe);
      })
      .catch(processApiError);
  }

  /** auto merges so you can pass just the options you need */
  const updateGameOptions = (options: RecipeGameOptions) => {
    //console.log("updating options to ", options)
    setRecipe(p => ({ ...p, recipe: { ...p.recipe, ...options }}));
  }

  /** auto merges so you can pass just the options you need */
  const updateRecipeDefinition = (definition: GameRecipeDefinition) => {
    //console.log("updating definition to", definition)
    setRecipe(p => ({ ...p, recipe: { ...p.recipe, ...definition } }));
  }

  const updateTheme = (t: GameTheme) => {
    if (t && (recipe.recipe.theme_id !== t.theme_id || theme?.theme_id !== t.theme_id)) {
      setTheme(t);
      updateRecipeDefinition({ theme_id: t.theme_id });
    }
  }

  const updateLesson = (l: Lesson) => {
    if (recipe.recipe.lesson_id !== l?.lesson_id || lesson?.lesson_id !== l?.lesson_id) {
      setLesson(l);

      const match_depth = (
        (recipe.recipe.lesson_match_depth === "attribute value" && l?.match_attribute_values) ||
        (recipe.recipe.lesson_match_depth === "item" && l?.match_items)) ?
          recipe.recipe.lesson_match_depth : 
          l && l.match_items ? "item" : 
            l ? "attribute value" : 
            null;

      updateRecipeDefinition({ lesson_id: l?.lesson_id, lesson_match_depth: match_depth });
    }
  }

  return <PlayContext.Provider value={{
    setRecipeById, setRecipeByFeatureId,
    updateRecipeDefinition, updateGameOptions,
    recipeDefinition: recipe.recipe,
    initialFeatured: feature,
    lesson, setLesson: updateLesson,
    theme, setTheme: updateTheme,
    playItems,
    requestNewItems: getRecipeItems,
    onFinish: toggleConfetti
  }}>{props.children}</PlayContext.Provider>
}

