import { ILazyObservable, lazyObservable } from 'mobx-utils';
import React, { useContext } from 'react';

import { GameRecipe } from '../../../api/src/models/gamerecipe.entity';
import { getUserGameRecipes } from '../services/gamerecipes';
import { AuthContext } from './auth';

export type LookupsContextOptions = {
  myGameRecipes: ILazyObservable<GameRecipe[]>;
}

export const LookupsContext = React.createContext<LookupsContextOptions>(undefined);

export const LookupsContextProvider:React.FC = (props) => {
  const auth = useContext(AuthContext);

  const myGameRecipes = lazyObservable<GameRecipe[]>(sink => getUserGameRecipes(auth.user?.user_id).then(u => sink(u.data)));
  
  return <LookupsContext.Provider value={{
    myGameRecipes,
  }}>{props.children}</LookupsContext.Provider>
}