import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import PageHeader from '../shared/PageHeader';
import { AddEditLesson } from './AddEditLesson';
import LessonList from './LessonList';
import ManageLessonCategories from './ManageLessonCategories';

function LessonsHome () {
  let { path } = useRouteMatch();
  
  return (
    <>
      <PageHeader title="Lessons" backText="Back to Lesson Library" />
      
      <Switch>
        <Route path={`${path}/category`} component={ManageLessonCategories} />
        <Route path={`${path}/lesson/:lesson_id?`} component={AddEditLesson} />
        <Route path={path} component={() => <LessonList showList="all" showFilter />} />
      </Switch>
    </>
  );
}

export default LessonsHome;