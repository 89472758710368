import { Picker } from 'emoji-mart';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Header, Label, Popup } from 'semantic-ui-react';

import { AuthContext } from '../../contexts/auth';
import { ErrorContext } from '../../contexts/error';
import { TextWithEmoji } from '../../services/html';
import { updateCurrentUser } from '../../services/user';

function BasicProfile() {
  const auth = useContext(AuthContext);
  const [saving, setSaving] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  // const [passwordSuccessShow, setPasswordSuccessShow] = useState(false);

  const [name, setName] = useState(auth.user.name);
  const [email, setEmail] = useState(auth.user.email);
  // const [password1, setPassword1] = useState("");
  // const [password2, setPassword2] = useState("");

  const [emojis, setEmojis] = useState({
    default_positive_icon: auth.user.default_positive_icon,
    default_negative_icon: auth.user.default_negative_icon,
  });

  const { processApiError } = useContext(ErrorContext);

  const timeout1 = useRef(null);
  // const timeout2 = useRef(null);

  const save = async () => {
    setSaving(true);

    updateCurrentUser({name, email, ...emojis})
      .then(u => {
        auth.updateUser(u.data);
        setSaving(false);
        setSuccessShow(true);
        timeout1.current = setTimeout(() => setSuccessShow(false), 4000);
      }).catch(processApiError);
  }

  // const updatePassword = async () => {
  //   setSaving(true);

  //   changeUserPassword(password1)
  //     .then(u => {
  //       setSaving(false);
  //       setPasswordSuccessShow(true);
  //       timeout2.current = setTimeout(() => setPasswordSuccessShow(false), 4000);
  //     }).catch(processApiError);
  // }

  useEffect(() => {
    return () => {
      clearTimeout(timeout1.current);
      // clearTimeout(timeout2.current);
    }
  }, [])
 
  return (
    <Form size="large" onSubmit={save}>
      <Header size="large" className="relaxed">Basic Profile</Header>

      <Form.Input required label="My name" placeholder='Please enter your name' fluid value={name} onChange={(_, d) => setName(d.value)} />
      <Form.Input type="email" required label="My email (for notifications and stuff)" placeholder='Please enter your email' fluid value={email} onChange={(_, d) => setEmail(d.value)} />

      <Form.Field>
        <label>Pick your favorite positive and negative emojis for the games that use them</label>
        <Popup hoverable on="click" trigger={(
            <Label as='a' basic image color="green">
              <TextWithEmoji text="Positive" emoji={emojis.default_positive_icon} size={30} />
            </Label>
          )}>
          <Popup.Content><Picker title='Pick your emoji!' emoji='point_up' onSelect={e => setEmojis({...emojis, default_positive_icon: e.id})} /></Popup.Content>
        </Popup>
        <Popup hoverable on="click" trigger={(
          <Label as='a' basic image color="red">
            <TextWithEmoji text="Negative" emoji={emojis.default_negative_icon} size={30} />
          </Label>
        )}>
          <Popup.Content><Picker title='Pick your emoji!' emoji='point_up' onSelect={e => setEmojis({ ...emojis, default_negative_icon: e.id })} /></Popup.Content>
        </Popup>
      </Form.Field>

      <Button primary loading={saving} disabled={saving}>Update Profile</Button>
      {successShow && <div className="saved-message"><TextWithEmoji emoji="tada" text="Saved!" /></div>}
      
      {/* DISABLED FOR NOW BECAUSE of CREDENTIAL_TOO_OLD_LOGIN_AGAIN issue - need to follow instructions here after mvp https://medium.com/@ericmorgan1/change-user-email-password-in-firebase-and-react-native-d0abc8d21618
      <Segment color="red" style={{marginTop: "3em"}}>
        <Header>Change Password</Header>
        {auth.firebase_user.providerData[0].providerId === "google.com" ? (
          <div><Icon name="google" /> You're logged in with google so there's no password to change. Nice!</div>
        ) : (
          <>
            <Form.Input type="password" required label="New Password" placeholder="Please enter a new password if you want" fluid value={password1} onChange={(_, d) => setPassword1(d.value)} />
            <Form.Input type="password" required label="Confirm Password" placeholder="" fluid value={password2} onChange={(_, d) => setPassword2(d.value)} />
            <Button onClick={updatePassword} disabled={password1.length < 6 || password1 !== password2 || saving}>Update Password</Button>
            {passwordSuccessShow && <div className="saved-message"><TextWithEmoji emoji="tada" text="Updated!" /></div>}
          </>
        )}
      </Segment> */}

    </Form>
  )
}

export default BasicProfile;