/** Gets the thumb url given the naming convention we set up where all files are prefixed with thumb_ */
export function getImageThumbUrl(url: string) {
  if (!url) {
    return "/monster-gray.png";
  }
  else { 
    // svgs and webps don't need thumbs
    return url.replace(/(.*\/)(.*\.(?!(svg|webp))\w*(?:$|\n|\)))/, "$1thumb_$2");
  }
}

const transparent_images = ["image/png", "image/svg+xml", "image/gif"];
const nontransparent_images = ["image/jpeg", "image/pjpeg"];

export const transparentImageTypes = transparent_images.join(",");
export const allImageTypes = transparent_images.concat(nontransparent_images).join(",");