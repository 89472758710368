import { lazyObservable } from 'mobx-utils';

import { Student, UserStudentRelationships } from '../../../api/src/models/student.entity';
import axios from '../config/axios';
import { observable } from 'mobx';
import { User } from '../../../api/src/models/user.entity';

export const allMyStudents = lazyObservable<Student[]>(sink => getStudents().then(u => sink(u.data)));

export const StudentBackgrounds = [
  { name: "Cupcakes", url: "/bgs/cupcakes.jpg" },
  { name: "Hearts", url: "/bgs/hearts.jpg" },
  { name: "Owls", url: "/bgs/owls.jpg" },
  { name: "Robots", url: "/bgs/robots.jpg" },
  { name: "Trucks", url: "/bgs/trucks.jpg" },
  { name: "Unicorns", url: "/bgs/unicorns.jpg" }
];

export const CurrentStudent = observable({
  student: null,
  set: (s: Student) => {
    CurrentStudent.student = s;

    if (s) {
      if (s.other_student_settings?.background_url) {
        document.body.style.background = `url(${s.other_student_settings.background_url})`;
      }
      else {
        document.body.style.background = null;
      }
    }
    else {
      document.body.style.background = null;
    }
  }
} as {
  student?: Student
  set: (student: Student) => void
});

export function getCurrentStudentPositiveEmoji(user: User) {
  return CurrentStudent?.student?.default_positive_icon || user?.default_positive_icon || "smile";
}

export function getCurrentStudentNegativeEmoji(user: User) {
  return CurrentStudent?.student?.default_negative_icon || user?.default_negative_icon || "slightly_frowning_face";
}


export function getStudents() {
  return axios.get<Student[]>(`/student`);
}

export function getStudent(student_id: number) {
  return axios.get<Student>(`/student/${student_id}`);
}

export function addStudent(student: Partial<Student>, relationship_id: UserStudentRelationships) {
  return axios.post<Student>(`/student`, {
    ...student,
    relationship_id
  });
}

export function updateStudent(student_id: number, student: Partial<Student>) {
  return axios.patch<Student>(`/student/${student_id}`, {
    ...student
  });
}

export function deleteStudent(student_id: number) {
  return axios.delete<Student>(`/student/${student_id}`);
}