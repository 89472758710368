import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Popup } from 'semantic-ui-react';

import { Item } from '../../../../api/src/models/item.entity';
import { ErrorContext } from '../../contexts/error';
import { FakeLink, toDropDownSource } from '../../services/html';
import { searchSpecific } from '../../services/search';

function AttributeCopier(props:{text?: string, onSelect:(value_ids?: number[]) => void}) {
  const [q, setQ] = useState("");
  const [results, setResults] = useState<Item[]>([]);

  const { processApiError } = useContext(ErrorContext);

  useEffect(() => {
    if (q !== "") {
      searchSpecific<Item>("item", { "name": { "$contL": q } }, 
        ["attribute_values", "attribute_values.attribute_value", "attribute_values.attribute_value.attribute"], 1, 50)
      .then(r => {
        setResults(r.data.data);
      })
      .catch(processApiError);
    }
  }, 
    // eslint-disable-next-line
    [q]
  );

  return (
    <Popup on="click" trigger={<FakeLink style={{cursor: "pointer"}}>{props.text || "Or pick from an existing item."}</FakeLink>}
      content={(
        <div style={{ minWidth: 400 }}>
          <label>Pick an item to copy attributes from.</label>          
          <Dropdown fluid selection search placeholder="Search..." selectOnBlur={false} selectOnNavigation={false}      
            searchQuery={q} onSearchChange={(_, d) => setQ(d.searchQuery)}
            options={toDropDownSource(results, "name", "item_id", "image_url", null, null, i => i.attribute_values.map(av => `${av.attribute_value.attribute.name}: ${av.attribute_value.value}`).join(", "))}
            value={null} onChange={(_, d) => {
              props.onSelect(results.find(r => r.item_id === d.value as number).attribute_values.map(v => v.value_id))
            }}
          />
        </div>
      )}
    />
  )
}

export default AttributeCopier;