import _ from 'lodash';
import { useObserver } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  Button,
  Card,
  Checkbox,
  Confirm,
  Header,
  Image,
  Input,
  List,
  Pagination,
  Placeholder,
  Popup,
} from 'semantic-ui-react';

import { Item } from '../../../../api/src/models/item.entity';
import { AuthContext } from '../../contexts/auth';
import { ErrorContext } from '../../contexts/error';
import { allAttributes } from '../../services/attributes';
import { TextWithEmoji } from '../../services/html';
import { getImageThumbUrl } from '../../services/images';
import { deleteItem, updateItem } from '../../services/items';
import { allLessons } from '../../services/lessons';
import { PagedSearchResult, searchSpecific } from '../../services/search';

interface ItemListProps {
  showList: "all" | "my";
  relaxed?: boolean;
  showFilter?: boolean;
}

const placeholder = (
  <Placeholder style={{ width: 200 }}>
    <Placeholder.Image square />
    <Placeholder.Line />
    <Placeholder.Line />
  </Placeholder>
);

function ItemList(props: ItemListProps) {
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagedResults, setPagedResults] = useState<PagedSearchResult<Item>>();
  const [filter, setFilter] = useState("");
  const [draftOnly, setDraftOnly] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [toDelete, setToDelete] = useState(-1);

  const { showList } = props;  
  const { path } = useRouteMatch();
  const auth = useContext(AuthContext);
  const { processApiError } = useContext(ErrorContext);

  useEffect(() => {
    setLoading(true);

    const fq = showList === "all" ? {
      "$and": [{
        "$or": [
          { "name": { "$contL": filter } },
          { "type.name": { "$contL": filter } },
        ]},
        draftOnly ? { "is_draft": true } : null
      ]
    } : 
    {
      "$and": [{
        "$or": [
          { "name": { "$contL": filter } },
          { "type.name": { "$contL": filter } },
        ]},
        { "is_public": false }
      ]
    }

    searchSpecific<Item>("item", fq, ["type", "attribute_values", "attribute_values.attribute_value", "item_lessons"], pageNumber, 100)
    .then(r => {
      setPagedResults(r.data);
      setLoading(false)
    })
    .catch(processApiError);
  }, 
    // eslint-disable-next-line
    [filter, pageNumber, showList, draftOnly, forceRefresh]
  );

  const deleteAnItem = () => {
    deleteItem(toDelete)
      .then(_ => {
        setToDelete(-1);
        setForceRefresh(!forceRefresh);
      })
      .catch(processApiError);
  }

  return useObserver(() => (pagedResults && allLessons.current() && allAttributes.current() ? (
    <>
      {props.showFilter && (
        <div className="relaxed">
          <Input action={{ icon: 'add', primary: true, as: Link, to: `${path}/item` }} placeholder={`Filter ${pagedResults.total} items...`} onChange={_.debounce((_, d) => setFilter(d.value), 300)} fluid />
          {auth.user?.is_admin && <Checkbox checked={draftOnly} onChange={() => setDraftOnly(!draftOnly)} label="Draft Only" /> }
        </div>
      )}
      
      { loading ? placeholder : (
        <Card.Group itemsPerRow={props.relaxed ? 4 : 5} stackable>
          {pagedResults.data.map(i => {
            let atts: { [key: number]: [string, string[]] } = {}
            i.attribute_values.forEach(v => {
              const aid = v.attribute_value.attribute_id
              const existing = atts[aid];

              atts[aid] = [
                existing ? atts[aid][0] : allAttributes.current().find(a => a.attribute_id === aid)?.name,
                (existing ? atts[aid][1] : []).concat(v.attribute_value.value)
              ];
            })

            return (
              <Card key={i.item_id}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#eee" }}>
                  <Image src={getImageThumbUrl(i.image_url)} style={{ display: "flex", maxWidth: "100%", maxHeight: 200 }} />
                </div>
                <Card.Content style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column" }}>
                  <Card.Header>{i.name}</Card.Header>
                  <Card.Meta><TextWithEmoji emoji={i.type.icon} text={i.type.name} /></Card.Meta>
                </Card.Content>
                <Card.Content extra>
                  <Button.Group basic size="mini" color={i.is_draft ? "yellow" : null}>
                    <Popup trigger={<Button icon="info" />} on="click" content={(
                      <div>
                        {i.attribute_values && (
                          <>
                            <Header size="medium">Attributes</Header>
                            {Object.values(atts).length > 0 ? (
                              <List>
                                {Object.values(atts).sort((a, b) => a < b ? -1 : 1).map((v, i) => <List.Item key={i} content={`${v[0]}: ${v[1].join(", ")}`} />)}
                              </List>
                            ) : <em>No item attributes</em>}
                          </>
                        )}
                        {i.item_lessons && (
                          <>
                            <Header size="medium">Lessons</Header>
                            <div>
                              {i.item_lessons.map(l => allLessons.current().find(al => al.lesson_id === l.lesson_id)?.name).join(", ") || <em>No item lessons</em>}
                            </div>
                          </>
                        )}
                      </div>
                    )} />
                    {auth.user?.is_admin && <Button icon={i.is_draft ? "eye slash" : "eye"} title={i.is_draft ? "DRAFT" : "PUBLISHED"} onClick={async () => { 
                      await updateItem(i.item_id, { is_draft: !i.is_draft }); 
                      setForceRefresh(!forceRefresh); 
                    }} />}
                    {auth.user?.is_admin && i.is_draft && <Button icon="trash" negative onClick={() => setToDelete(i.item_id)} title="Delete" />}
                    <Button as={Link} to={`${path}/item/${i.item_id}`}>Edit</Button>
                  </Button.Group>
                </Card.Content>
              </Card>
            )
          })
          }
        </Card.Group>
      )}

      <Confirm open={toDelete > 0} onCancel={() => setToDelete(-1)} onConfirm={deleteAnItem} />

      {pagedResults.total === 0 && <em style={{marginTop: "1.5em", display: "block"}}>No items fit your filter.</em> }

      {pagedResults.pageCount > 1 && (
        <div style={{ marginTop: "3em", display: "flex", justifyContent: "center" }}>
          <Pagination activePage={pageNumber} totalPages={pagedResults.pageCount} onPageChange={(_, d) => setPageNumber(d.activePage as number)}/>
        </div>
      )}
    </>
  ) : placeholder));
};

export default ItemList;