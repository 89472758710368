import { useObserver } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Form, Header, Message } from 'semantic-ui-react';

import { Item } from '../../../../api/src/models/item.entity';
import { ErrorContext } from '../../contexts/error';
import { allAttributeValues } from '../../services/attributes';
import { TextWithEmoji, toDropDownSource } from '../../services/html';
import { transparentImageTypes } from '../../services/images';
import { allMyItems, bulkCreateItemsWithImages } from '../../services/items';
import { allItemTypes } from '../../services/itemtypes';
import { allLessons } from '../../services/lessons';

export const BulkAddItems = () => {
  const [type, setType] = useState<number>();
  const [successShow, setSuccessShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [saveError, setSaveError] = useState<string>(null);
  const [uploadResult, setUploadResult] = useState<{items: Item[], errors: any[]}>(null);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({ onDropAccepted: setFiles, accept: transparentImageTypes });
  const { processApiError } = useContext(ErrorContext);

  const save = async () => {
    setSaving(true);

    try {
      const r = await bulkCreateItemsWithImages(type, files);

      setUploadResult(r.data);
      setSaving(false);
      setSuccessShow(true);
      setFiles([]);
      setTimeout(() => setSuccessShow(false), 4000);

      allMyItems.refresh();
    }
    catch (err) {
      processApiError(err);
      setSaveError(err);
    }
    finally {
      setSaving(false);
    }
  }

  return useObserver(() => allItemTypes.current() && allLessons.current() && allAttributeValues.current() ? (
    <>
      <Header className="relaxed">
        Add Bulk Items
        <Header.Subheader>
          Pick a category and upload a bunch of images. They will all go into draft status in the category and will have the file name be the item name. You 
          can go and edit them afterwards and publish them.
        </Header.Subheader>
      </Header>

      <Form onSubmit={save}>  
        <Form.Dropdown required fluid selection search placeholder="Item category" label="Item category"
          options={toDropDownSource(allItemTypes.current(), (i) => i.name, "type_id", (i) => i.icon)}
          value={type} onChange={(_, d) => setType(d.value as number)}
        />

        <Form.Field>
          <label>Item Pictures</label>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {
              <div className="drop-zone">
                {isDragActive ?
                  "Drop the files here..." :
                  <span><strong>Drag and drop files or click to select some.</strong> Please make sure you're allowed to use this file, especially if you plan on making this item public. SVG and PNG are best (in that order) - if possible, use those.</span>
                }
              </div>
            }
            {isDragReject && <strong>Please only use images that support transparent backgrounds like SVGs and PNGs. Thanks!</strong>}
            {files && files.map(f => <div key={f.name}>{f.name}</div>)}
          </div>
        </Form.Field>

        {uploadResult && (
          <Message visible info>
            Created: {uploadResult.items.length} item(s)<br />
            Errors: { JSON.stringify(uploadResult.errors) || "none" }
          </Message>
        )}

        <Button primary loading={saving} disabled={saving}>Add Items</Button>
        {successShow && <div className="saved-message"><TextWithEmoji emoji="tada" text="Saved!" /></div>}
        {saveError && <div className="error-message"><TextWithEmoji emoji="sweat" text={saveError} /></div>}
      </Form>
    </>
  ) : null)
};