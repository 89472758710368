import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Button, Header } from 'semantic-ui-react';

import { allMyStudents } from '../../services/students';
import PageHeader from '../shared/PageHeader';
import AddEditStudent from '../students/AddEditStudent';
import StudentList from '../students/StudentList';
import MustBeSubscribed from '../shared/MustBeSubscribed';
import { SubscriptionLevels } from '../../services/payment';

function UserStudents() {
  const { path } = useRouteMatch();

  return useObserver(() => allMyStudents.current() ? (
    <>
      <PageHeader title="My Students" backText="Back to My Students" />

      <Switch>
        <Route path={`${path}/student/:student_id?`}><AddEditStudent /></Route>
        <Route>
          <Header className="relaxed">
            My Students
            <Header.Subheader>
              A student is anyone you're working with. It's super handy to add them here because then you can customize their favorite emojis,
              backgrounds and other things. Hint: Picking emojis with your students is a fun activity!
            </Header.Subheader>
          </Header>
          
          <MustBeSubscribed minLevel={SubscriptionLevels.Small}>
            <div className="profile-section">
              {allMyStudents.current().length > 0 ?
                <StudentList /> :
                <em>You don't have any students yet. Add one!</em>
              }
            </div>

            <Button positive as={Link} to={`${path}/student`}>Add a Student</Button>
          </MustBeSubscribed>
        </Route>
      </Switch>
    </>
  ) : null)
}

export default UserStudents;