import { Emoji, Picker } from 'emoji-mart';
import { useObserver } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
  Button,
  Confirm,
  Dropdown,
  Form,
  Header,
  Image,
  Input,
  List,
  Message,
  Modal,
  Popup,
  Table,
} from 'semantic-ui-react';

import { Attribute, AttributeValue } from '../../../../api/src/models/attribute.entity';
import { Item } from '../../../../api/src/models/item.entity';
import { ErrorContext } from '../../contexts/error';
import {
  addAttribute,
  addAttributeValue,
  allAttributes,
  deleteAttributeValue,
  findAttributesByName,
  findAttributeValuesByValues,
  getAttribute,
  getAttributeValue,
  resetAttributeLessons,
  resetAttributeValueLessons,
  updateAttribute,
  updateAttributeValue,
} from '../../services/attributes';
import { ExternalLink, TextWithEmoji, toDropDownSource } from '../../services/html';
import { getImageThumbUrl } from '../../services/images';
import { allLessons, filterApplicableLessons } from '../../services/lessons';
import HelpIcon from '../shared/HelpIcon';
import LessonCopier from '../shared/LessonCopier';

export const AddEditAttribute = () => {
  const [name, setName] = useState("");
  const [icon, setIcon] = useState("woman-shrugging");
  const [attributeLessons, setAttributeLessons] = useState<number[]>([]);
  const [values, setValues] = useState<{ value_id: number, value: string, lessons: number[] }[]>([]);  
  const [newValue, setNewValue] = useState<{ value: string, lessons: number[] }>();
  const [editingValue, setEditingValue] = useState<{ value_id: number, value: string, lessons: number[] }>();
  const [dupeValueCheckerOpen, setDupeValueCheckerOpen] = useState(false);
  const [dupeValues, setDupeValues] = useState<AttributeValue[]>([]);
  const [dupeAttributeCheckerOpen, setDupeAttributeCheckerOpen] = useState(false);
  const [dupeAttributes, setDupeAttributes] = useState<Attribute[]>([]);
  const [usedValueCheckerOpen, setUsedValueCheckerOpen] = useState(false);
  const [usageChecker, setUsageChecker] = useState(-1);
  const [usedItems, setUsedItems] = useState<Item[]>([]);
  const [deletingValue, setDeletingValue] = useState(-1);
  
  const [successShow, setSuccessShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveError, setSaveError] = useState<string>(null);
  const [saveValueError, setSaveValueError] = useState<string>(null);

  const { attribute_id } = useParams();
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const { processApiError } = useContext(ErrorContext);

  const refreshAttribute = () => {
    setLoading(true);

    getAttribute(parseInt(attribute_id), ["attribute_lessons", "values", "values.value_lessons"])
      .then(d => {
        setName(d.data.name);
        setIcon(d.data.icon);
        setAttributeLessons(d.data.attribute_lessons.map(l => l.lesson_id));

        setValues(d.data.values.map(v => ({
          value_id: v.value_id,
          value: v.value,
          lessons: v.value_lessons ? v.value_lessons.map(l => l.lesson_id) : []
        })).sort((a, b) => a.value.toLowerCase() < b.value.toLowerCase() ? -1 : 1));
      })
      .catch(processApiError)
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (attribute_id) {
      refreshAttribute();
    }
  // eslint-disable-next-line
  }, [attribute_id]);

  const trySaveAttribute = async () => {
    const dupes = await findAttributesByName(name);

    if (dupes.data.length > 0 && (!attribute_id || dupes.data.find(d => d.attribute_id !== parseInt(attribute_id)))) {
      setDupeAttributes(dupes.data);
      setDupeAttributeCheckerOpen(true);
    }
    else {
      saveAttribute();
    }
  }

  const saveAttribute = async () => {
    setDupeAttributeCheckerOpen(false);
    setDupeAttributes([]);
    setSaving(true);
    setSaveError(null);

    try {
      if (!attribute_id) {
        const attribute = await addAttribute({
          name: name,
          icon: icon === "" ? null : icon
        });
        
        await resetAttributeLessons(attribute.data.attribute_id, attributeLessons);
        push(`${url}/${attribute.data.attribute_id}`);
      }
      else {
        // updating
        await updateAttribute(parseInt(attribute_id), {
          name: name,
          icon: icon === "" ? null : icon
        });

        await resetAttributeLessons(parseInt(attribute_id), attributeLessons);
      }

      allAttributes.refresh();

      setSaving(false);
      setSuccessShow(true);
      setTimeout(() => setSuccessShow(false), 4000);
    }
    catch (err) {
      processApiError(err);
      setSaveError(err);
    }
    finally {
      setSaving(false);
    }
  }

  const tryAddNewValue = async () => {
    setSaveValueError(null);

    if (! newValue?.value || newValue.value.trim() === "") {
      setSaveValueError("A value is required");
      return;
    }

    const dupes = await findAttributeValuesByValues(newValue.value);

    if (dupes.data.length > 0) {
      setDupeValues(dupes.data);
      setDupeValueCheckerOpen(true);
    }
    else {
      addNewValue();
    }
  }

  const addNewValue = async () => {
    setDupeValueCheckerOpen(false);
    setDupeValues([]);
    setSaving(true);
    setSaveError(null);

    try {
      const v = await addAttributeValue(parseInt(attribute_id), newValue.value);
      await resetAttributeValueLessons(v.data.value_id, newValue.lessons);
      
      setNewValue(null);
      refreshAttribute();
    }
    catch (err) {
      processApiError(err);
      setSaveError(err);
    }
    finally {
      setSaving(false);
    }
  }

  const updateValue = async () => {
    setSaving(true);
    setSaveValueError(null);

    try {
      await updateAttributeValue(editingValue.value_id, { value: editingValue.value });
      await resetAttributeValueLessons(editingValue.value_id, editingValue.lessons);

      setEditingValue(null);
      refreshAttribute();
    }
    catch (err) {
      processApiError(err);
      setSaveValueError(err);
    }
    finally {
      setSaving(false);
    }
  }

  const usageCheck = async (value_id: number) => {
    const usage = await getAttributeValue(value_id, ["attribute", "item_attribute_values", "item_attribute_values.item"]);
    
    setUsedItems(usage.data.item_attribute_values?.map(v => v.item) || []);
    setUsageChecker(value_id);
  }

  const tryDeleteValue = async (value_id: number) => {
    setDeletingValue(value_id);

    const usage = await getAttributeValue(value_id, ["attribute", "item_attribute_values", "item_attribute_values.item"]);
    const val = usage.data;

    if (val.item_attribute_values && val.item_attribute_values.length > 0) {
      setUsedItems(val.item_attribute_values.map(v => v.item));
      setUsedValueCheckerOpen(true);
    }
    else {
      deleteValue(value_id);
    }
  }

  const deleteValue = async (value_id?:number) => {
    setUsedValueCheckerOpen(false);
    setUsedItems([]);    
    setEditingValue(null);
    setSaving(true);

    try {
      await deleteAttributeValue(deletingValue < 0 ? value_id : -1);
      setDeletingValue(-1);
      refreshAttribute();
    }
    catch (err) {
      processApiError(err);
      setSaveError(err);
    }
    finally {
      setSaving(false)
    }
  }

  return useObserver(() => allLessons.current() ? (
    <>
      <Header className="relaxed">
        {attribute_id ? "Update" : "Add New"} Attribute
        <Header.Subheader>
          Attributes are properties of items. If your item is a some object, 
          it might have attributes like <strong>shape</strong>, <strong>size</strong>, 
          <strong>number of legs</strong>, <strong>temperature</strong>, etc. Keep in mind
          that specific items might have attributes that are "part" of the item. For example, if your item
          is a "red square", it would have an attribute "color" with the value "red" and an attribute "shape"
          with the value "square". If your item is a concept like "cold", it might have an attribute "temperature"
          with the value "cold".
        </Header.Subheader>
      </Header>

      <Form loading={loading} onSubmit={trySaveAttribute}>
        <Form.Input required label="Attribute name. Try to be generic so this attribute can apply to as many items as possible." placeholder='Attribute Name' fluid value={name} onChange={(_, d) => setName(d.value)}
          action={
            <Popup hoverable on="click" trigger={<Button type="button"><Emoji emoji={icon || "woman-shrugging"} size={24} /></Button>}>
              <Popup.Content><Picker title='Pick your emoji!' emoji='point_up' onSelect={e => setIcon(e.id)} /></Popup.Content>
            </Popup>
          }
        />
        
        <Confirm open={dupeAttributeCheckerOpen} onCancel={() => setDupeAttributeCheckerOpen(false)} onConfirm={() => saveAttribute()} confirmButton="It's not a duplicate - save it!"
          content={(
            <div className="content">
              <p>It looks like this attribute might already exist. Please make sure this makes sense.</p>
              <List>
                {dupeAttributes.map(d => <List.Item key={d.attribute_id}>{d.name}</List.Item>)}
              </List>
            </div>
          )}
        />

        <Button primary loading={saving} disabled={saving}>{attribute_id ? "Update" : "Add"} Attribute</Button>
        {successShow && <div className="saved-message"><TextWithEmoji emoji="tada" text="Saved!" /></div>}
        {saveError && <div className="error-message"><TextWithEmoji emoji="sweat" text={saveError} /></div>}

        { attribute_id ? (
          <>
            <Header size='medium'>
              Add Values To <strong>{name || "Your Attribute"}</strong>
              <Header.Subheader>
                Attributes have values. For example, <strong>color</strong> might have values like "red", "green", or "blue".
              </Header.Subheader>
            </Header>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{ minWidth: 200 }}>Value <HelpIcon text="Attributes can have many values. The attribute 'color' can have values 'blue', 'red', 'green', and many others." /></Table.HeaderCell>
                  <Table.HeaderCell>Applicable Lessons <HelpIcon text="Pick any lessons that might apply to a value (regardless of the attribute). For example, the value 'cold' of the attribute 'temperature' might apply to the lesson 'Initial K' even though the attribute itself (temperature) doesn't and that's OK." /></Table.HeaderCell>
                  <Table.HeaderCell collapsing></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {values.map(v => {
                  const is_editing = editingValue && editingValue.value_id === v.value_id;

                  return (
                    <Table.Row key={v.value_id}>
                      <Table.Cell verticalAlign="bottom">
                        {is_editing ?
                          <Input fluid placeholder="Attribute Value" value={editingValue.value} onChange={(_, d) => setEditingValue({ ...editingValue, value: d.value })} /> :
                          v.value
                        }
                      </Table.Cell>
                      <Table.Cell verticalAlign="bottom">
                        {is_editing ? 
                          <>
                            <label>Edit lessons. <LessonCopier which={["item", "value"]} onSelect={l => setEditingValue({ ...editingValue, lessons: filterApplicableLessons(l, ["value"]).map(ll => ll.lesson_id) })} /></label>
                            <Dropdown fluid selection multiple search placeholder="Applicable Attribute Value Lessons"
                              options={toDropDownSource(allLessons.current().filter(l => l.match_attribute_values), "name", "lesson_id")}
                              value={editingValue?.lessons || []} onChange={(_, d) => setEditingValue({ ...editingValue, lessons: d.value as number[] })}
                            />
                          </> :
                          v.lessons.map(l => allLessons.current().find(al => al.lesson_id === l).name).join(", ")
                        }
                      </Table.Cell>
                      <Table.Cell verticalAlign="bottom">
                        <Button.Group>
                          <Button type="button" icon={is_editing ? "save" : "edit"} disabled={is_editing && saving} positive={is_editing} onClick={() => !is_editing ? setEditingValue(v) : updateValue()} />
                          <Button type="button" primary icon="eye" title="Check usage" onClick={() => usageCheck(v.value_id)} />
                          <Button type="button" negative icon="delete" disabled={saving} onClick={() => v.value_id > 0 ? tryDeleteValue(v.value_id) : setValues(values.filter(f => f.value !== v.value))} />
                        </Button.Group>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell verticalAlign="bottom">
                    <Input fluid placeholder="Add New Attribute Value" value={newValue?.value || ""} onChange={(_, d) => setNewValue({ ...newValue, value: d.value })} />
                    <Confirm open={dupeValueCheckerOpen} onCancel={() => setDupeValueCheckerOpen(false)} onConfirm={() => addNewValue()} confirmButton="It's not a duplicate - save it!"
                      content={(
                        <div className="content">
                          <p>
                            It looks like this value might already exist on a different attribute.
                            Please make sure this makes sense.
                        </p>
                          <List>
                            {dupeValues.map(d => <List.Item key={d.value_id}>{d.attribute.name} - {d.value}</List.Item>)}
                          </List>
                        </div>
                      )}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell verticalAlign="bottom">
                    <label>Add lessons. <LessonCopier which={["item", "value"]} onSelect={l => setNewValue({ ...newValue, lessons: filterApplicableLessons(l, ["value"]).map(ll => ll.lesson_id)})} /></label>
                    <Dropdown fluid selection multiple search placeholder="Applicable Attribute Value Lessons"
                      options={toDropDownSource(allLessons.current().filter(l => l.match_attribute_values), "name", "lesson_id")}
                      value={newValue?.lessons || []} onChange={(_, d) => setNewValue({ ...newValue, lessons: d.value as number[] })}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell verticalAlign="bottom">
                    {saveValueError && <div className="error-message"><TextWithEmoji emoji="sweat" text={saveValueError} /></div>}
                    <Button positive type="button" icon="save" disabled={saving} onClick={tryAddNewValue} />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>

            <Confirm open={usedValueCheckerOpen} onCancel={() => setUsedValueCheckerOpen(false)} onConfirm={() => deleteValue()} confirmButton="That's ok - delete it!"
              content={(
                <div className="content">
                  <p>
                    It looks like this value is being used on some items. If you remove this value, it will be removed from the items too. Please double check.
                  </p>
                  <List horizontal>
                    {usedItems.map(d => (
                      <List.Item key={d.item_id} title={d.name}>
                        <Image size="tiny" src={getImageThumbUrl(d.image_url)} />
                        <div style={{ textAlign: "center" }}>{d.name}</div>
                      </List.Item>))}
                  </List>
                </div>
              )}
            />

            <Modal open={usageChecker > 0} onClose={() => setUsageChecker(-1)}>
              <Modal.Content>
                <p>This attribute is being used on the following items:</p>
                <List horizontal>
                  {usedItems.map(d => (
                    <List.Item key={d.item_id} title={d.name}>
                      <Image size="tiny" src={getImageThumbUrl(d.image_url)} />
                      <div style={{ textAlign: "center" }}><ExternalLink href={`${process.env.REACT_APP_ROOT}/admin/item/item/${d.item_id}`}>{d.name}</ExternalLink></div>
                    </List.Item>))}
                </List>

                {usedItems.length === 0 && <div>None</div>}
              </Modal.Content>
            </Modal>
          </>
        ) : (
          <Message icon="info" info content="You can add values to your attribute after you create it." />
        )}
      </Form>
    </>
  ) : null)
};