import { Emoji } from 'emoji-mart';
import { useObserver } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Header, Segment } from 'semantic-ui-react';

import { ErrorContext } from '../../contexts/error';

function ErrorDisplayer() {
  const { ackError, message } = useContext(ErrorContext);
  
  useEffect(() => {
    ackError();
  }, 
    // eslint-disable-next-line
    []
  );

  return useObserver(() => (
    <Segment placeholder style={{ marginTop: "3em" }}>
      <Header icon>
        <p><Emoji emoji="face_with_head_bandage" size={50} /></p>
        Looks like you took a wrong turn! Click back to try again.
        <Header.Subheader>{message || null}</Header.Subheader>
      </Header>
    </Segment>
  ))
}

export default ErrorDisplayer;