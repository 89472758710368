import { Emoji } from 'emoji-mart';
import React from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import { getImageThumbUrl } from './images';
import { useLocation } from 'react-router-dom';

const subheaderStyle: React.CSSProperties = {
  color: "#aaa",
  paddingTop: ".2em"
}

type StringOrFunction<T> = ((i: T) => string) | keyof T;
type BooleanOrFunction<T> = ((i: T) => boolean) | keyof T;

function toStringValue<T>(item: T, v: StringOrFunction<T>) {
  return typeof v === "function" ? v(item) : item[v] as unknown as string;
}

function toBooleanValue<T>(item: T, v: BooleanOrFunction<T>) {
  return typeof v === "function" ? v(item) : item[v] as unknown as boolean;
}

export function toDropDownSource<T>(
  array: T[], 
  text: StringOrFunction<T>, 
  value: StringOrFunction<T>, 
  /** if starts with http, treat as image, otherwise as emoji */
  graphic?: StringOrFunction<T>, 
  prepend?: string, 
  aside?: StringOrFunction<T>,
  subheader?: StringOrFunction<T>,
  disabled?: BooleanOrFunction<T>)
{  
  const options = array.map(i => {
    const is_img = toStringValue(i, graphic) && toStringValue(i, graphic).startsWith("http");
    const is_emoji = toStringValue(i, graphic) && !toStringValue(i, graphic).startsWith("http");

    return {
      key: toStringValue(i, value),
      value: toStringValue(i, value),
      text: toStringValue(i, text),
      description: toStringValue(i, aside),
      disabled: toBooleanValue(i, disabled),
      content: graphic ? (
        <>
          <div style={{display: "flex", flexDirection: "row"}}>
            <div style={{ display: "flex", marginRight: ".5em" }}>
              {is_img && <div style={{ width: "50px", minWidth: "50px", height: "50px", minHeight: "50px"}}><img alt="" src={getImageThumbUrl(toStringValue(i, graphic))} style={{ maxWidth: "50px", maxHeight: "50px" }} /></div> }
              {is_emoji && <Emoji emoji={toStringValue(i, graphic)} size={24} /> }
            </div>
            
            <div style={{ display: "flex", flexDirection: "column", paddingLeft: ".5em", justifyContent: subheader ? "flex-start" : "center" }}>
              {toStringValue(i, text)}
              {subheader && <div style={subheaderStyle}>{toStringValue(i, subheader)}</div>}    
            </div>          
          </div>
        </>
      ) : (
        <>
          <div>{toStringValue(i, text)}</div>
          <div style={subheaderStyle}>{toStringValue(i, subheader)}</div>
        </>
      )
    } as DropdownItemProps
  });

  if (prepend) {
    options.unshift({
      key: "0",
      value: null,
      text: prepend,
      icon: null
    });
  }

  return options;
}

export function TextWithEmoji(props:{emoji: string, size?: number, text: string, style?: React.CSSProperties, swap?: boolean}) {
  const emoji = <Emoji key="e" emoji={props.emoji} size={props.size || 20} />;
  const text = <span key="t" style={{ paddingRight: props.swap ? ".5em" : "0", paddingLeft: props.swap ? "0" : ".5em", ...props.style }}>{props.text}</span>;
  
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {props.swap ? [text, emoji] : [emoji, text] }
    </div>
  );
}

export function doesOverlap(rect1: DOMRect, rect2: DOMRect) {
  return !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  );
}

export function stringToReact(str: string, style?: React.CSSProperties) {
  return <div style={style} dangerouslySetInnerHTML={{__html: str }} /> 
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/** To get around the eslint nonsense */
export const FakeLink: React.FC<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>> = props => {
  // eslint-disable-next-line
  return <a {...props} style={{cursor: "pointer"}} />
}

export const ExternalLink: React.FC<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>> = props => {
  return <a {...props} target="_blank" rel="noopener noreferrer">{props.children || props.href}</a>
}