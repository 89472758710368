import { Emoji } from 'emoji-mart';
import { useObserver } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button, Card, Input } from 'semantic-ui-react';

import { allAttributes } from '../../services/attributes';

export const ManageAttributes = () => {
  const [attributeFilter, setAttributeFilter] = useState("");

  const { path } = useRouteMatch();

  return useObserver(() => allAttributes.current() ? (
    <>
      <div className="relaxed">
        <Input action={{ icon: 'add', primary:true, as: Link, to: `${path}/attribute`}} placeholder={`Filter ${allAttributes.current().length} attributes...`} onChange={(_, d) => setAttributeFilter(d.value)} fluid />
      </div>

      <Card.Group itemsPerRow={4} stackable>
        {allAttributes.current()
          .filter(a => attributeFilter === "" || a.name.toLowerCase().includes(attributeFilter.toLowerCase()) || a.values.some(v => v.value.toLowerCase().includes(attributeFilter.toLowerCase())))
          .map(a => (
            <Card key={a.attribute_id}>
              <Card.Content>
                <div style={{ float: "right" }}><Emoji emoji={a.icon || "woman-shrugging"} size={16} /></div>
                <Card.Header>{a.name}</Card.Header>                  
                <Card.Description>{a.values?.map(v => v.value).join(", ")}</Card.Description>
              </Card.Content>
              <Card.Content extra>
                <Button as={Link} to={`${path}/attribute/${a.attribute_id}`} basic size="mini">Edit</Button>
              </Card.Content>
            </Card>
          ))
        }
      </Card.Group>
    </>
  ) : null);
};