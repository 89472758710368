import { Emoji } from 'emoji-mart';
import { motion } from 'framer-motion';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Fullscreen from 'react-full-screen';
import { Checkbox, Dropdown, Image, List, Menu, Message, Segment } from 'semantic-ui-react';

import { ItemDisplayLocation } from '../../../../api/src/models/gametheme.entity';
import { Item } from '../../../../api/src/models/item.entity';
import { AuthContext } from '../../contexts/auth';
import { ErrorContext } from '../../contexts/error';
import { PlayContext } from '../../contexts/play';
import { ToolsContext } from '../../contexts/tools';
import { allAttributeValues } from '../../services/attributes';
import {
  getItemDisplayLocation,
  getThemeBuckets,
  ItemDisplayLocationsContainerAdjustment,
  ItemDisplayLocationsItemAdjustment,
} from '../../services/gamethemes';
import { doesOverlap, TextWithEmoji, toDropDownSource } from '../../services/html';
import { getImageThumbUrl } from '../../services/images';
import { allItemTypes } from '../../services/itemtypes';
import { getCurrentStudentNegativeEmoji, getCurrentStudentPositiveEmoji } from '../../services/students';
import { GameProps } from '../shared/GameBase';
import GameTools from './GameTools';
import ItemEmphasizer from './ItemEmphasizer';

type GroupOptions = "category" | "attribute value";
type StateOptions = "active" | "correct" | "incorrect";

type GroupConfig = {
  grouping: GroupOptions,
  // group id (so type_id if grouping is categories), name (Animals), bucket (Tub)
  groups: {
    group_id: number,
    group_name: string,
    bucket_id: number,
    bucket_name: string
  }[]
}

function Sort(props: GameProps) {
  const auth = useContext(AuthContext);
  const tools = useContext(ToolsContext);
  const { processApiError } = useContext(ErrorContext);
  const { playItems, recipeDefinition, theme, updateGameOptions: setGameOptions, onFinish } = useContext(PlayContext);

  const [bucketsRetrieved, setBucketsRetrieved] = useState(false); // so we can wait when opening a recipe
  const [itemLocation, setItemLocation] = useState<ItemDisplayLocation>(recipeDefinition.game_options?.item_location || getItemDisplayLocation("Sort", theme));
  const [itemScatterMap, setItemScatterMap] = useState<{ [id: number]: [number, number] }>({});
  const [buckets, setBuckets] = useState<Item[]>([]);
  const [itemStateMap, setItemStateMap] = useState<{ [id: number]: StateOptions }>({});
  const [makeBigger, setMakeBigger] = useState(recipeDefinition.game_options?.make_bigger || false);
  const [groupBy, setGroupBy] = useState<GroupOptions>(recipeDefinition.game_options?.group_by);
  const [groupConfig, setGroupConfig] = useState<GroupConfig[]>([]);
  const [isFullScreen, setFullScreen] = useState(false);
  const [justFinished, setJustFinished] = useState(false);

  const positive_icon = getCurrentStudentPositiveEmoji(auth.user);
  const negative_icon = getCurrentStudentNegativeEmoji(auth.user);

  useEffect(() => {
    setGameOptions({
      game_name: "Sort",
      game_options: {
        make_bigger: makeBigger,
        item_location: itemLocation,
        group_by: groupBy
      }
    });

    if (itemLocation === "random" && !makeBigger)
      setMakeBigger(true);
  },
  // eslint-disable-next-line
  [
    makeBigger, 
    itemLocation, 
    groupBy
  ]);

  useEffect(() => {
    setBuckets(null);
    setBucketsRetrieved(false);

    if (theme) {
      getThemeBuckets(theme).then(f => {
        setBuckets(f.data.sort(() => Math.random() - .5));
        setBucketsRetrieved(true);
      }).catch(processApiError);
    }
  }, 
    // eslint-disable-next-line
    [theme]
  );

  useEffect(() => {
    setItemStateMap({});

    if (Object.keys(itemScatterMap).length !== playItems.items.length) {
      const getR = () => {
        const r = (Math.random() * 100) - 100 / playItems.items.length;
        return r > 0 ? r : 0;
      }

      setItemScatterMap(playItems.items.reduce((acc, cur, i) => { acc[i] = [getR(), getR()]; return acc; }, {}));
    }
  },
    // eslint-disable-next-line
    [playItems]
  );

  useEffect(() => {
    const allDone = Object.values(itemStateMap).length === playItems.items.length && Object.values(itemStateMap).every(i => i === "correct");

    if (allDone && onFinish && !justFinished) {
      setJustFinished(true);
      onFinish();
    }
  // eslint-disable-next-line
  }, [itemStateMap, justFinished, playItems]);

  useEffect(() => {
    if (bucketsRetrieved) {
      let options: GroupConfig[] = [];

      if (buckets && buckets.length > 0) {
        if (recipeDefinition.item_type_id?.length > 1) {
          options.push({
            grouping: "category",
            groups: recipeDefinition.item_type_id.map((c, i) => ({
              group_id: c,
              group_name: allItemTypes.current().find(t => t.type_id === c).name,
              bucket_id: buckets[i < buckets.length ? i : buckets.length - 1].item_id,
              bucket_name: buckets[i < buckets.length ? i : buckets.length - 1].name,
            }))
          });
        }

        if (recipeDefinition.attribute_value_id?.length > 1) {
          options.push({
            grouping: "attribute value",
            groups: recipeDefinition.attribute_value_id.map((av, i) => ({
              group_id: av,
              group_name: allAttributeValues.current().find(attv => attv.value_id === av).value,
              bucket_id: buckets[i < buckets.length ? i : buckets.length - 1].item_id,
              bucket_name: buckets[i < buckets.length ? i : buckets.length - 1].name,
            }))
          });
        }
      }

      setGroupConfig(options);

      // if grouping already set and is still valid, leave it, otherwise reset it
      if (options.length === 0)
        setGroupBy(null);
      else if (groupBy !== null && options.some(o => o.grouping === groupBy)) {
        // do nothing
      }
      else {
        setGroupBy(options[0].grouping);
      }
    }
  }, 
  // eslint-disable-next-line
  [
    // this should be only things that can affect grouping options
    recipeDefinition.item_type_id,
    recipeDefinition.attribute_value_id,
    bucketsRetrieved
  ]);

  const getStateFor = (id: number) => {
    return itemStateMap[id] || "active";
  }

  const getScatterFor = (index: number) => {
    return itemLocation !== "random" ?
      {} as React.CSSProperties :
      {
        position: "absolute",
        left: `${itemScatterMap[index][0]}%`,
        top: `${itemScatterMap[index][1]}%`
      } as React.CSSProperties
  }

  const variants = {
    "active": { rotate: [0, 0] },
    "correct": { rotate: [0, 0] },
    "incorrect": { 
      rotate: [-15, 15]
    }
  }

  const containerStyle: React.CSSProperties = {
    position: "relative",
    display: "flex",
    backgroundImage: `url(${theme.background_url})`,
    backgroundSize: "cover",
    height: "100vh",
    width: "100%",
    alignItems: "flex-start",
    paddingTop: "5em",
    paddingBottom: "5em",

    ...theme.container_style,
  }

  const ulStyle: React.CSSProperties = {
    listStyle: "none",
    padding: 0,
    margin: 0,
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",

    flexWrap: makeBigger ? "wrap" : null,

    ...ItemDisplayLocationsContainerAdjustment[itemLocation]
  }

  const liStyle: React.CSSProperties = {
    margin: "1em",
    padding: "1em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: 200,
    height: 200,
    
    zIndex: 1,

    maxHeight: makeBigger ? null : `${100 / playItems.items.length}%`,
    maxWidth: makeBigger ? null : `${100 / playItems.items.length}%`,

    ...theme.item_style,
    ...ItemDisplayLocationsItemAdjustment[itemLocation]
  }

  const textStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",

    ...theme.font_style
  }

  const bucketStyle = (bucket_id: number) => {
    // if there's no bucket style, that means these are random buckets and we're
    // going to arrange them along the bottom
    const bucket_style = theme.more_options?.buckets.find(b => b.item_id === bucket_id).style;

    return {
      position: bucket_style ? "absolute" : null,
      width: `${80 / groupConfig.find(c => c.grouping === groupBy).groups.length}%`,
      maxWidth: "30%",
      maxHeight: "30%",

      zIndex: 1,

      ...bucket_style || {}
    } as React.CSSProperties
  }

  const dropItem = (item: Item) => {
    setJustFinished(false);
    const itemrect = document.getElementById(`item_${item.item_id}`).getBoundingClientRect();
    const gconfig = groupConfig.find(c => c.grouping === groupBy);

    if (gconfig) {
      let overlaps_one = false;
      let state: StateOptions = null;

      _.take(buckets, groupConfig.find(c => c.grouping === groupBy).groups.length).forEach(b => {
        // check if overlaps any bucket
        if (doesOverlap(document.getElementById(`bucket_${b.item_id}`).getBoundingClientRect(), itemrect)) {
          // get the config for that bucket
          const bconfig = gconfig.groups.find(g => g.bucket_id === b.item_id);
          overlaps_one = true;

          // check that the item category matches the config
          if (groupBy === "category") {
            state = item.type_id === bconfig.group_id ? "correct" : "incorrect";
          }
          else if (groupBy === "attribute value") {
            state = item.attribute_values.some(av => av.value_id === bconfig.group_id) ? "correct" : "incorrect";
          }

          tools.playSound(state === "correct" ? "positive" : "negative");
        }
      });

      if (! overlaps_one) {
        state = "active";
      }

      setItemStateMap({ ...itemStateMap, [item.item_id]: state });
    }
  }

  return Object.keys(itemScatterMap).length === playItems.items.length ? (
    <>
      <Message info className="tour-game-instructions"><TextWithEmoji emoji="point_right" text="Drag and drop items into the right buckets. You must have at least two categories or attribute values specified in the recipe." /></Message>
      
      <Menu attached="top" className="tour-game-menu">
        <Dropdown item icon="align left" trigger={<React.Fragment />}
          options={Object.keys(ItemDisplayLocationsContainerAdjustment).map(s => ({ text: s, value: s }))}
          value={itemLocation} onChange={(_, d) => setItemLocation(d.value as ItemDisplayLocation)}
        />
        <Menu.Item>
          <Dropdown selection search placeholder="Group by" noResultsMessage={<div style={{whiteSpace:"normal"}}>You have to have at least 2 values or categories in your recipe so you can sort by them!</div>}
            options={toDropDownSource(groupConfig, "grouping", "grouping", null, null, null, (i) => i.groups.map(g => `${g.group_name} => ${g.bucket_name}`).join("; "))}
            value={groupBy} onChange={(_, d) => setGroupBy(d.value as GroupOptions)}
          />
        </Menu.Item>
        <Menu.Item>
          <Checkbox toggle checked={makeBigger} disabled={itemLocation === "random"} onChange={() => setMakeBigger(!makeBigger)} label="Make bigger" />
        </Menu.Item>

        {groupConfig.length > 0 && groupBy && (
          <Menu.Menu position="right">
            <Menu.Item><Emoji emoji={positive_icon} size={20} />&nbsp;&nbsp;{Object.values(itemStateMap).filter(i => i === "correct").length}</Menu.Item>
            <Menu.Item><Emoji emoji={negative_icon} size={20} />&nbsp;&nbsp;{Object.values(itemStateMap).filter(i => i === "incorrect").length}</Menu.Item>
          </Menu.Menu>
        )}
      </Menu>

      <Fullscreen enabled={isFullScreen} onChange={f => setFullScreen(f)}>
        <div style={containerStyle} id="game-container">
          { groupConfig.length > 0 && groupBy && buckets && (
            <Segment inverted color="blue" compact secondary style={{ position: "absolute", left: "1em", top: "1em", zIndex: 2 }} className="game-tools">
              <List divided inverted horizontal>
                {groupConfig.find(c => c.grouping === groupBy).groups.map(g => {
                  const bucket = buckets.find(b => b.item_id === g.bucket_id);
                  
                  return (
                    <List.Item key={bucket.item_id}>
                      <Image size="mini" style={{width: 20, maxWidth: 20 }} src={getImageThumbUrl(bucket.image_url)} />
                      <List.Content>
                        <List.Header>{g.group_name}</List.Header>  
                        => {g.bucket_name}
                      </List.Content>                        
                    </List.Item>
                  );
                })}
              </List>
            </Segment>
          )}        

          <GameTools screenshot fullscreen confetti draw isFullScreen={isFullScreen} onFullScreenToggle={() => setFullScreen(!isFullScreen)} />

          <div style={{display: "flex", position: "absolute", bottom: 0, justifyContent: "space-around", maxHeight: "50%"}}>
            {groupConfig.length > 0 && groupBy && buckets && _.take(buckets, groupConfig.find(c => c.grouping === groupBy).groups.length).map(b => (
              <motion.img draggable="false" id={`bucket_${b.item_id}`} key={b.item_id} alt="" src={b.image_url} style={bucketStyle(b.item_id)} whileHover={{ opacity: .6 }} />
            ))}
          </div>

          <ul style={ulStyle}>
            {playItems.items.map((item, i) => (
              <motion.li style={{...liStyle, ...(getScatterFor(i))}} id={`item_${item.item_id}`} key={item.item_id} drag dragMomentum={false} whileHover={{ scale: 1.1 }} onDragEnd={_ => dropItem(item)} variants={variants} animate={getStateFor(item.item_id)} transition={{ rotate: { duration: 1, yoyo: Infinity }}}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}>
                  <Image draggable="false" src={getImageThumbUrl(item.image_url)} style={{ cursor: "pointer", display: "flex", maxHeight: "100%" }} />
                  <div style={textStyle}>
                    <ItemEmphasizer item={item} recipe={recipeDefinition} ignoreImage />
                  </div>
                </div>
              </motion.li>
            ))}
          </ul>
        </div>
      </Fullscreen>
    </>
  ) : null
};

export default Sort;