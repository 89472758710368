import React from 'react';
import { Header, Segment } from 'semantic-ui-react';

import { ExternalLink } from '../../services/html';

function HelpPage() {
  return (
    <>
      <Header className="relaxed">
        Help, Support & Contact
        <Header.Subheader>
          We want to hear <strong>all your questions</strong>, <strong>all your suggestions</strong>, and definitely <strong>all your problems</strong>! 
          We have a couple of options for getting in touch - take a look below.
        </Header.Subheader>
      </Header>
      
      
      <Segment color="blue">
        <Header className="relaxed" size="medium">For questions, problems and suggestions...</Header>

        <p>
          We are <strong>super responsive</strong> on chat (on the bottom right of every page) and on <ExternalLink href="https://www.facebook.com/guessmonstergames/">Facebook</ExternalLink>.
          Please reach out and we'll get back to you 
          immediately or very, very fast.
        </p>
        <p>
          If you prefer email, you can always email us at <ExternalLink href="mailto:support@guessmonster.com">support@guessmonster.com</ExternalLink>.
        </p>
      </Segment>

      <Segment color="yellow">
        <Header className="relaxed" size="medium">For media, collaboration or contribution inquiries...</Header>

        <p>
          Please email us at <ExternalLink href="mailto:info@guessmonster.com?subject=I need more Guess Monster in my life!">info@guessmonster.com</ExternalLink> to discuss how we can work together. <strong>We'd love to!</strong>
        </p>
      </Segment>
    </>
  )
}

export default HelpPage