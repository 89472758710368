import React from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { Header } from "semantic-ui-react";
import { TextWithEmoji } from "../../services/html";

interface PageHeaderProps {
  title: string,
  backText: string
}

function PageHeader(props: PageHeaderProps) {
  const { path, isExact } = useRouteMatch();

  return (
    <Header size="large" className="relaxed">
      <div style={{display: "flex", minHeight: "1em"}}>
        {!isExact &&
          <Header.Subheader style={{fontSize: "1rem"}}><Link to={path}><TextWithEmoji size={20} emoji="runner" text={props.backText} /></Link></Header.Subheader>
        }
      </div>

      {props.title}
    </Header>
  )
}

export default PageHeader;