import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe, StripeCardElementOptions } from '@stripe/stripe-js';
import { Emoji } from 'emoji-mart';
import { useObserver } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Accordion, Button, Confirm, Form, Header, Icon, Input, Message, Segment } from 'semantic-ui-react';

import { AuthContext } from '../../contexts/auth';
import { ErrorContext } from '../../contexts/error';
import { TextWithEmoji } from '../../services/html';
import { PlanDetails, SubscriptionLevels, unsubscribeUser, updateUserCard } from '../../services/payment';
import { AddEditItem } from '../items/AddEditItem';
import PaymentInfo from '../payments/PaymentInfo';
import PageHeader from '../shared/PageHeader';
import Upgrade from './Upgrade';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const cardStyle = {
  iconStyle: 'solid',
  style: {
    base: {
      fontFamily: "'Quicksand', sans-serif",
      fontSmoothing: 'antialiased',
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
  },
} as StripeCardElementOptions

function UserBilling() {
  const [payError, setPayError] = useState("");
  const [saving, setSaving] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [showDanger, setShowDanger] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [unsubscribeReason, setUnsubscribeReason] = useState("");

  const { path } = useRouteMatch();
  const stripe = useStripe();
  const elements = useElements();
  const auth = useContext(AuthContext);
  const { processApiError } = useContext(ErrorContext);
  
  const updateCard = async (e: React.MouseEvent) => {
    // Block native form submission.
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setPayError("");
    setSaving(true);
    const cardElement = elements.getElement(CardElement);

    try {
      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setPayError(error.message);
      } else {
        await updateUserCard({
          payment_method_id: paymentMethod.id
        });

        setSuccessShow(true);
        setTimeout(() => setSuccessShow(false), 4000);
      }
    }
    catch (err) {
      setPayError(`We had trouble updating your card. Please try again later or let us know! ${err?.response?.data?.raw?.message}`)
      processApiError(err);
    }
    finally {
      setSaving(false);
    }
  };

  const unsubscribe = async () => {
    setSaving(true);

    try {
      const user = await unsubscribeUser(unsubscribeReason);
      auth.updateUser(user.data);
    }
    catch (err) {
      setPayError(`We had trouble unsubscribing you. Please try again or contact us for help. Sorry for the invonvience!`)
      processApiError(err);
    }
    finally {
      setSaving(false);
    }
  }

  return useObserver(() => (
    <>
      <PageHeader title="Subscription & Billing" backText="Back to Billing" />

      <Switch>
        <Route path={`${path}/upgrade`}><Upgrade /></Route>
        <Route path={`${path}/item/:item_id?`}><AddEditItem /></Route>
        <Route>
          <Header className="relaxed">
            Billing Info
          </Header>

          {auth.user?.subscription_level_id > 0 ? (
            <>
              <Header size="small">
                You're currently subscribed to <strong>{PlanDetails[auth.user.subscription_level_id].name}</strong>&nbsp;<Emoji emoji="tada" size={20} />
                {auth.user.subscription_level_id < SubscriptionLevels.Large && (
                  <Link style={{display: "block"}} to="/profile/billing/upgrade">Need to upgrade?</Link>
                )}
              </Header>

              <PaymentInfo refresh={saving} />
            
              <Message attached content='Update Card Information' />
              <Form className="segment attached compact">
                <Form.Field>
                  <CardElement options={cardStyle} />
                  {payError !== "" && <Message visible content={payError} error />}
                </Form.Field>
                <Form.Field>
                  <Button disabled={!stripe} loading={saving} onClick={updateCard}>Update Card</Button>
                  {successShow && <div className="saved-message"><TextWithEmoji emoji="tada" text="Updated!" /></div>}
                </Form.Field>
              </Form>

              <Accordion as={Segment} color="red" style={{ marginTop: "3em" }}>
                <Accordion.Title as={Header} onClick={() => setShowDanger(!showDanger)} active={showDanger}>
                  <Icon name='dropdown' /> Unsubscribe
                  <Header.Subheader>If you really want to unsubscribe, you can do it here.</Header.Subheader>
                </Accordion.Title>
                <Accordion.Content active={showDanger}>
                  If you unsubscribe, please let us know why so we can get better!
                  <br /><br />

                  <Input fluid style={{marginBottom: "1em"}} value={unsubscribeReason} onChange={(_, d) => setUnsubscribeReason(d.value)} placeholder="Please help us be better!" />
                  <Button negative loading={saving} disabled={saving} onClick={() => setConfirmOpen(true)}>Unsubscribe</Button>
                  <Confirm open={confirmOpen} onCancel={() => setConfirmOpen(false)} onConfirm={unsubscribe} confirmButton={{ content: `Unsubscribe`, negative: true }} />
                </Accordion.Content>
              </Accordion>
            </>
          ) : (
            <Redirect to={`${path}/upgrade`} />
          )}
        </Route>
      </Switch>
    </>
  ))
}

const UserBillingWrapper = () => <Elements stripe={stripePromise}><UserBilling /></Elements>

export default UserBillingWrapper;