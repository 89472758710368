import React, { useContext, useEffect, useState } from 'react';
import { List } from 'semantic-ui-react';

import { AuthContext } from '../../contexts/auth';
import { ErrorContext } from '../../contexts/error';
import { getPaymentInfo } from '../../services/payment';

function PaymentInfo(props: {refresh?: boolean}) {
  const [info, setInfo] = useState<any>();
  
  const auth = useContext(AuthContext);
  const { processApiError } = useContext(ErrorContext);

  const { refresh } = props;

  useEffect(() => {
    getPaymentInfo()
      .then(i => setInfo(i.data.data))
      .catch(processApiError);
  }, 
    // eslint-disable-next-line
    [refresh]
  );

  if (! auth.user?.subscription_level_id) {
    return null;
  }

  return info ? (
    <List horizontal>
      <List.Item>We have a card on file:</List.Item>
      <List.Item style={{ textTransform: "capitalize" }}><strong>{(info[0].card.brand)}</strong></List.Item>
      <List.Item><strong>ending in {info[0].card.last4}</strong></List.Item>
      <List.Item><strong>expiring {info[0].card.exp_month}/{info[0].card.exp_year}</strong></List.Item>
    </List>
  ) : <List horizontal><List.Item>Loading card information...</List.Item></List>;
}

export default PaymentInfo;