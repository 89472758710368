import React from 'react';

import { GameRecipeDefinition } from '../../../../api/src/models/gamerecipe.entity';
import { GamePlayItem } from '../../../../api/src/types/game';
import { getImageThumbUrl } from '../../services/images';

function ItemEmphasizer(props: {recipe: GameRecipeDefinition, item: GamePlayItem, invalidAsBlank?: boolean, ignoreImage?: boolean}) {
  const deemphasized = (s: string) => <span style={{textDecoration: "line-through"}}>{s}</span>
  
  switch(props.recipe.emphasize) {
    case "item category": return <div>{props.item.type.name}</div>;
    case "item name": return <div>{props.item.name}</div>;
    case "attribute value": return <div>{props.item.attribute_values.filter(v => v.attribute_value.attribute_id === props.recipe.emphasize_attribute_id).map(v => v.attribute_value.value).join(", ") || (props.invalidAsBlank ? null : props.recipe.emphasize_attribute_id ? deemphasized(props.item.name) : props.item.name)}</div>;
    case "lesson match": return <div>{[props.item.item_lesson_match, props.item.attribute_lesson_match].filter(i => i).join(", ")}</div>;
    case "image": return props.ignoreImage ? null : <img src={getImageThumbUrl(props.item.image_url)} style={{ width: "40%" }} alt={props.item.name} />;
    case "nothing": return null;
    default: return props.invalidAsBlank ? null : <div>{props.item.name}</div>;
  }
}

export default ItemEmphasizer;