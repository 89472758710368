import { useObserver } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { Button, Confirm, Form, Header, Image, List, Message } from 'semantic-ui-react';

import { Item, ItemFacingDirections } from '../../../../api/src/models/item.entity';
import { AuthContext } from '../../contexts/auth';
import { ErrorContext } from '../../contexts/error';
import { allAttributeValues } from '../../services/attributes';
import { ExternalLink, TextWithEmoji, toDropDownSource } from '../../services/html';
import { getImageThumbUrl, transparentImageTypes } from '../../services/images';
import {
  addItem,
  allMyItems,
  getItem,
  resetItemLessons,
  resetItemValues,
  updateItem,
  updateItemImageByFile,
} from '../../services/items';
import { allItemTypes } from '../../services/itemtypes';
import { allLessons, filterApplicableLessons } from '../../services/lessons';
import { searchSpecific } from '../../services/search';
import AttributeCopier from '../shared/AttributeCopier';
import LessonCopier from '../shared/LessonCopier';

export const AddEditItem = () => {
  const [name, setName] = useState("");
  const [type, setType] = useState<number>();
  const [facingDirection, setFacingDirection] = useState<ItemFacingDirections>();
  const [existingImageUrl, setExistingImageUrl] = useState("");
  const [itemLessons, setItemLessons] = useState<number[]>([]);
  const [itemValues, setItemValues] = useState<number[]>([]);
  const [dupes, setDupes] = useState<Item[]>([]);
  const [dupeItemCheckerOpen, setDupeItemCheckerOpen] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [saveError, setSaveError] = useState<string>(null);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({ onDropAccepted: setFiles, accept: transparentImageTypes });
  const auth = useContext(AuthContext);
  const { processApiError, setError } = useContext(ErrorContext);

  const { item_id } = useParams();
  
  useEffect(() => {
    if (item_id) {
      setLoading(true);

      getItem(parseInt(item_id), ["item_lessons", "attribute_values"])
        .then(d => {
          if (d.data.created_by === auth.user?.user_id || auth.user?.is_admin) {
            setName(d.data.name);
            setType(d.data.type_id);
            setFacingDirection(d.data.facing_direction);
            setExistingImageUrl(d.data.image_url);
            setItemLessons(d.data.item_lessons.map(l => l.lesson_id));
            setItemValues(d.data.attribute_values.map(v => v.value_id));
          }
          else {
            setError("401", "You can only edit your own items.");
          }
        })
        .catch(processApiError)
        .finally(() => setLoading(false));
    }
  },
    // eslint-disable-next-line 
    [item_id, auth.user]
  )

  const trySaveItem = async () => {
    // don't check dupes for nonadmins
    const getDupes = () => searchSpecific<Item>("item", { "name": { "$contL": name } }, ["type"]);
    const dupes = auth.user?.is_admin ? (await getDupes()).data.data : [];
    const filtered = dupes.filter(d => !item_id || d.item_id !== parseInt(item_id));

    if (filtered.length > 0) {
      setDupes(filtered);
      setDupeItemCheckerOpen(true);
    }
    else {
      save();
    }
  }

  const uploadFiles = async (item_id: number) => {
    if (files.length > 0) {
      return updateItemImageByFile(item_id, files[0]);
    }
  }

  const resetForm = () => {
    setName("");
    setType(null);
    setItemLessons([]);
    setItemValues([]);
  }

  const save = async () => {
    setDupeItemCheckerOpen(false);
    setDupes([]);
    setSaving(true);

    try {
      if (! item_id) {
        const item = await addItem({
          name: name,
          type_id: type,
          facing_direction: facingDirection
        });

        await resetItemLessons(item.data.item_id, itemLessons);
        await resetItemValues(item.data.item_id, itemValues);
        await uploadFiles(item.data.item_id);

        resetForm();
      }
      else {
        const item = await updateItem(parseInt(item_id), {
          name: name,
          type_id: type,
          facing_direction: facingDirection
        });

        await resetItemLessons(item.data.item_id, itemLessons);
        await resetItemValues(item.data.item_id, itemValues);
        await uploadFiles(item.data.item_id);
      }

      setSaving(false);
      setSuccessShow(true);
      setTimeout(() => setSuccessShow(false), 4000);

      allMyItems.refresh();
    }
    catch (err) {
      processApiError(err);
      setSaveError(err);
    }
    finally {
      setSaving(false);
    }
  }

  return useObserver(() => allItemTypes.current() && allLessons.current() && allAttributeValues.current() ? (
    <>
      <Header className="relaxed">
        {item_id ? "Update" : "Add New"}  Item
        <Header.Subheader>
          Items are things and concepts that have attributes and can be talked about. A "monkey" is an item, "red square" is an item, "emotion" is an item.
        </Header.Subheader>
      </Header>

      <Form loading={loading} onSubmit={trySaveItem}>  
        <Form.Group widths="equal">
          <Form.Input required label="Item name" placeholder='Item name' fluid value={name} onChange={(_, d) => setName(d.value)} />
          <Form.Dropdown required fluid selection search placeholder="Item category" label="Item category"
            options={toDropDownSource(allItemTypes.current(), (i) => i.name, "type_id", (i) => i.icon)}
            value={type} onChange={(_, d) => setType(d.value as number)}
          />
        </Form.Group>
        
        <Confirm open={dupeItemCheckerOpen} onCancel={() => setDupeItemCheckerOpen(false)} onConfirm={save} confirmButton="It's not a duplicate - save it!"
          content={(
            <div className="content">
              <p>It looks like this item might already exist. Please double check that it's different from the ones below.</p>
              <List horizontal>
                {dupes.map(d => (
                  <List.Item key={d.item_id} title={d.name}>
                    <Image size="tiny" src={getImageThumbUrl(d.image_url)} />
                    <div style={{textAlign: "center"}}>{d.name}</div>
                  </List.Item>))}
              </List>
            </div>
          )}
        />

        <Form.Dropdown fluid selection multiple search placeholder="Applicable Item Lessons"
          label={(
            <label>
              Pick any lessons that might apply to the item itself (not a specific attribute of it). For example, 'Cube' might apply to the lesson 'Initial K'.
              {' '}<LessonCopier which={["item", "value"]} onSelect={l => setItemLessons(filterApplicableLessons(l, ["item"]).map(ll => ll.lesson_id))} />
            </label>
          )}
          options={toDropDownSource(allLessons.current().filter(l => l.match_items), "name", "lesson_id")}
          value={itemLessons} onChange={(_, d) => setItemLessons(d.value as number[])}
        />

        <Form.Dropdown className="menu-grid" fluid selection multiple search placeholder="Attribute values - pick as many as you'd like"
          label={(
            <label>
              Pick any values that this item has. If you don't see attributes or values you want, please reach out to us - we're always adding more!
              {' '}<AttributeCopier onSelect={l => setItemValues(l)} />
            </label>
          )}
          options={toDropDownSource(allAttributeValues.current(), (i) => `${i.attribute.name}: ${i.value}`, "value_id", (i) => i.attribute.icon)}
          value={itemValues} onChange={(_, d) => setItemValues(d.value as number[])}
        />

        {item_id && existingImageUrl && (
          <Form.Field>
            <Message warning visible icon>
              <Image src={getImageThumbUrl(existingImageUrl)} size="tiny" style={{ marginRight: "1em" }} />
              <Message.Content>
                <Message.Header>
                  This is your current item image
                </Message.Header>
                
                When updating an item, if you leave the section below alone, the item will retain the current image. Or you can replace the image. Please note that if you're
                replacing the image, it can take a few minutes and up to an hour for it to be processed and the new image to appear in games and on the site. <ExternalLink href={existingImageUrl}>Click here for the full size version</ExternalLink>.
              </Message.Content>
            </Message>
          </Form.Field>
        )}

        <Form.Field>
          <label>Item Picture</label>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {
              <div className="drop-zone">
                {isDragActive ?
                  "Drop the file here..." :
                  <span><strong>Drag and drop a file or click to select one.</strong> Please make sure you're allowed to use this file, especially if you plan on making this item public. SVG and PNG are best (in that order) - if possible, use those.</span>
                }
              </div>
            }
            {isDragReject && <strong>Please only use images that support transparent backgrounds like SVGs and PNGs. Thanks!</strong>}
            {files && files.length > 0 && <div>{files[0].name}</div>}
          </div>
        </Form.Field>

        <Form.Dropdown fluid selection placeholder="Facing direction" label="This image is facing... (for floating games, defaults to 'Right')"
          options={toDropDownSource([{key: "r", text: "Right", icon: "arrow_right"}, { key: "l", text: "Left", icon: "arrow_left" }], "text", "key", "icon", "Not Applicable")}
          value={facingDirection} onChange={(_, d) => setFacingDirection(d.value as ItemFacingDirections)}
        />

        <Button primary loading={saving} disabled={saving}>{item_id ? "Update" : "Add"} Item</Button>
        {successShow && <div className="saved-message"><TextWithEmoji emoji="tada" text="Saved!" /></div>}
        {saveError && <div className="error-message"><TextWithEmoji emoji="sweat" text={saveError} /></div>}
      </Form>
    </>
  ) : null)
};