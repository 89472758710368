import { Emoji } from 'emoji-mart';
import { useObserver } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button, Card, Input, List } from 'semantic-ui-react';

import { allItemTypes } from '../../services/itemtypes';

export const ManageItemTypes = () => {
  const [typeFilter, setTypeFilter] = useState("");

  const { path } = useRouteMatch();

  return useObserver(() => allItemTypes.current() ? (
    <>
      <div className="relaxed">
        <Input action={{ icon: 'add', primary:true, as: Link, to: `${path}/add`}} placeholder={`Filter ${allItemTypes.current().length} categories...`} onChange={(_, d) => setTypeFilter(d.value)} fluid />
      </div>

      <Card.Group itemsPerRow={5}>
        {allItemTypes.current()
          .filter(a => typeFilter === "" || a.name.toLowerCase().includes(typeFilter.toLowerCase()))
          .map(l => (
            <Card key={l.type_id}>
              <Card.Content>
                <div style={{ float: "right" }}><Emoji emoji={l.icon} size={16} /></div>
                <Card.Header>{l.name}</Card.Header>
                <Card.Description>
                  <List>
                    <List.Item content={`${l.item_count} item${l.item_count === 1 ? "" : "s"}`} />
                  </List>
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <Button as={Link} to={`${path}/${l.type_id}/edit`} basic size="mini">Edit</Button>
              </Card.Content>
            </Card>
          ))
        }          
      </Card.Group>
    </>
  ) : null);
};