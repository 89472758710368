const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const numberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1
});

const percentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});

export function formatCurrency(amt: number | string) {
  if (!amt || (typeof amt === "string" && amt.trim() === ""))
    return null;

  return currencyFormatter.format(typeof amt === "string" ? parseFloat(amt.replace(/,/g, "")) : amt);
}

export function formatNumber(num: number) {
  return num ? numberFormatter.format(num) : null;
}

export function formatPercent(num: number) {
  return num ? percentFormatter.format(num) : null;
}

export function toFriendlyString(num: number) {
  if (! num || isNaN(num))
    return "many";
    
  return num < 1000 ? num.toString() : `${(num / 1000).toFixed(1)}k`;
}