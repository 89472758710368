import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { AddEditItemType } from './AddEditItemType';
import { ManageItemTypes } from './ManageItemTypes';

function ItemTypesHome () {
  let { path } = useRouteMatch();
  
  return (
    <Switch>
      <Route path={`${path}/add`} component={AddEditItemType} />
      <Route path={`${path}/:type_id/edit`} component={AddEditItemType} />
      <Route path={path} component={ManageItemTypes} />
    </Switch>
  );
}

export default ItemTypesHome;