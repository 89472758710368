import React from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';

import { User } from '../../../../api/src/models/user.entity';
import { Tour } from '../../../../api/src/types/tours';
import { userWatchedTour } from '../../services/user';

const tour_steps: {[key in Tour]: Step[]} = {
  "Game Overview": [{
      target: ".tour-recipe",
      disableBeacon: true,
      title: "Quick Guess Monster Overview",
      content: <div><strong>Recipes</strong> is where the magic happens - every combination of lesson, item, game, theme, and everything else is a recipe you can save for later.</div>
    }, {
      target: ".tour-games",
      disableBeacon: true,
      content: <div>Pick from many <strong>games</strong> but remember that each game can have <em>many</em> uses depending on the recipe and theme!</div>
    }, {
      target: ".tour-themes",
      disableBeacon: true,
      content: <div>Pick from lots of fun <strong>themes</strong> or head over to your profile to make custom ones.</div>
    }, {
      target: ".tour-save-recipe",
      disableBeacon: true,
      content: <div>Save <strong>all your recipes</strong> here so you can pull them up with a click.</div>
    }, {
      target: ".tour-games-link",
      disableBeacon: true,
      content: <div>Or go here to search <strong>tens of thousands of amazing recipes</strong>.</div>
    }, {
      target: ".tour-tools",
      disableBeacon: true,
      content: <div>Use tools like <strong>Instant Confetti</strong>!</div>
    }, {
      target: ".tour-students",
      disableBeacon: true,
      content: <div>Manage your students here so you can customize the experience for every child.</div>
    }, {
      target: ".tour-profile",
      disableBeacon: true,
      content: <div>Manage items, themes, collections, billing and more!</div>
    }, {
      target: ".tour-game-instructions",
      disableBeacon: true,
      content: <div>Keep an eye out for <strong>game-specific instructions</strong> here (on some games).</div>
    }, {
      target: ".tour-game-menu",
      disableBeacon: true,
      content: <div>And <strong>game-specific options</strong> are over here. These are part of the recipes too!</div>,
    }, {
      target: ".tour-game-tools",
      disableBeacon: true,
      content: <div>Finally, don't forget that some games allow you to go full screen and even take a picture so you can send it to parents, share it online, or just for fun!<br /><br /><strong>Have fun!</strong></div>,
    }]
}

function TourPlayer(props:{user?: User, tour: Tour, force?: boolean, onFinish?: () => void}) {
  const onStateChange = (d:CallBackProps) => {
    if (d.action === "reset") {
      if (props.user) {
        userWatchedTour(props.user, props.tour);
      }
    }
  }

  return (
    <Joyride run={props.user && (props.force || !props.user?.tours_watched?.find(t => t === props.tour))} showSkipButton continuous callback={onStateChange} styles={{ tooltipContent: { textAlign: "left" } }} steps={tour_steps[props.tour]} />
  );
}

export default TourPlayer;