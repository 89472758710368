import { Emoji, Picker } from 'emoji-mart';
import { useObserver } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Confirm, Form, Header, List, Popup } from 'semantic-ui-react';

import { ItemType } from '../../../../api/src/models/item.entity';
import { SearchResult } from '../../../../api/src/types/search';
import { ErrorContext } from '../../contexts/error';
import { TextWithEmoji } from '../../services/html';
import { addItemType, allItemTypes, findItemTypesByName, getItemType, updateItemType } from '../../services/itemtypes';
import ItemQuickList from '../shared/ItemQuickList';

export const AddEditItemType = () => {
  const [name, setName] = useState("");
  const [icon, setIcon] = useState("woman-shrugging");
  const [dupeTypes, setDupeTypes] = useState<ItemType[]>([]);
  const [dupeTypeCheckerOpen, setDupeTypeCheckerOpen] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typeItems, setTypeItems] = useState<SearchResult[]>([])

  const { type_id } = useParams();
  const { processApiError } = useContext(ErrorContext);

  useEffect(() => {
    if (type_id) {
      setLoading(true);

      getItemType(parseInt(type_id), ["items"])
        .then(d => {
          setName(d.data.name);
          setIcon(d.data.icon);

          setTypeItems(d.data.items.map(i => ({
            id: i.item_id,
            name: i.name,
            which: "item",
            image_url: i.image_url
          })));
        })
        .catch(processApiError)
        .finally(() => setLoading(false));
    }
  },
    // eslint-disable-next-line
    [type_id]
  )

  const trySaveItemType = async () => {
    const dupes = await findItemTypesByName(name);

    if (dupes.data.length > 0 && (!type_id || dupes.data.find(d => d.type_id !== parseInt(type_id)))) {
      setDupeTypes(dupes.data);
      setDupeTypeCheckerOpen(true);
    }
    else {
      saveItemType();
    }
  }

  const resetForm = () => {
    setName("");
    setIcon("");
  }

  const saveItemType = async () => {
    setDupeTypeCheckerOpen(false);
    setDupeTypes([]);
    setSaving(true);

    if (! type_id) {
      await addItemType({
        name: name,
        icon: icon === "" ? null : icon,
      });

      resetForm();
    }
    else {
      await updateItemType(parseInt(type_id), {
        name: name,
        icon: icon === "" ? null : icon,
      });
    }

    setSaving(false);
    setSuccessShow(true);
    
    allItemTypes.refresh();

    setTimeout(() => setSuccessShow(false), 4000);
  }

  return useObserver(() => allItemTypes.current() ? (
    <>
      <Header className="relaxed">
        {type_id ? "Update" : "Add New"}  Category
        <Header.Subheader>
          All items have a category. A category can be "animals", or "shapes", or "countries" or "concepts", etc. Please use <em>plurals</em> for category names (Animals instead of Animal, for example).
        </Header.Subheader>
      </Header>

      <Form loading={loading} onSubmit={trySaveItemType}>
        <Form.Input required label="Category name" placeholder='Category Name' fluid value={name} onChange={(_, d) => setName(d.value)}
          action={
            <Popup hoverable on="click" trigger={<Button type="button"><Emoji emoji={icon} size={24} /></Button>}>
              <Popup.Content><Picker title='Pick your emoji!' emoji='point_up' onSelect={e => setIcon(e.id)} /></Popup.Content>
            </Popup>
          }
        />
        <Confirm open={dupeTypeCheckerOpen} onCancel={() => setDupeTypeCheckerOpen(false)} onConfirm={() => saveItemType()} confirmButton="It's not a duplicate - save it!"
          content={(
            <div className="content">
              <p>It looks like this category might already exist. Please double check that it's different from the ones below.</p>
              <List>
                {dupeTypes.map(d => <List.Item key={d.type_id} header={d.name} />)}
              </List>
            </div>
          )}
        />
        
        <Button primary loading={saving} disabled={saving}>{type_id ? "Update" : "Add"} Category</Button>
        {successShow && <div className="saved-message"><TextWithEmoji emoji="tada" text="Saved!" /></div>}
      </Form>

      {type_id && (
        <>
          <Header size='medium'>
            Quick View Category Items
          </Header>

          <ItemQuickList items={typeItems} />
        </>
      )}
    </>
  ) : null)
};