import { Emoji } from 'emoji-mart';
import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button, Card, Placeholder } from 'semantic-ui-react';

import { allMyStudents, CurrentStudent } from '../../services/students';

function StudentList() {
  const { path } = useRouteMatch();
  
  return useObserver(() => allMyStudents.current() ? (
    <Card.Group itemsPerRow={3}>
      { allMyStudents.current().map(s => (
        <Card key={s.student_id}>
          <div style={{display: "flex", height: 200, alignItems: "center", justifyContent: "center", background: `url(${s?.other_student_settings?.background_url})`}}>
            <Emoji emoji={s.default_positive_icon} size={60} />
          </div>
          <Card.Content>
            <Card.Header>{s.name}</Card.Header>
          </Card.Content>
          <Card.Content extra>
            <Button.Group basic size="mini">
              <Button onClick={() => CurrentStudent.set(s)}>Set</Button>
              <Button as={Link} to={`${path}/student/${s.student_id}`}>Edit</Button>
            </Button.Group>
            
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  ) : (
    <Placeholder style={{ width: 200 }}>
      <Placeholder.Image square />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder>
  ))
}

export default StudentList;