import { useObserver } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Button, Confirm, Divider, Form, Header, List, Message } from 'semantic-ui-react';

import { Lesson } from '../../../../api/src/models/lesson.entity';
import { SearchResult } from '../../../../api/src/types/search';
import { ErrorContext } from '../../contexts/error';
import { TextWithEmoji } from '../../services/html';
import {
  addLesson,
  addLessonItems,
  addLessonValues,
  allLessons,
  findLessonsByName,
  getLesson,
  removeLessonItem,
  removeLessonValue,
  updateLesson,
} from '../../services/lessons';
import MultiItemSelector from '../shared/MultiItemSelector';
import MultiValueSelector from '../shared/MultiValueSelector';

export const AddEditLesson = () => {
  const [lesson, setLesson] = useState<Partial<Lesson>>({ match_items: true, match_attribute_values: false });
  const [dupeLessons, setDupeLessons] = useState<Lesson[]>([]);
  const [dupeLessonCheckerOpen, setDupeLessonCheckerOpen] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lessonItems, setLessonItems] = useState<SearchResult[]>([]);
  const [lessonValues, setLessonValues] = useState<SearchResult[]>([]);

  const { processApiError } = useContext(ErrorContext);
  const { lesson_id } = useParams();
  const { push } = useHistory();
  const { url } = useRouteMatch();

  useEffect(() => {
    if (lesson_id) {
      setLoading(true);

      getLesson(parseInt(lesson_id), [
        "item_lessons", "item_lessons.item", "attribute_value_lessons", 
        "attribute_value_lessons.attribute_value", "attribute_value_lessons.attribute_value.attribute"
      ])
        .then(d => {
          setLesson(d.data);

          setLessonItems(d.data.item_lessons.map(i => ({
            id: i.item_id,
            name: i.item.name,
            which: "item",
            image_url: i.item.image_url
          })));

          setLessonValues((d.data.attribute_value_lessons || []).map(v => ({
            id: v.value_id,
            name: `${v.attribute_value.attribute.name}: ${v.attribute_value.value}`,
            which: "value",
            image_url: v.attribute_value.attribute.icon
          })))
        })
        .catch(processApiError)
        .finally(() => setLoading(false));
    }
  }, 
    // eslint-disable-next-line
    [lesson_id]
  )

  const onItemsChange = async (allSelected: SearchResult[], currentChange?: { item: SearchResult, action: "added" | "removed" }) => {
    if (currentChange.action === "added") {
      await addLessonItems(parseInt(lesson_id), [currentChange.item.id]);
    }
    else {
      await removeLessonItem(parseInt(lesson_id), currentChange.item.id);
    }

    setLessonItems(allSelected);
  }

  const onValuesChange = async (allSelected: SearchResult[], currentChange?: { item: SearchResult, action: "added" | "removed" }) => {
    if (currentChange.action === "added") {
      await addLessonValues(parseInt(lesson_id), [currentChange.item.id]);
    }
    else {
      await removeLessonValue(parseInt(lesson_id), currentChange.item.id);
    }

    setLessonValues(allSelected);
  }

  const trySaveLesson = async () => {
    const dupes = await findLessonsByName(lesson.name);

    if (dupes.data.length > 0 && (!lesson_id || dupes.data.find(d => d.lesson_id !== parseInt(lesson_id)))) {
      setDupeLessons(dupes.data);
      setDupeLessonCheckerOpen(true);
    }
    else {
      saveLesson();
    }
  }

  const saveLesson = async () => {
    setDupeLessonCheckerOpen(false);
    setDupeLessons([]);
    setSaving(true);

    if (! lesson_id) {
      const l = await addLesson({ ...lesson, lesson_id: null });
      push(`${url}/${l.data.lesson_id}`);
    }
    else {
      await updateLesson(parseInt(lesson_id), lesson);
    }

    setSaving(false);
    setSuccessShow(true);
    
    allLessons.refresh();

    setTimeout(() => setSuccessShow(false), 4000);
  }

  return useObserver(() => (
    <>
      <Header className="relaxed">
        {lesson_id ? "Update" : "Add New"}  Lesson
        <Header.Subheader>
          Lessons are topics you discuss - it could be something like "Initial Th-" or a concept like "Temperature".
          You can link items to lessons (a "Cat" might be in the "Animals" lesson) or you can link attributes to lessons (a "Temperature" attribute might be in the "Temperature" lesson)
          or you can link attribute values to lessons ("Red" is a value of the "Color" attribute and you can link "Red" to the "Colors" lesson). In short, lessons can be about anything.
        </Header.Subheader>
      </Header>

      <Form loading={loading} onSubmit={trySaveLesson}>
        <Form.Input required label="New lesson name" placeholder='Lesson Name' fluid value={lesson.name || ""} onChange={(_, d) => setLesson({...lesson, name: d.value})} />
        <Confirm open={dupeLessonCheckerOpen} onCancel={() => setDupeLessonCheckerOpen(false)} onConfirm={() => saveLesson()} confirmButton="It's not a duplicate - save it!"
          content={(
            <div className="content">
              <p>It looks like this lesson might already exist. Please double check that it's different from the ones below.</p>
              <List>
                {dupeLessons.map(d => <List.Item key={d.lesson_id} header={d.name} content={d.description} />)}
              </List>
            </div>
          )}
        />

        <Form.TextArea label="Description" placeholder='Optional description explaining what this lesson is supposed to be about'
          value={lesson.description || ""} onChange={(_, d) => setLesson({ ...lesson, description: d.value.toString()})}
        />

        <Form.Field>
          <label>This lesson can apply to...</label>
          <Form.Checkbox checked={lesson.match_items} onChange={(_, d) => setLesson({...lesson, match_items: d.checked})} label="Items (a lesson like Initial K might apply to a Car)" />
          <Form.Checkbox checked={lesson.match_attribute_values || false} onChange={(_, d) => setLesson({ ...lesson, match_attribute_values: d.checked })} label="Attribute Values (a lesson like Emotions might apply to all items that Emotion: Happy in them)" />
        </Form.Field>
      
        <Button primary loading={saving} disabled={saving}>{lesson_id ? "Update" : "Add"} Lesson</Button>
        {successShow && <div className="saved-message"><TextWithEmoji emoji="tada" text="Saved!" /></div>}
      </Form>

      {lesson_id && (
        <>
          <Divider section />

          {lesson.match_items ? (
            <>
              <Header size='small'>
                Manage Lesson Items
                <Header.Subheader>
                  You can assign lessons to items from the item page or you can assign multiple items to lessons from here.<br /><strong>Note that these will update immediately as you add or remove them.</strong>
                </Header.Subheader>
              </Header>

              <MultiItemSelector placeholder="Find items..." items={lessonItems} onChange={onItemsChange} />
            </>
          ) : lessonItems.length > 0 ? (
            <Message warning>This lesson is tied to items but you're turning off its ability to be tied to items. If you save the lesson, we'll clear all of its connections to items. The items themselves will be unchanged.</Message>
          ) : null}

          {lesson.match_attribute_values ? (
            <>
              <Header size='small'>
                Manage Lesson Attribute Values
                <Header.Subheader>
                  You can assign lessons to attribute values when editing a specific attribute or you can do it from here.<br /><strong>Note that these will update immediately as you add or remove them.</strong>
                </Header.Subheader>
              </Header>

              <MultiValueSelector placeholder="Find values..." items={lessonValues} onChange={onValuesChange} />            
            </>
          ) : lessonValues.length > 0 ? (
            <Message warning>This lesson is tied to attribute values but you're turning off its ability to be tied to them. If you save the lesson, we'll clear all of its connections to attribute values. The values themselves will be unchanged.</Message>
          ) : null}
        </>
      )}
    </>
  ))
};