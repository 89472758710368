import React from 'react';
import { Header, List } from 'semantic-ui-react';

import { ExternalLink } from '../../services/html';

function Thanks() {
  return (
    <>
      <Header className="relaxed">
        Thanks &amp; Attributions
        <Header.Subheader>
          The graphics on this site are a combination of unique, licensed, and public domain images that we're grateful to be able to use. 
          We make sure to make all proper attributions but if you notice that something is missing, please let us know! These are in no particular order, 
          but we're thankful to everyone who makes publically-available content!
        </Header.Subheader>
      </Header>
      
      <List relaxed>
        <List.Item><ExternalLink href="https://www.freepik.com/free-photos-vectors/background">Background vector created by kostolom3000 - www.freepik.com</ExternalLink></List.Item>
        <List.Item><ExternalLink href="https://ya-webdesign.com" /></List.Item>
        <List.Item>Icons made by <ExternalLink href="https://www.flaticon.com/authors/freepik">Freepik from flaticon.com</ExternalLink></List.Item>
        <List.Item><ExternalLink href="https://www.goodfreephotos.com/">Good Free Photos</ExternalLink></List.Item>
        <List.Item><ExternalLink href="https://svg-clipart.com/blue/HeGsGsg-godzilla-emerging-from-sea-clipart" /></List.Item>

        <List.Item>Man Doing Push Ups Cartoon.svg from Wikimedia Commons by <ExternalLink href="https://videoplasty.com">Videoplasty.com</ExternalLink>, CC-BY-SA 4.0</List.Item>
        <List.Item>Corporate Woman Talking on the Phone Leaning On Her Desk.svg from Wikimedia Commons by <ExternalLink href="https://videoplasty.com">Videoplasty.com</ExternalLink>, CC-BY-SA 4.0</List.Item>
        <List.Item>Black Man Talking Angry on the Phone Cartoon Vector Cartoon Vector.svg from Wikimedia Commons by <ExternalLink href="https://videoplasty.com">Videoplasty.com</ExternalLink>, CC-BY-SA 4.0</List.Item>
        <List.Item>Coach Yelling Cartoon.svg from Wikimedia Commons by <ExternalLink href="https://videoplasty.com">Videoplasty.com</ExternalLink>, CC-BY-SA 4.0</List.Item>
        <List.Item>Corporate Woman Jumping for Joy.svg from Wikimedia Commons by <ExternalLink href="https://videoplasty.com">Videoplasty.com</ExternalLink>, CC-BY-SA 4.0</List.Item>
        <List.Item>Corporate Woman Being Surprised.svg from Wikimedia Commons by <ExternalLink href="https://videoplasty.com">Videoplasty.com</ExternalLink>, CC-BY-SA 4.0</List.Item>
        <List.Item>Professor Holding a Book Cartoon.svg from Wikimedia Commons by <ExternalLink href="https://videoplasty.com">Videoplasty.com</ExternalLink>, CC-BY-SA 4.0</List.Item>
        <List.Item>Doctor Pointing Cartoon.svg from Wikimedia Commons by <ExternalLink href="https://videoplasty.com">Videoplasty.com</ExternalLink>, CC-BY-SA 4.0</List.Item>
        <List.Item>Nurse with Clipboard Cartoon.svg from Wikimedia Commons by <ExternalLink href="https://videoplasty.com">Videoplasty.com</ExternalLink>, CC-BY-SA 4.0</List.Item>
        <List.Item>Coach Lifting Dumbbells Cartoon.svg from Wikimedia Commons by <ExternalLink href="https://videoplasty.com">Videoplasty.com</ExternalLink>, CC-BY-SA 4.0</List.Item>
        <List.Item>Coach Using a Whistle Cartoon.svg from Wikimedia Commons by <ExternalLink href="https://videoplasty.com">Videoplasty.com</ExternalLink>, CC-BY-SA 4.0</List.Item>
        
        <List.Item>
          Pixabay contributors: Clker-Free-Vector-Images, Montse González, Alexander Lesnitsky, Jennifer R., OpenClipart-Vectors, Marta Simon, Annalize Batista, 
          Firas Samir, Paul Tate, Gordon Johnson, Christian Plaza, 200 Degrees, Niran Kasri, Vasil Todorov, Parker_West, Devi J, Mostafa Elturkey, InspiredImages
        </List.Item>
      </List>
    </>
  )
}

export default Thanks