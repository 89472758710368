import { lazyObservable } from 'mobx-utils';

import { Attribute, AttributeLesson, AttributeValue, AttributeValueLesson } from '../../../api/src/models/attribute.entity';
import axios from '../config/axios';

export const allAttributes = lazyObservable<Attribute[]>(sink => getAttributes(["attribute_lessons", "values", "values.value_lessons"]).then(u => sink(u.data)));
export const allAttributeValues = lazyObservable<AttributeValue[]>(sink => getAllAttributeValues(["attribute", "value_lessons"]).then(u => sink(u.data)));

function getAttributes(join?: string[]) {
  return axios.get<Attribute[]>(`/attribute?sort=name,ASC&${join ? `join=${join.join("&join=")}`: ''}`);
}

export function getAttribute(attribute_id: number, join?: string[]) {
  return axios.get<Attribute>(`/attribute/${attribute_id}?${join ? `join=${join.join("&join=")}` : ''}`);
}

/** This gets all values for all attributes in the system */
function getAllAttributeValues(join?: string[]) {
  return axios.get<AttributeValue[]>(`/attribute-value?sort=attribute.name,ASC&sort=value,ASC&${join ? `join=${join.join("&join=")}` : ''}`);
}

/** This gets all values of one specific attribute */
export function getAttributeValues(attribute_id: number) {
  return axios.get<AttributeValue[]>(`/attribute-value?filter=attribute_id||$eq||${attribute_id}&sort=value,ASC`);
}

export function getAttributeValue(value_id: number, join?: string[]) {
  return axios.get<AttributeValue>(`/attribute-value/${value_id}?${join ? `join=${join.join("&join=")}` : ''}`);
}

export function deleteAttributeValue(value_id: number) {
  return axios.delete<AttributeValue[]>(`/attribute-value/${value_id}`);
}

export function findAttributeValuesByValues(value: string) {
  return axios.get<AttributeValue[]>(`/attribute-value?filter=value||$cont||${value}&join=attribute&sort=value,ASC`);
}

export function findAttributesByName(name: string) {
  return axios.get<Attribute[]>(`/attribute?filter=name||$cont||${name}&sort=name,ASC`);
}

export function getAttributeLessons(attribute_id: number) {
  return axios.get<AttributeLesson[]>(`/attribute-lesson?filter=attribute_id||$eq||${attribute_id}&join=lesson&sort=lesson.name,ASC`);
}

export function getAttributeValueLessons(value_id: number) {
  return axios.get<AttributeValueLesson[]>(`/attribute-value-lesson?filter=value_id||$eq||${value_id}&join=lesson&sort=lesson.name,ASC`);
}

export function addAttribute(attribute:Partial<Attribute>) {
  return axios.post<Attribute>(`/attribute`, attribute);
}

export function updateAttribute(attribute_id: number, attribute: Partial<Attribute>) {
  return axios.patch<Attribute>(`/attribute/${attribute_id}`, attribute);
}

export function addAttributeValue(attribute_id: number, value: string) {
  return axios.post<AttributeValue>(`/attribute-value`, {
    attribute_id: attribute_id,
    value: value
  });
}

export function updateAttributeValue(value_id: number, value: Partial<AttributeValue>) {
  return axios.patch<AttributeValue>(`/attribute-value/${value_id}`, value);
}

export async function resetAttributeLessons(attribute_id: number, lesson_ids: number[]) {
  await axios.patch(`/attribute/${attribute_id}/lesson`, lesson_ids || []);
}

export async function resetAttributeValueLessons(value_id: number, lesson_ids: number[]) {
  await axios.patch(`/attribute-value/${value_id}/lesson`, lesson_ids || []);
}