import React from 'react';
import { Icon, Label } from 'semantic-ui-react';

import { SearchResult } from '../../../../api/src/types/search';
import { TextWithEmoji } from '../../services/html';

type ValueQuickListProps = {
  items: SearchResult[];
  onRemove?: (item: SearchResult) => void;
}

function ValueQuickList(props: ValueQuickListProps) { 
  return props.items.length > 0 ? (
    <Label.Group style={{ marginTop: ".5em" }}>
      {props.items.map(f => (
        <Label basic image key={f.id} size="big" style={{ marginTop: ".3em", display: "inline-flex", alignItems: "center" }}>
          <TextWithEmoji emoji={f.image_url} text={f.name} />
          {props.onRemove && <Icon name='delete' onClick={() => props.onRemove(f)} />}
        </Label>
      ))}
    </Label.Group>
  ) : null
}

export default ValueQuickList;