import React, { useContext, useEffect, useState } from 'react';
import Fullscreen from 'react-full-screen';
import { Dropdown, Image, Menu, Message, Reveal } from 'semantic-ui-react';

import { ItemDisplayLocation } from '../../../../api/src/models/gametheme.entity';
import { PlayContext } from '../../contexts/play';
import {
  getItemDisplayLocation,
  ItemDisplayLocationsContainerAdjustment,
  ItemDisplayLocationsItemAdjustment,
} from '../../services/gamethemes';
import { TextWithEmoji } from '../../services/html';
import { getImageThumbUrl } from '../../services/images';
import { GameProps } from '../shared/GameBase';
import GameTools from './GameTools';
import ItemEmphasizer from './ItemEmphasizer';

const revealStyles = ['fade', 'small fade', 'move', 'move right', 'move up', 'move down', 'rotate', 'rotate left'];

function RevealBehind(props: GameProps) {
  const { playItems, recipeDefinition, theme, updateGameOptions: setGameOptions } = useContext(PlayContext);
  
  const [itemLocation, setItemLocation] = useState<ItemDisplayLocation>(recipeDefinition.game_options?.item_location || getItemDisplayLocation("Reveal", theme, "top"));
  const [itemScatterMap, setItemScatterMap] = useState<{ [id: number]: [number, number] }>({});
  const [revealStyle, setRevealStyle] = useState(recipeDefinition.game_options?.reveal_style || "move");
  const [isFullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    setGameOptions({
      game_name: "Reveal",
      game_options: {
        reveal_style: revealStyle
      }
    });
  }, 
    // eslint-disable-next-line
    [revealStyle]
  );

  useEffect(() => {
    if (Object.keys(itemScatterMap).length !== playItems.items.length) {
      const getR = () => {
        const r = (Math.random() * 100) - 100 / playItems.items.length;
        return r > 0 ? r : 0;
      }

      setItemScatterMap(playItems.items.reduce((acc, cur, i) => { acc[i] = [getR(), getR()]; return acc; }, {}));
    }
  },
    // eslint-disable-next-line
    [playItems]
  );

  const getScatterFor = (index: number) => {
    return itemLocation !== "random" ?
      {} as React.CSSProperties :
      {
        position: "absolute",
        left: `${itemScatterMap[index][0]}%`,
        top: `${itemScatterMap[index][1]}%`
      } as React.CSSProperties
  }

  const containerStyle: React.CSSProperties = {
    position: "relative", 
    display: "flex", 
    backgroundImage: `url(${theme.background_url})`, 
    backgroundSize: "cover",
    height: !props.headless ? (isFullScreen || itemLocation === "random" ? "100vh" : null) : "100%", 
    width: "100%", 
    alignItems: "flex-start",
    paddingTop: "5em",
    paddingBottom: "5em",

    ...theme.container_style,
  }

  const ulStyle: React.CSSProperties = {
    listStyle: "none", 
    padding: 0, 
    margin: 0, 
    position: "relative", 
    display: "flex", 
    flexWrap: "wrap", 
    justifyContent: "space-around", 
    width: "100%",
    height: "100%",

    ...ItemDisplayLocationsContainerAdjustment[itemLocation]
  }

  const liStyle: React.CSSProperties = {
    margin: "1em",
    padding: "1em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: 200,
    height: 200,
    zIndex: 1,

    ...theme.item_style,
    ...ItemDisplayLocationsItemAdjustment[itemLocation]
  }

  const textStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    height: "100%",
    width: "100%",

    ...theme.font_style
  }

  return Object.keys(itemScatterMap).length === playItems.items.length ? (
    <>
      {! props.headless && (
        <>
          <Message info className="tour-game-instructions"><TextWithEmoji emoji="point_right" text="You can choose how things are revealed (below) and choose WHAT is being revealed by emphasizing something different in the recipe above." /></Message>

          <Menu attached="top" className="tour-game-menu">
            <Dropdown item icon="align left" trigger={<React.Fragment />}
              options={Object.keys(ItemDisplayLocationsContainerAdjustment).map(s => ({ text: s, value: s }))}
              value={itemLocation} onChange={(_, d) => setItemLocation(d.value as ItemDisplayLocation)}
            />

            <Menu.Item>
              <Dropdown selection search placeholder="Reveal Style"
                options={revealStyles.map(s => ({ text: s, value: s }))}
                value={revealStyle} onChange={(_, d) => setRevealStyle(d.value as string)}
              />
            </Menu.Item>
          </Menu>
        </>
      )}
      
      <Fullscreen enabled={isFullScreen} onChange={f => setFullScreen(f)}>
        <div style={containerStyle} id="game-container">
          {!props.headless && <GameTools screenshot fullscreen dice confetti draw isFullScreen={isFullScreen} onFullScreenToggle={() => setFullScreen(!isFullScreen)} /> }

          <ul style={ulStyle}>
            {playItems.items.map((item, i) => (
              <li style={{ ...liStyle, ...(getScatterFor(i))}} key={item.item_id}>
                <Reveal animated={revealStyle as any} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}>
                  <Reveal.Content visible style={{display: "flex", height: "100%", width: "100%", alignItems: "center", justifyContent: "center"}}>
                    <Image src={getImageThumbUrl(item.image_url)} style={{ cursor: "pointer", display: "flex", maxHeight: "100%"  }} />
                  </Reveal.Content>
                  <Reveal.Content hidden>
                    <div style={textStyle}>
                      <ItemEmphasizer item={item} recipe={recipeDefinition} ignoreImage />
                    </div>
                  </Reveal.Content>
                </Reveal>
              </li>
            ))}
          </ul>
        </div>        
      </Fullscreen>
    </>
  ) : null
};

export default RevealBehind;