import _ from 'lodash';

import {
  FeaturedRecipe,
  GameRecipe,
  GameRecipeDefinition,
  RecipeSearchFilter,
} from '../../../api/src/models/gamerecipe.entity';
import { GameTheme } from '../../../api/src/models/gametheme.entity';
import axios from '../config/axios';
import { PagedSearchResult } from './search';

export function getUserGameRecipes(user_id: number) {
  return axios.get<GameRecipe[]>(`/game-recipe?filter=is_public||$eq||false&filter=created_by||$eq||${user_id || -1}&sort=name,ASC`);
}

export function getFeaturedGameRecipes() {
  return axios.get<FeaturedRecipe[]>(`/game-recipe/featured`);
}

export function searchRecipes(filter: RecipeSearchFilter) {
  return axios.post<PagedSearchResult<GameRecipe>>(`/game-recipe/search/fancy`, filter);
}

export function getFeaturedGameRecipe(feature_id: number) {
  return axios.get<FeaturedRecipe>(`/game-recipe/featured/${feature_id}`);
}

export function getGameRecipe(recipe_id: number) {
  return axios.get<GameRecipe>(`/game-recipe/${recipe_id}`);
}

export function addGameRecipe(recipe: Partial<GameRecipe>, theme: GameTheme) {
  return axios.post<GameRecipe>(`/game-recipe`, { recipe, theme });
}

export function deleteGameRecipe(recipe_id: number) {
  return axios.delete<GameRecipe>(`/game-recipe/${recipe_id}`);
}

export function compareRecipes(left: GameRecipeDefinition, right: GameRecipeDefinition, ignore_options?: boolean) {
  left = !ignore_options ? left : _.omit(left, ["game_options"]);
  right = !ignore_options ? right : _.omit(right, ["game_options"]);

  return _.isEqual(left, right);
}