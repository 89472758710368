import { Emoji } from 'emoji-mart';
import htmlToImage from 'html-to-image';
import React, { useContext } from 'react';
import { Button, Popup } from 'semantic-ui-react';

import { ToolsContext } from '../../contexts/tools';
import { CurrentStudent } from '../../services/students';
import ConfettiTool from '../tools/ConfettiTool';
import Dice from '../tools/Dice';
import MustBeSubscribed from '../shared/MustBeSubscribed';
import { SubscriptionLevels } from '../../services/payment';
import DrawOnGames from '../tools/DrawOnGames';

interface GameToolsProps {
  dice?: boolean,
  fullscreen?: boolean,
  screenshot?: boolean,
  confetti?: boolean,
  draw?: boolean,

  isFullScreen?: boolean
  onFullScreenToggle?: () => void
}

function GameTools(props:GameToolsProps) {
  const tools = useContext(ToolsContext);
  
  const takeScreenshot = () => {
    document.getElementById('game-watermark').style.display = "block";

    htmlToImage.toPng(document.getElementById('game-container'), { filter: n => n.id !== "game-tools" })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = `GuessMonster-${CurrentStudent.student ? `${CurrentStudent.student.name}-` : ""}${(new Date()).toLocaleString()}.png`;
        link.href = dataUrl;
        link.click();

        document.getElementById('game-watermark').style.display = "none";
      });
  }

  return (
    <>
      {props.confetti && <ConfettiTool inGame fullScreen={props.isFullScreen} /> }
      {props.draw && <DrawOnGames />}
            
      <div style={{ position: "absolute", right: "1em", top: "1em", zIndex: 3 }} id="game-tools">
        {props.dice && <Dice />}

        <Button.Group className="tour-game-tools">
          {props.draw && (
            <MustBeSubscribed minLevel={SubscriptionLevels.Medium} fallback={<Button title="Please upgrade to draw on games." icon disabled><Emoji emoji="writing_hand" size={16} /></Button>}>
              <Popup trigger={<Button active={tools.draw >= 0} title="Draw on the game" icon onClick={tools.toggleDraw}><Emoji emoji="writing_hand" size={16} /></Button>}>
                Click <strong>once</strong> to turn on and draw.<br />
                Click <strong>again</strong> to turn off drawing.<br />
                Click a <strong>third</strong> time to hide it.
              </Popup>
            </MustBeSubscribed>
          )}
          {props.confetti && (
            <MustBeSubscribed minLevel={SubscriptionLevels.Medium} fallback={<Button icon disabled title="Please upgrade for Instant Confetti."><Emoji emoji="tada" size={16} /></Button>}>
              <Button icon onClick={tools.toggleConfetti}><Emoji emoji="tada" size={16} /></Button>
            </MustBeSubscribed>
          )}
          {props.dice && <Button active={tools.dice} title="Open Dice" icon onClick={tools.toggleDice}><Emoji emoji="game_die" size={16} /></Button>}
          {props.screenshot && <Button icon='photo' title="Take Screenshot (great for homework!)" onClick={takeScreenshot} />}
          {props.fullscreen && <Button icon='expand' title="Expand to full screen" onClick={props.onFullScreenToggle} />}
        </Button.Group>
      </div>

      <img src="/monster-watermark.png" alt="guessmonster.com" id="game-watermark" style={{ position: "absolute", right: "1em", bottom: "1em", zIndex: 3, display: "none" }} />
    </>
  )
}

export default GameTools