import React, { useState } from 'react';

type ErrorTypes = "401" | "404";

export type ErrorContextOptions = {
  viewed: boolean;
  type: ErrorTypes;
  message: string;

  setError: (type: ErrorTypes, message?: string) => void;
  clearError: () => void;
  ackError: () => void;
  processApiError: (err: any) => void;
}

export const ErrorContext = React.createContext<ErrorContextOptions>(undefined);

export const ErrorContextProvider: React.FC = (props) => {
  const [viewed, setViewed] = useState(false);
  const [type, setType] = useState<ErrorTypes>();
  const [message, setMessage] = useState("");

  const setError = (type: ErrorTypes, message?: string) => {
    setType(type);
    setMessage(message);
    setViewed(false);
  }

  const clearError = () => {
    setType(null);
    setMessage("");
    setViewed(false);
  }

  const ackError = () => {
    setViewed(true);
  }

  const processApiError = (err:any) => {
    console.log(err);

    if (err?.response?.status === 404) {
      setError("404")
    }
    else if (err?.response?.status === 401) {
      setError("401", err.response.data.message);
    }
  }

  return <ErrorContext.Provider value={{
    viewed, type, message,
    setError, clearError, ackError, processApiError
  }}>{props.children}</ErrorContext.Provider>
}