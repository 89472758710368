import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import Fullscreen from 'react-full-screen';
import { Checkbox, Dropdown, Image, Menu, Message } from 'semantic-ui-react';

import { ItemDisplayLocation } from '../../../../api/src/models/gametheme.entity';
import { PlayContext } from '../../contexts/play';
import {
  getItemDisplayLocation,
  ItemDisplayLocationsContainerAdjustment,
  ItemDisplayLocationsItemAdjustment,
} from '../../services/gamethemes';
import { TextWithEmoji } from '../../services/html';
import { getImageThumbUrl } from '../../services/images';
import { GameProps } from '../shared/GameBase';
import GameTools from './GameTools';
import ItemEmphasizer from './ItemEmphasizer';

function DragAndDrop(props: GameProps) {
  const { playItems, recipeDefinition, theme, updateGameOptions: setGameOptions } = useContext(PlayContext);
  
  const [itemLocation, setItemLocation] = useState<ItemDisplayLocation>(recipeDefinition.game_options?.item_location || getItemDisplayLocation("Drag n Drop", theme));
  const [itemScatterMap, setItemScatterMap] = useState<{ [id: number]: [number, number] }>({});
  const [makeBigger, setMakeBigger] = useState(recipeDefinition.game_options?.make_bigger || false);
  const [isFullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    setGameOptions({
      game_name: "Drag n Drop",
      game_options: {
        make_bigger: makeBigger,
        item_location: itemLocation
      }
    });

    if (itemLocation === "random" && !makeBigger)
      setMakeBigger(true);
  }, 
    // eslint-disable-next-line
    [
      makeBigger,
      itemLocation
    ]
  );

  useEffect(() => {
    if (Object.keys(itemScatterMap).length !== playItems.items.length) {
      const getR = () => {
        const r = (Math.random() * 100) - 100 / playItems.items.length;
        return r > 0 ? r : 0;
      }

      setItemScatterMap(playItems.items.reduce((acc, cur, i) => { acc[i] = [getR(), getR()]; return acc; }, {}));
    }
  },
    // eslint-disable-next-line
    [playItems]
  );

  const getScatterFor = (index: number) => {
    return itemLocation !== "random" ? 
      {} as React.CSSProperties :
      {
        position: "absolute", 
        left: `${itemScatterMap[index][0]}%`, 
        top: `${itemScatterMap[index][1]}%`
      } as React.CSSProperties
  }

  const containerStyle: React.CSSProperties = {
    position: "relative", 
    display: "flex", 
    backgroundImage: `url(${theme.background_url})`, 
    backgroundSize: "cover",
    minHeight: "100vh", 
    width: "100%", 
    alignItems: "flex-start",
    paddingTop: "5em",
    paddingBottom: "5em",

    ...theme.container_style,
  }

  const ulStyle: React.CSSProperties = {
    listStyle: "none",
    padding: 0,
    margin: 0,
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",

    flexWrap: makeBigger ? "wrap" : null,

    ...ItemDisplayLocationsContainerAdjustment[itemLocation]
  }

  const liStyle: React.CSSProperties = {
    margin: "1em",
    padding: "1em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: 200,
    height: 200,
    zIndex: 1,
    
    maxHeight: makeBigger ? null : `${100/playItems.items.length}%`,
    maxWidth: makeBigger ? null : `${100/playItems.items.length}%`,

    ...theme.item_style,
    ...ItemDisplayLocationsItemAdjustment[itemLocation]
  }

  const textStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",

    ...theme.font_style
  }

  return Object.keys(itemScatterMap).length === playItems.items.length ? (
    <>
      <Message info className="tour-game-instructions"><TextWithEmoji emoji="point_right" text="Drag items anywhere you want, emphasize 'nothing' in the recipe to get rid of text. Don't forget to take a picture (top right in the game)!" /></Message>
      
      <Menu attached="top" className="tour-game-menu">
        <Dropdown item icon="align left" trigger={<React.Fragment />}
          options={Object.keys(ItemDisplayLocationsContainerAdjustment).map(s => ({ text: s, value: s }))}
          value={itemLocation} onChange={(_, d) => setItemLocation(d.value as ItemDisplayLocation)}
        />
        <Menu.Item>
          <Checkbox toggle checked={makeBigger} disabled={itemLocation === "random"} onChange={() => setMakeBigger(!makeBigger)} label="Make bigger" />
        </Menu.Item>
      </Menu>
      
      <Fullscreen enabled={isFullScreen} onChange={f => setFullScreen(f)}>
        <div style={containerStyle} id="game-container">
          <GameTools screenshot fullscreen dice confetti draw isFullScreen={isFullScreen} onFullScreenToggle={() => setFullScreen(!isFullScreen)} />

          <ul style={ulStyle}>
            {playItems.items.map((item, i) => (
              <motion.li drag dragMomentum={false} whileHover={{ scale: 1.1 }}  style={{...liStyle, ...(getScatterFor(i))}} key={item.item_id}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%", width: "100%"}}>
                  <Image src={getImageThumbUrl(item.image_url)} draggable={false} style={{ cursor: "pointer", display: "flex", maxHeight: "100%" }} />
                  <div style={textStyle}>
                    <ItemEmphasizer item={item} recipe={recipeDefinition} ignoreImage />
                  </div>
                </div>
              </motion.li>              
            ))}
          </ul>
        </div>
      </Fullscreen>
    </>
  ) : null
};

export default DragAndDrop;