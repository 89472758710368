import { lazyObservable } from 'mobx-utils';

import { GameRecipeDefinition } from '../../../api/src/models/gamerecipe.entity';
import { Item } from '../../../api/src/models/item.entity';
import { ItemCollection } from '../../../api/src/models/itemcollection.entity';
import { GamePlaySearchResult } from '../../../api/src/types/game';
import axios from '../config/axios';

export const allMyCollections = lazyObservable<ItemCollection[]>(sink => getCollections().then(u => sink(u.data)));
export const allMyItems = lazyObservable<Item[]>(sink => getMyItems(["type", "attribute_values", "attribute_values.attribute_value", "item_lessons"]).then(u => sink(u.data)));

function getCollections() {
  return axios.get<ItemCollection[]>(`/item-collection`);
}

export function getCollection(collection_id: number, join?: string[]) {
  return axios.get<ItemCollection>(`/item-collection/${collection_id}?${join ? `join=${join.join("&join=")}` : ''}`);
}

export function getMyItems(join?: string[]) {
  return axios.get<Item[]>(`/item?filter=is_public||$eq||false&sort=name,ASC&${join ? `join=${join.join("&join=")}` : ''}`);
}

export function getItem(item_id: number, join?: string[]) {
  return axios.get<Item>(`/item/${item_id}?${join ? `join=${join.join("&join=")}` : ''}`);
}

export function findItemsByRecipe(recipe: GameRecipeDefinition) {
  return axios.post<GamePlaySearchResult>(`/item/by_recipe`, recipe);
}

export function addItem(item: Partial<Item>) {
  return axios.post<Item>(`/item`, item);
}

export function addItemCollection(name: string, item_ids: number[]) {
  return axios.post<ItemCollection>(`/item-collection`, {
    name,
    collection_items: item_ids.map(i => ({ item_id: i }))
  } as ItemCollection);
}

export function updateItemCollection(collection_id: number, name: string, item_ids: number[]) {
  return axios.patch<ItemCollection>(`/item-collection/${collection_id}`, {
    name,
    collection_items: item_ids.map(i => ({ item_id: i }))
  } as ItemCollection);
}

export async function resetItemLessons(item_id: number, lesson_ids: number[]) {
  await axios.patch(`/item/${item_id}/lesson`, lesson_ids);
}

export async function resetItemValues(item_id: number, value_ids: number[]) {
  await axios.patch(`/item/${item_id}/value`, value_ids);
}

export function updateItem(item_id: number, item: Partial<Item>) {
  return axios.patch<Item>(`/item/${item_id}`, item);
}

export function deleteItem(item_id: number) {
  return axios.delete<Item>(`/item/${item_id}`);
}

export function updateItemImageByUrl(item_id: number, image_url: string) {
  return axios.post<string>(`/item/${item_id}/image/by_url?url=${image_url}`);
}

export function updateItemImageByFile(item_id: number, file: File) {
  const data = new FormData();
  data.append("file", file);

  return axios.post<string>(`/item/${item_id}/image/by_file`, data);
}

export function bulkCreateItemsWithImages(type_id: number, files: File[]) {
  const data = new FormData();
  
  data.append("type_id", type_id.toString());
  files.forEach(f => data.append("file", f));

  return axios.post<{items: Item[], errors: any[]}>(`/item/by_files`, data);
}