import { wrap } from '@popmotion/popcorn';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import Fullscreen from 'react-full-screen';

import { PlayContext } from '../../contexts/play';
import { GameProps } from '../shared/GameBase';
import GameTools from './GameTools';
import ItemEmphasizer from './ItemEmphasizer';

const variants = {
  enter: (direction: number) => {
    return {
      opacity: 0
    };
  },
  center: {
    opacity: 1
  },
  exit: (direction: number) => {
    return {
      opacity: 0
    };
  }
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

// adopted from https://codesandbox.io/s/framer-motion-image-gallery-pqvx3
function OneByOne(props: GameProps) {
  const { playItems, recipeDefinition, theme, updateGameOptions: setGameOptions } = useContext(PlayContext);
  
  const [[page, direction], setPage] = useState([0, 0]);
  const [isFullScreen, setFullScreen] = useState(false);
  
  const imageIndex = wrap(0, playItems.items.length, page);

  useEffect(() => {
    setGameOptions({ game_name: "One by One" });
  }, 
    // eslint-disable-next-line
    []
  );

  const containerStyle: React.CSSProperties = {
    position: "relative", 
    display: "flex", 
    backgroundImage: `url(${theme.background_url})`, 
    backgroundSize: "cover", 
    height: "100vh", 
    alignItems: "flex-start",
    
    ...theme.container_style as React.CSSProperties,
  }

  const textStyle: React.CSSProperties = {
    ...theme.font_style as React.CSSProperties,
    fontSize: "4.5em",
    lineHeight: 1
  }

  const arrowStyle: React.CSSProperties = {
    top: "calc(50% - 20px)",
    position: "absolute",
    background: "white",
    borderRadius: 30,
    width: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    userSelect: "none",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "3em",
    zIndex: 2
  }

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <>
      <Fullscreen enabled={isFullScreen} onChange={f => setFullScreen(f)}>
        <div style={containerStyle} id="game-container">
          <GameTools screenshot fullscreen confetti draw isFullScreen={isFullScreen} onFullScreenToggle={() => setFullScreen(!isFullScreen)} />
          
          <AnimatePresence initial={false} custom={direction}>
            <motion.div key={page} 
              style={{display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center", flexDirection: "column", zIndex: 1}}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                opacity: { duration: 0.2 }
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}
            >
              <div style={{ background: `url(${playItems.items[imageIndex].image_url}) center center / contain no-repeat`, display: "flex", width: "70%", height: "70%" }} />

              <div style={textStyle}>
                <ItemEmphasizer item={playItems.items[imageIndex]} recipe={recipeDefinition} invalidAsBlank ignoreImage />
              </div>
            </motion.div>
          </AnimatePresence>
          <div style={{...arrowStyle, right: 10}} onClick={() => paginate(1)}>
            {"‣"}
          </div>
          <div style={{...arrowStyle, left: 10, transform: "scale(-1)"}} onClick={() => paginate(-1)}>
            {"‣"}
          </div>
        </div>
      </Fullscreen>
    </>
  )
};

export default OneByOne;