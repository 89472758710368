import '../../styles/monster.css';

import { Emoji } from 'emoji-mart';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import Fullscreen from 'react-full-screen';
import { Image, Input, Menu, Message } from 'semantic-ui-react';

import { GuessableResult } from '../../../../api/src/types/game';
import { AuthContext } from '../../contexts/auth';
import { PlayContext } from '../../contexts/play';
import { ToolsContext } from '../../contexts/tools';
import { getGuessableQuestion } from '../../services/games';
import { TextWithEmoji } from '../../services/html';
import { getImageThumbUrl } from '../../services/images';
import { CurrentStudent, getCurrentStudentNegativeEmoji, getCurrentStudentPositiveEmoji } from '../../services/students';
import { GameProps } from '../shared/GameBase';
import GameTools from './GameTools';
import ItemEmphasizer from './ItemEmphasizer';

type StateOptions = "active" | "correct" | "incorrect";

function GuessMonster(props: GameProps) {
  const auth = useContext(AuthContext);
  const tools = useContext(ToolsContext);
  const { playItems, recipeDefinition, theme, updateGameOptions: setGameOptions, requestNewItems, onFinish } = useContext(PlayContext);
  
  const [itemStateMap, setItemStateMap] = useState<{ [id: number]: StateOptions }>({});
  const [justFinished, setJustFinished] = useState(false);
  const [targetCorrect, setTargetCorrect] = useState(recipeDefinition.game_options?.target_correct || 10);
  const [answers, setAnswers] = useState<[number, number]>([0, 0]); // [correct, incorrect]
  const [isFullScreen, setFullScreen] = useState(false);
  const [guessable, setGuessable] = useState<GuessableResult>(null);
  const [questionText, setQuestionText] = useState<JSX.Element>(null);

  const positive_icon = getCurrentStudentPositiveEmoji(auth.user);
  const negative_icon = getCurrentStudentNegativeEmoji(auth.user);

  useEffect(() => {
    setGameOptions({
      game_name: "Guess Monster",
      game_options: {
        target_correct: targetCorrect
      }
    });
  }, 
    // eslint-disable-next-line
    [targetCorrect]
  );

  const containerStyle: React.CSSProperties = {
    position: "relative", 
    display: "flex", 
    backgroundImage: `url(${theme.background_url})`, 
    backgroundSize: "cover", 
    height: !props.headless ? (isFullScreen ? "100vh" : null) : "100%", 
    width: "100%",
    alignItems: "flex-start",
    overflow: "hidden",
    paddingTop: "5em",
    paddingBottom: "5em",

    ...theme.container_style,
  }

  const ulStyle: React.CSSProperties = {
    listStyle: "none", 
    padding: 0, 
    margin: 0, 
    position: "relative", 
    display: "flex", 
    flexWrap: "wrap", 
    justifyContent: "space-around", 
    width: "100%" 
  }

  const liStyle: React.CSSProperties = {
    margin: "1em",
    padding: "1em",
    width: 200,
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",

    ...theme.item_style
  }

  const textStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

    ...theme.font_style
  }

  useEffect(() => {
    const allDone = answers[0] === targetCorrect;
    
    if (allDone && onFinish && !justFinished) {
      setJustFinished(true);
      onFinish();
    }
  },
    // eslint-disable-next-line
    [targetCorrect, justFinished, answers]
  );

  useEffect(() => {
    if (playItems.guessables?.length > 0) {
      setItemStateMap({});
      const question = playItems.guessables.sort(() => Math.random() - .5)[0];
      setGuessable(question);
      setQuestionText(getGuessableQuestion(question));
    }
    else {
      requestNewItems();
    }
  },
    // eslint-disable-next-line
    [playItems]
  );

  const getStateFor = (id: number) => {
    return itemStateMap[id] || "active";
  }

  const clickItem = (id: number) => {
    if (playItems.items.find(i => i.item_id === id).attribute_values.some(v => v.value_id === guessable.value_id)) {
      setAnswers([answers[0] + 1, answers[1]]);
      setItemStateMap({ ...itemStateMap, [id]: "correct" });
      tools.playSound("positive");
      requestNewItems();
      setTimeout(() => setItemStateMap({}), 1500);
    }
    else {
      setAnswers([answers[0], answers[1] + 1]);
      setItemStateMap({ ...itemStateMap, [id]: "incorrect" });
      tools.playSound("negative");
    }
  }

  const doubleClickItem = (id: number) => {
    if (auth.user?.is_admin && !CurrentStudent.student) {
      window.open(`${process.env.REACT_APP_ROOT}/admin/item/item/${id}`);
    }
  }

  return (
    <>
      {! props.headless && (
        <>
          <Message info className="tour-game-instructions"><TextWithEmoji emoji="point_right" text="The monster is hungry! Help him out by clicking on the items he's asking for!" /></Message>

          <Menu attached="top" className="tour-game-menu">
            <Menu.Item>
              <Input icon='target' title="Number of correct guesses to get." type="number" min="1" iconPosition='left' placeholder='Target' style={{ width: 100 }} value={targetCorrect} onChange={(_, d) => setTargetCorrect(parseInt(d.value) || 1)} />
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item><Emoji emoji={positive_icon} size={20} />&nbsp;&nbsp;{answers[0]}/{targetCorrect}</Menu.Item>
              <Menu.Item><Emoji emoji={negative_icon} size={20} />&nbsp;&nbsp;{answers[1]}</Menu.Item>
            </Menu.Menu>
          </Menu>
        </>
      )}

      <Fullscreen enabled={isFullScreen} onChange={f => setFullScreen(f)}>
        <div style={containerStyle} id="game-container">
          {guessable && <motion.img src="/monster.svg" style={{position: "absolute", width: 300, top: "-2em"}} animate={{left: -130, rotate: 30}} transition={{duration: .5}} /> }
          
          {!props.headless && <GameTools screenshot fullscreen confetti draw isFullScreen={isFullScreen} onFullScreenToggle={() => setFullScreen(!isFullScreen)} />}

          <ul style={ulStyle}>
            {guessable && (
              <motion.li style={{ ...liStyle, fontWeight: 700, WebkitTextStroke: "1px darkgrey", WebkitTextFillColor: "darkgrey", justifyContent: "flex-end", alignItems: "flex-end"}}
                whileHover={{scale: 1.3}}
              >
                <div style={{...textStyle, fontSize: "1.8em"}}>{questionText}</div>
              </motion.li>
            )}

            {playItems.items.map(item => (
              <li style={liStyle} key={item.item_id} onClick={(e) => clickItem(item.item_id)} onDoubleClick={() => doubleClickItem(item.item_id)}>
                <motion.div key={`${item.item_id}-${getStateFor(item.item_id)}`} style={{display: "flex", flexDirection: "column", alignItems: "center", height: "100%", width: "100%", justifyContent: "center"}}
                  initial={{opacity: 0}}
                  animate={{opacity: 1}} 
                  exit={{opacity: 0}} 
                  transition={{ duration: 1 }}
                  whileHover={{scale: 1.2}}
                >
                  {getStateFor(item.item_id) === "active" && <Image draggable="false" src={getImageThumbUrl(item.image_url)} style={{display: "flex", maxHeight: "100%", maxWidth: "100%"}} /> }
                  {getStateFor(item.item_id) === "correct" && <Emoji emoji={positive_icon} size={64} />}
                  {getStateFor(item.item_id) === "incorrect" && <Emoji emoji={negative_icon} size={64} />}

                  <div style={textStyle}>
                    <ItemEmphasizer item={item} recipe={recipeDefinition} ignoreImage />
                  </div>
                </motion.div>

              </li>
            ))}
          </ul>
        </div>
      </Fullscreen>
    </>
  )
};

export default GuessMonster;