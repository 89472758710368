import '../../styles/stripe.css';

import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe, StripeCardElementOptions } from '@stripe/stripe-js';
import { Emoji } from 'emoji-mart';
import { useObserver } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Divider, Header, Label, Message, Table, Form } from 'semantic-ui-react';

import { GroupedPlanInfo } from '../../../../api/src/types/payment';
import { AuthContext } from '../../contexts/auth';
import { ErrorContext } from '../../contexts/error';
import { ToolsContext } from '../../contexts/tools';
import { TextWithEmoji, FakeLink } from '../../services/html';
import { formatCurrency, formatNumber } from '../../services/intl';
import { getSubscriptionPlans, PlanDetail, PlanDetails, subscribeUser, upgradeUser, validatePromoCode } from '../../services/payment';
import Login from '../auth/Login';
import PaymentInfo from './PaymentInfo';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

interface FeatureGroup {
  name: string,
  features: {
    feature: string | React.ReactNode,
    levels: [boolean | string, boolean | string, boolean | string]
  }[]
}

const buyDiv: React.CSSProperties = {
  padding: "2em",
  textAlign: "center"
}

const cardStyle = {
  iconStyle: 'solid',
  style: {
    base: {
      fontFamily: "'Quicksand', sans-serif",
      fontSmoothing: 'antialiased',
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
  },
} as StripeCardElementOptions

function PriceSheet() {
  const [plans, setPlans] = useState<GroupedPlanInfo>(null);
  const [chosenPlan, setChosenPlan] = useState<PlanDetail>(null);
  const [promo, setPromo] = useState("");
  const [promoInvalid, setPromoInvalid] = useState(false);
  const [chosenPromo, setChosenPromo] = useState<any>(null)
  const [saving, setSaving] = useState(false);
  const [payError, setPayError] = useState("");
  const [planFrequency, setPlanFrequency] = useState<"year" | "month">("year");

  const auth = useContext(AuthContext);
  const tools = useContext(ToolsContext);
  const { processApiError } = useContext(ErrorContext);

  const { push } = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const features: FeatureGroup[] = [
    {
      name: "Items",
      features: [
        { feature: "Access to our item library (growing every day!)", levels: [true, true, true] },
        { feature: "Add custom and private items", levels: [false, true, true] },
        { feature: "Create your own item collections", levels: [false, true, true] },
        { feature: "Premium, licensed items (coming soon!)", levels: [false, false, true] }
      ],
    }, {
      name: "Games (more coming soon!)",
      features: [
        { feature: "Guess Monster, Drag n Drop, One by One, Reveal, Check Off", levels: [true, true, true] },
        { feature: "Catch, Sort", levels: [false, true, true] },
      ]
    }, {
      name: "Game Tools",
      features: [
        { feature: "Full Screen", levels: [true, true, true] },
        { feature: "Screenshot", levels: [true, true, true] },
        { feature: "Dice", levels: [true, true, true] },
        { feature: "Draw On Games (literally draw on them)", levels: [false, true, true] },
        { feature: <FakeLink onClick={tools.toggleConfetti}>Instant Confetti!</FakeLink>, levels: [false, true, true] },
      ]
    }, {
      name: "Game Themes",
      features: [
        { feature: "Access to our theme library", levels: [true, true, true] },
        { feature: "Create your own themes", levels: [false, true, true] },
        { feature: "Premium, licensed themes (coming soon!)", levels: [false, false, true] }
      ]
    }, {
      name: "Attributes",
      features: [
        { feature: "Access to our entire attribute library (growing every day!)", levels: [true, true, true] },
      ]
    }, {
      name: "Lessons",
      features: [
        { feature: "Access to our entire lesson library (growing every day!)", levels: [true, true, true] },
        { feature: "Create custom lessons (coming soon!)", levels: [false, false, true] },
      ]
    }, {
      name: "Recipes",
      features: [
        { feature: "Access to public recipes", levels: [true, true, true] },
        { feature: "Custom recipe limit", levels: ["1", "5", "no limit"] },
      ]
    }, {
      name: "Profile",
      features: [
        { feature: "Pick your own emojis", levels: [true, true, true] },
        { feature: "Student limit (with their own backgrounds and emojis!)", levels: ["1", "5", "30 (30+? contact us!)"] },
      ]
    },
  ];

  useEffect(() => {
    getSubscriptionPlans()
      .then(p => setPlans(p.data))
      .catch(processApiError);
  }, 
    // eslint-disable-next-line
    []
  );

  const getPrices = (name: string) => {
    const p = plans[name];

    return (
      <div>
        <div>{formatCurrency(p[0].price)}/{p[0].interval}</div>
        <div>{formatCurrency(p[1].price)}/{p[1].interval} - {formatNumber(((p[0].price * 12) - p[1].price) / p[0].price)} months free!</div>
        <div style={{ marginTop: "1em" }}>14 day FREE trial!</div>
      </div>
    )
  }

  const validatePromo = () => {
    validatePromoCode(promo)
      .then(c => {
        if (c.data) {
          setChosenPromo(c.data);
          setPromoInvalid(false);
        }
        else {
          setPromoInvalid(true);
        }
      })
      .catch(processApiError);
  }

  const clearPromo = () => {
    setChosenPromo(null);
    setPromoInvalid(false);
    setPromo("");
  }

  const pay = async (e: React.MouseEvent) => {
    // Block native form submission.
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setPayError("");
    setSaving(true);
    const cardElement = elements.getElement(CardElement);

    try {
      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setPayError(error.message);
      } else {
        try {
          const user = await subscribeUser({
            payment_method_id: paymentMethod.id,
            plan_id: plans[chosenPlan.name].find(p => p.interval === planFrequency).stripe_plan_id,
            promo_code: chosenPromo
          });

          tools.playSound("positive");
          auth.updateUser(user.data);
          tools.toggleConfetti();
          push("/profile/billing");
        }
        catch (err) {
          setPayError(err?.response?.data?.raw?.message || "There was a problem subscribing you. So sorry! Please try again or let us know via the chat on the bottom right!");
        }
      }
    }
    catch (err) {
      console.log(err);
      processApiError(err);
    }
    finally {
      setSaving(false);
    }
  };

  const upgrade = async () => {
    setSaving(true);

    try {
      const user = await upgradeUser({
        plan_id: plans[chosenPlan.name].find(p => p.interval === planFrequency).stripe_plan_id
      });

      tools.playSound("positive");
      auth.updateUser(user.data);
      tools.toggleConfetti();
      push("/profile/billing");
    }
    catch(err) {
      console.log(err);
      processApiError(err);
    }
    finally {
      setSaving(false);
    }
  }

  return useObserver(() => plans ? (
    <>
      <Table celled definition unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            {Object.values(PlanDetails).map(p => (
              <Table.HeaderCell key={p.name}>
                <Label ribbon color={p.color}>{p.label}</Label>
                {p.name}
                <Divider />
                {getPrices(p.name)}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {features.map((g, gi) => (
            <React.Fragment key={g.name}>
              {gi > 0 && (
                <Table.Row><Table.Cell style={{ backgroundColor: "white", height: "2em" }} colSpan="4"></Table.Cell></Table.Row>
              )}

              <Table.Row positive>
                <Table.Cell style={{ textTransform: "uppercase" }} colSpan="4">{g.name}</Table.Cell>
              </Table.Row>
              
              {g.features.map((f, fi) => (
                <Table.Row key={fi}>
                  <Table.Cell style={{ fontWeight: "normal" }}>{f.feature}</Table.Cell>
                  {f.levels.map((l, li) => (
                    <Table.Cell textAlign="center" key={`${fi}_${li}`} negative={!l}>{l && (typeof l == "boolean") ? <Emoji emoji="thumbsup" size={20} /> : l}</Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </React.Fragment>
          ))}
        </Table.Body>

        <Table.Footer>
          {chosenPlan == null ? (
            <Table.Row>
              <Table.HeaderCell />
              {Object.values(PlanDetails).map(p => (
                <Table.HeaderCell key={p.name} verticalAlign="top">
                  <Label ribbon color={p.color}>{p.label}</Label>
                  {p.name}
                  <Divider />
                  {auth.user?.subscription_level_id?.toString() === p.level.toString() ? (
                    <div>
                      <Header>Your current plan! <Header.Subheader>Thank you!</Header.Subheader></Header>
                      <Link to="/profile/billing"><TextWithEmoji text="Manage Billing" emoji="credit_card" /></Link>
                    </div>
                  ) : (
                    <>
                      {getPrices(p.name)}
                      <div style={buyDiv}>
                          {auth.user?.subscription_level_id == null ? (
                            <Button onClick={() => setChosenPlan(p)} positive>Subscribe!</Button>
                          ) : (
                            <>
                              <Button onClick={() => setChosenPlan(p)} positive={auth.user?.subscription_level_id < p.level}>
                                {auth.user?.subscription_level_id < p.level ? "Upgrade!" : "Downgrade" }
                              </Button>
                              <br />
                              (Prorated)
                            </>
                          ) }
                      </div>
                    </>
                  )}

                </Table.HeaderCell>
              ))}
            </Table.Row>
          ) : (
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell colSpan="3">
                  <Label ribbon color={chosenPlan.color}>{chosenPlan.label}</Label>
                  <Button size="small" basic style={{ float: "right" }} onClick={() => setChosenPlan(null)}>Change Plan</Button>
                  <strong>{chosenPlan.name}</strong> - Good choice!
                  <Divider />

                  {!auth.user?.subscription_level_id ? (
                    <>
                      { auth.user ? (
                        <>
                          <Button.Group fluid>
                            <Button primary={planFrequency === "year"} onClick={() => setPlanFrequency("year")}>Annual - {formatCurrency(plans[chosenPlan.name][1].price)}/{plans[chosenPlan.name][1].interval}</Button>
                            <Button.Or />
                            <Button primary={planFrequency === "month"} onClick={() => setPlanFrequency("month")}>Monthly - {formatCurrency(plans[chosenPlan.name][0].price)}/{plans[chosenPlan.name][0].interval}</Button>
                          </Button.Group>

                          <div style={{ marginBottom: "2em" }}>
                            <CardElement options={cardStyle} />
                            
                            <Header size="small">Have a promo code?</Header>
                            
                            <Form size="tiny">
                              <Form.Group inline>
                                <Form.Input value={promo} onChange={(_, d) => setPromo(d.value)} />
                                <Form.Button size="tiny" content="Apply Code" onClick={validatePromo} />
                                {chosenPromo && <Form.Button size="tiny" icon="trash" onClick={clearPromo} /> }
                              </Form.Group>
                              
                              {promoInvalid && <Message negative>Sorry, this code is not valid.</Message>}
                              {chosenPromo && <Message positive>{chosenPromo?.metadata?.description || "Applied!"}</Message>}
                            </Form>
                          </div>

                          {payError !== "" && <Message content={payError} error />}
                          <Button disabled={!stripe} loading={saving} onClick={pay} positive>Subscribe!</Button>
                        </>
                      ) : (
                        <>
                          <Message positive visible>Let's get you signed in or registered first!</Message>
                          <Login />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <PaymentInfo />

                      <div style={{ marginBottom: "1em" }}>
                        If you would like to use this card, please click {auth.user?.subscription_level_id < chosenPlan.level ? "Upgrade" : "Downgrade"} below. <br />
                        If you would like to change your payment method, please go to <Link to="/profile/billing">Manage Billing</Link>.
                      </div>

                      <Button.Group fluid>
                        <Button primary={planFrequency === "year"} onClick={() => setPlanFrequency("year")}>Annual - {formatCurrency(plans[chosenPlan.name][1].price)}/{plans[chosenPlan.name][1].interval}</Button>
                        <Button.Or />
                        <Button primary={planFrequency === "month"} onClick={() => setPlanFrequency("month")}>Monthly - {formatCurrency(plans[chosenPlan.name][0].price)}/{plans[chosenPlan.name][0].interval}</Button>
                      </Button.Group>

                      <Button style={{ marginTop: "1em" }} loading={saving} onClick={upgrade} positive>
                        {auth.user?.subscription_level_id < chosenPlan.level ? "Upgrade" : "Downgrade"} to {chosenPlan.name}!
                      </Button>
                    </>
                  )}
                </Table.HeaderCell>
              </Table.Row>
            )}
        </Table.Footer>
      </Table>
    </>
  ) : null)
}

const PriceSheetWrapper = () => <Elements stripe={stripePromise}><PriceSheet /></Elements>

export default PriceSheetWrapper;