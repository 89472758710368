import React from 'react';

import { SearchResult } from '../../../../api/src/types/search';
import ItemQuickList from './ItemQuickList';
import SearchDropdown from './SearchDropdown';

type MultiItemSelectorProps = {
  placeholder?: string;
  items: SearchResult[];
  onChange: (allSelected:SearchResult[], currentChange?: {item: SearchResult, action: "added" | "removed"}) => void;
}

function MultiItemSelector(props: MultiItemSelectorProps) { 
  return (
    <>
      <SearchDropdown placeholder={props.placeholder} which={["item"]} onSelect={v => {
        // ignore if already in list
        if (!(props.items.find(i => i.which === v.which && i.id === v.id))) {
          props.onChange([...props.items, v], { item: v, action: "added" });
        }
      }} />

      <ItemQuickList items={props.items} onRemove={(i) => props.onChange(props.items.filter(fl => fl.id !== i.id && fl.which === i.which), { item: i, action: "removed" })} />
    </>
  )
}

export default MultiItemSelector;