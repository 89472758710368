import React from 'react';
import { Header } from 'semantic-ui-react';

import Login from '../auth/Login';

function LoginPage() {
  return (
    <div style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: 500, background: "url(/godzilla.png)", backgroundSize: "cover", backgroundColor: "white" }}>
      <Header size="huge">Register or Log In</Header>
      <Login />
    </div>
  )
}

export default LoginPage;