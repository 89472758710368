import { lazyObservable } from 'mobx-utils';

import { ItemType } from '../../../api/src/models/item.entity';
import axios from '../config/axios';

export const allItemTypes = lazyObservable<ItemType[]>(sink => getItemTypes().then(u => sink(u.data)));

export function getItemTypes(join?: string[]) {
  return axios.get<ItemType[]>(`/item-type?sort=name,ASC&${join ? `join=${join.join("&join=")}` : ''}`);
}

export function getItemType(type_id: number, join?: string[]) {
  return axios.get<ItemType>(`/item-type/${type_id}?${join ? `join=${join.join("&join=")}` : ''}`);
}

export function findItemTypesByName(name: string) {
  return axios.get<ItemType[]>(`/item-type/search?filter=name||$cont||${name}&sort=name,ASC`);
}

export function addItemType(item_type: Partial<ItemType>) {
  return axios.post<ItemType>(`/item-type`, item_type);
}

export function updateItemType(type_id: number, item_type: Partial<ItemType>) {
  return axios.patch<ItemType>(`/item-type/${type_id}`, item_type);
}