import React, { useEffect, useState } from 'react';
import { Button, Message, Modal } from 'semantic-ui-react';

import { getImageThumbUrl } from '../../services/images';

interface ThemePreviewProps {
  background: File | string;
  containerOverride: string;
  itemOverride: string;
  textOverride: string;
  sampleItemUrl?: string;
  hideButtonText?: boolean;
}

function ThemePreview(props:ThemePreviewProps) {
  const [bgsrc, setBgsrc] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState<[string, string][]>([]);

  const {background} = props;

  useEffect(() => {
    if (background && !(typeof background === "string")) {
      const reader = new FileReader();

      reader.addEventListener("load", () => setBgsrc(reader.result as string));
      reader.readAsDataURL(background);
    }
    else if (background) {
      setBgsrc(background as string);
    }
  }, [background]);

  const toCSS = (str: string) => {
    if (errors.length === 0) {
      try {
        if (str === "") return {};
        return JSON.parse(str) as React.CSSProperties;
      }
      catch (err) {
        setErrors(errors.concat([str, `Bad CSS JSON: ${err.toString()}`]));
        return {};
      }
    }
    else
      return {};
  }

  const containerStyle = {
    display: "flex", 
    background: `url(${bgsrc})`, 
    backgroundSize: "cover",
    minHeight: 500, 
    minWidth: 300, 
    maxWidth: "100%", 
    alignItems: "center", 
    justifyContent: "center", 
    flexDirection: "column",

    ...toCSS(props.containerOverride)
  } as React.CSSProperties

  return (
    <Modal open={isOpen} onClose={() => { setIsOpen(false); setErrors([]) }} size="large" 
      trigger={<Button type="button" onClick={() => setIsOpen(true)} basic icon="eye" content={props.hideButtonText ? null : "Preview"} />}>
      
      <Modal.Content>
        { isOpen && (
          <div style={containerStyle}>
            <img alt="" style={{display: "flex", maxHeight: "50%", ...toCSS(props.itemOverride)}} src={props.sampleItemUrl ? getImageThumbUrl(props.sampleItemUrl) : "/monster.png"} />
            <div style={{ ...toCSS(props.textOverride) }}>Guess Monster</div>
          </div>
        )}
        
        {errors.length > 0 && (
          <Message as={Modal.Description} warning>
            {console.log(errors)}
            <div>{errors[1]}</div>
          </Message>
        )}
      </Modal.Content>
    </Modal>
  );
}

export default ThemePreview;