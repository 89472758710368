import React from 'react';
import { Popup } from 'semantic-ui-react';

import { SearchableTypes } from '../../../../api/src/types/search';
import { FakeLink } from '../../services/html';
import SearchDropdown from './SearchDropdown';

interface LessonCopierProps {
  text?: string;
  onSelect: (lesson_ids?: number[]) => void;
  which: SearchableTypes[]
}

function LessonCopier(props:LessonCopierProps) {
  return (
    <Popup on="click" wide="very" trigger={<FakeLink style={{cursor: "pointer"}}>{props.text || "Or pick from an existing record."}</FakeLink>}
      content={(
        <div style={{ minWidth: 400 }}>
          <label>
            Pick somewhere to copy lesson from. <br />
            <strong>Some lessons apply only to items or only to values (or both) - when you pick a record to copy from, only the lessons that can be applied in this situation will be copied.</strong>
          </label>
          <SearchDropdown placeholder="Search..." which={props.which} onSelect={i => {
            props.onSelect(i.lesson_ids.split(",").map(l => parseInt(l)));
          }} />
        </div>
      )}
    />
  )
}

export default LessonCopier;