import { motion } from 'framer-motion';
import htmlToImage from 'html-to-image';
import { useObserver } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button, Checkbox, Form, Grid, Input, Segment, Dropdown } from 'semantic-ui-react';

import { GameTheme } from '../../../../api/src/models/gametheme.entity';
import { SearchResult } from '../../../../api/src/types/search';
import { getThemeBackgroundStyle } from '../../services/gamethemes';
import SearchDropdown from '../shared/SearchDropdown';
import GameThemeSearchDropdown from '../shared/searchers/GameThemeSearchDropdown';

function AdminSocialMedia () {
  const [item, setItem] = useState<SearchResult>(null);
  const [theme, setTheme] = useState<GameTheme>(null);
  const [dimension, setDimension] = useState<[number, number]>([65, 65]);
  const [showName, setShowName] = useState(false);
  const [fontSize, setFontSize] = useState("4em");

  const square = 800;

  const saveScreenshot = () => {
    htmlToImage.toPng(document.getElementById('social-square'), { width: square, height: square })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = `GuessMonster-social-${theme?.name}-${item?.name}.png`;
        link.href = dataUrl;
        link.click();
      });
  }
  
  return useObserver(() => (
    <Form>
      <Grid stackable stretched columns="equal" verticalAlign="bottom">
        <Grid.Row as={Segment}>
          <Grid.Column>
            <GameThemeSearchDropdown mode="modal" onItemSelected={setTheme} trigger={
              <Dropdown fluid selection placeholder="Pick a theme..."
                options={theme ? [{ text: theme.name, value: theme.theme_id }] : []} value={theme ? theme.theme_id : null}
              />}
            />
          </Grid.Column>
          <Grid.Column>
            <SearchDropdown placeholder="Search..." which={["item"]} onSelect={setItem} />
          </Grid.Column>
          <Grid.Column>
            <Form.Group widths="equal" style={{margin: 0}}>
              <Form.Input label="% Width" type="number" fluid value={dimension[0]} onChange={(_, d) => setDimension([parseFloat(d.value), dimension[1]])} />
              <Form.Input label="% Height" type="number" fluid value={dimension[1]} onChange={(_, d) => setDimension([dimension[0], parseFloat(d.value)])} />
            </Form.Group>
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
              <Checkbox checked={showName} label="Show Name" onChange={() => setShowName(!showName)} />
              <Input fluid value={fontSize} onChange={(_, d) => setFontSize(d.value)} />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Button primary fluid onClick={saveScreenshot}>Save</Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <div id="social-square" style={{ ...getThemeBackgroundStyle(theme), display: "flex", height: square, width: square, overflow: "hidden", justifyContent: "center", alignItems: "center" }}>
              <motion.div drag style={{ background: `url(${item?.image_url}) center center / contain no-repeat`, display: "flex", alignItems: "flex-end", justifyContent: "center", width: `${dimension[0]}%`, height: `${dimension[1]}%` }}>
                {showName && (
                  <motion.div drag style={{ ...theme?.font_style as React.CSSProperties, fontSize }}>
                    {item?.name}
                  </motion.div>
                )}
              </motion.div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  ));
}

export default AdminSocialMedia;