import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import PageHeader from '../shared/PageHeader';
import { AddEditItem } from './AddEditItem';
import { BulkAddItems } from './BulkAddItems';
import ItemList from './ItemList';

function ItemsHome () {
  let { path } = useRouteMatch();
  
  return (
    <>
      <PageHeader title="Items" backText="Back to Item Library" />

      <Switch>
        <Route path={`${path}/bulk`} component={BulkAddItems} />
        <Route path={`${path}/item/:item_id?`} component={AddEditItem} />
        <Route path={path} render={() => <ItemList showList="all" showFilter />} />
      </Switch>
    </>
  );
}

export default ItemsHome;