import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';

import { SearchableTypes, SearchResult } from '../../../../api/src/types/search';
import { ErrorContext } from '../../contexts/error';
import { toDropDownSource } from '../../services/html';
import { searchAll } from '../../services/search';

type SearchDropdownProps = {
  placeholder?: string;
  which: SearchableTypes[];
  onSelect: (selected: SearchResult) => void
}

function SearchDropdown(props: SearchDropdownProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState<SearchResult[]>([]);
  
  const { processApiError } = useContext(ErrorContext);
  
  const { which } = props;

  useEffect(() => {
    if (searchQuery.trim() !== "") {
      searchAll(searchQuery, which)
        .then(r => setResults(r.data))
        .catch(processApiError)
    }
  }, 
    // eslint-disable-next-line
    [searchQuery, which]
  );

  return (
    <>
      <Dropdown fluid selection search 
        placeholder={props.placeholder} selectOnNavigation={false} selectOnBlur={false}
        searchQuery={searchQuery} onSearchChange={(_, d) => setSearchQuery(d.searchQuery)}
        options={ toDropDownSource(results, "name", (i) => `${i.which}_${i.id}`, "image_url", null, props.which.length > 0 ? i => i.which : null, "lesson_names") }
        value={null} onChange={(_, d) => { 
          const v = (d.value as string).split("_");
          const r = results.find(r => r.which === v[0] as SearchableTypes && r.id === parseInt(v[1]));
          props.onSelect(r);
        }}
      />
    </>
  )
}

export default SearchDropdown;