import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Button, Header } from 'semantic-ui-react';

import { allMyCollections, allMyItems } from '../../services/items';
import { SubscriptionLevels } from '../../services/payment';
import { AddEditItem } from '../items/AddEditItem';
import AddEditItemCollection from '../items/AddEditItemCollection';
import ItemCollectionList from '../items/ItemCollectionList';
import ItemList from '../items/ItemList';
import MustBeSubscribed from '../shared/MustBeSubscribed';
import PageHeader from '../shared/PageHeader';

function UserItems() {
  const { path } = useRouteMatch();
  
  return useObserver(() => allMyCollections.current() && allMyItems.current() ? (
    <>
      <PageHeader title="Items and Collections" backText="Back to My Items" />

      <Switch>
        <Route path={`${path}/collection/:collection_id?`}><AddEditItemCollection /></Route>
        <Route path={`${path}/item/:item_id?`}><AddEditItem /></Route>
        <Route>
          <Header className="relaxed">
            My Collections
            <Header.Subheader>
              Think of collections as folders of items. They can be from our library or items you upload or a combination.
              Collections are really handy for games. Usually you want a game to be based around a lesson, but sometimes what you (or your student) need
              is some of your favorite princesses. That's what collections are for.
            </Header.Subheader>
          </Header>

          <MustBeSubscribed minLevel={SubscriptionLevels.Small}>
            <div className="profile-section">
              {allMyCollections.current().length > 0 ?
                <ItemCollectionList /> :
                <em>You don't have any collection yet. Make one!</em>
              }
            </div>

            <Button positive as={Link} to={`${path}/collection`}>Create a Collection</Button>
          </MustBeSubscribed>

          <Header className="relaxed">
            My Items
            <Header.Subheader>
              Upload your own items and link them to lessons and attributes! If you find yourself uploading items that you think others can use, and you have rights to the images,
              please reach out and we'll be happy to add them to the public library!
            </Header.Subheader>
          </Header>

          <MustBeSubscribed minLevel={SubscriptionLevels.Small}>
            <div className="profile-section">
              {allMyItems.current().length > 0 ?
                <ItemList showList="my" relaxed /> :
                <em>You don't have any custom items yet. Add one!</em>
              }
            </div>

            <Button positive as={Link} to={`${path}/item`}>Add a Custom Item</Button>
          </MustBeSubscribed>
        </Route>
      </Switch>
    </>
  ) : null)
}

export default UserItems;