import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavLink, useLocation } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

import { AuthContext } from '../../contexts/auth';

function AdminMenuItems () {
  const { pathname } = useLocation();
  const auth = useContext(AuthContext);
  const isMobilePortrait = useMediaQuery({ maxWidth: 768, orientation: "portrait" });
  
  return auth.user?.is_admin ? (
    <Dropdown item icon={isMobilePortrait ? "setting" : null} text={isMobilePortrait ? null : "Admin"} className={pathname.startsWith("/admin") ? "active" : ""} >
      <Dropdown.Menu>
        <Dropdown text="Items" as={Dropdown.Item}>
          <Dropdown.Menu>
            <Dropdown.Item as={NavLink} to="/admin/item">Items</Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/admin/item/item">Add Item</Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/admin/item/bulk">Add Bulk Items</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="Lessons" as={Dropdown.Item}>
          <Dropdown.Menu>
            <Dropdown.Item as={NavLink} to="/admin/lesson">Lessons</Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/admin/lesson/lesson">Add Lesson</Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/admin/lesson/category">Manage Lesson Categories</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown.Item as={NavLink} to="/admin/attribute">Attributes</Dropdown.Item>
        <Dropdown.Item as={NavLink} to="/admin/type">Categories</Dropdown.Item>
        <Dropdown.Item as={NavLink} to="/admin/theme">Themes</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={NavLink} to="/admin/social">Social Media Generator</Dropdown.Item>
        <Dropdown.Item as={NavLink} to="/admin/self-check">Self Check</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
}

export default AdminMenuItems;