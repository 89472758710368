import 'react-dice-complete/dist/react-dice-complete.css';

import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import ReactDice from 'react-dice-complete';

import { ToolsContext } from '../../contexts/tools';

function Dice() {
  const tools = useContext(ToolsContext);

  return tools.dice ? (
    <motion.div drag dragMomentum={false} animate={{ y: 75 }} style={{ position: "absolute", zIndex: 1, cursor: "pointer" }}>
      <ReactDice dieSize={60} outline numDice={1} rollDone={() => null} />
    </motion.div>
  ) : null
}

export default Dice;