import axios from '../config/axios';
import { SearchResult, SearchableTypes } from '../../../api/src/types/search';

export interface PagedSearchResult<T> {
  count: number,
  page: number,
  pageCount: number,
  total: number,
  data: T[]
}

export function searchAll(q:string, limit_to?: SearchableTypes[]) {
  return axios.get<SearchResult[]>(`/search?q=${q}&which=${limit_to ? limit_to.join(",") : ""}`);
}

export function searchSpecific<T>(route_root: string, query: any, join?: string[], page_number?: number, page_size?: number, sort?: string) {
  return axios.get<PagedSearchResult<T>>(`/${route_root}?s=${JSON.stringify(query)}&limit=${page_size || 30}&page=${page_number || 1}&sort=${sort || "name,ASC"}&${join ? `join=${join.join("&join=")}` : ''}`);
}