import 'react-sortable-tree/style.css';

import React, { useContext, useEffect, useState } from 'react';
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  FullTree,
  getTreeFromFlatData,
  NodeData,
  OnMovePreviousAndNextLocation,
  TreeItem,
} from 'react-sortable-tree';
import { Button, Grid, Header, Input, Label, Message } from 'semantic-ui-react';

import { Lesson, LessonCategory } from '../../../../api/src/models/lesson.entity';
import { ErrorContext } from '../../contexts/error';
import { addLessonCategory, getLessonCategories, getLessons, updateLessonCategory, updateLesson } from '../../services/lessons';

function ManageLessonCategories() {
  const [treeData, setTreeData] = useState<TreeItem[]>();
  const [lessons, setLessons] = useState<Lesson[]>();
  const [editingLesson, setEditingLesson] = useState<Lesson>();
  const [lessonCategoryFilter, setLessonCategoryFilter] = useState<number>();

  const { processApiError } = useContext(ErrorContext);

  useEffect(() => {
    getLessonCategories()
      .then(r => setTreeData(getTreeFromFlatData({ flatData: r.data.map(c => ({...c, title: c.name})), getKey: c => c.category_id, getParentKey: c => c.parent_id, rootKey: null })))
      .catch(processApiError);

    refreshLessons();
  }, 
    // eslint-disable-next-line
    []
  );

  const refreshLessons = () => {
    getLessons(["category"])
      .then(r => {
        setLessons(r.data);
        setEditingLesson(null);
      })
      .catch(processApiError);
  }

  const handleMove = async (data: FullTree & NodeData & OnMovePreviousAndNextLocation) => {
    if (data.nextParentNode) {
      await updateLessonCategory((data.node as LessonCategory).category_id, { parent_id: (data.nextParentNode as LessonCategory).category_id });
    }
  }

  const handleUpdateCategory = async (node: LessonCategory) => {
    await updateLessonCategory((node as LessonCategory).category_id, { name: (node as LessonCategory).name });
  }

  return (
    <>
      <Header className="relaxed">
        Manage Lesson Categories
        <Header.Subheader>
          This drives Magic Search and other things.
        </Header.Subheader>
      </Header>

      <Message warning icon="warning sign" content="Be careful - this page changes things immediately and LIVE." />
      
      <Grid columns="equal" stretched divided>
        <Grid.Column style={{minHeight: 600}}>
          <Header>Categories</Header>
          
            <SortableTree treeData={treeData}
              onChange={setTreeData} onMoveNode={handleMove}
              generateNodeProps={({ node, path }) => ({
                title: (
                  <Input size="mini" action={{ icon: "save", onClick: () => handleUpdateCategory(node as LessonCategory) }}
                    value={node.name || ""}
                    onChange={event => {
                      const name = event.target.value;

                      setTreeData(changeNodeAtPath({
                        treeData: treeData,
                        path,
                        getNodeKey: n => n.treeIndex,
                        newNode: { ...node, name },
                      }));
                    }}
                  />
                ),
                buttons: [
                  <Button.Group size="tiny">
                    <Button icon="plus"
                      onClick={async () => {
                        const new_cat = (await addLessonCategory({
                          parent_id: node.category_id,
                          name: "Change Me"
                        })).data;

                        setTreeData(addNodeUnderParent({
                          treeData: treeData,
                          parentKey: path[path.length - 1],
                          expandParent: true,
                          getNodeKey: n => n.treeIndex,
                          newNode: {
                            ...new_cat,
                            title: new_cat.name
                          },
                          addAsFirstChild: true
                        }).treeData);
                      }}
                    />
                    <Button icon="filter" 
                      onClick={() => setLessonCategoryFilter(lessonCategoryFilter === node.category_id ? null : node.category_id)}
                      active={lessonCategoryFilter === node.category_id}
                    />
                    <Button disabled={editingLesson == null || node.children != null} icon="angle double down"
                      onClick={async () => {
                        await updateLesson(editingLesson.lesson_id, {category_id: node.category_id});
                        refreshLessons();
                      }}
                    />
                  </Button.Group>
                ],
              })}
            />
          
        </Grid.Column>
        <Grid.Column verticalAlign="top">
          <Header>Lessons</Header>
          <Label.Group>
            {lessons?.filter(l => !lessonCategoryFilter || l.category_id === lessonCategoryFilter ).map(l => 
              <Label as="a" color={l.category_id == null ? "grey" : "green" } 
                icon={editingLesson?.lesson_id === l.lesson_id ? "angle double left" : null} 
                key={l.lesson_id} onClick={() => setEditingLesson(l)}
                content={<span>{l.name}{l.category_id ? ` - ${l.category.name}` : ""}</span>}
              />
            )}
          </Label.Group>
        </Grid.Column>
      </Grid>

    </>
  );
};

export default ManageLessonCategories