import { useObserver } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Header } from 'semantic-ui-react';

import { ErrorContext } from '../../contexts/error';
import { doSelfCheck } from '../../services/admin';

function AdminSelfCheck () {
  const [selfCheck, setSelfCheck] = useState<any>(null);
  const {processApiError} = useContext(ErrorContext);

  useEffect(() => {
    doSelfCheck()
      .then(r => setSelfCheck({
        ...r.data,
        api: process.env.REACT_APP_API_ROOT
      }))
      .catch(processApiError)
  }, 
    // eslint-disable-next-line 
    []
  );
  
  return useObserver(() => (
    <>
      <Header className="relaxed">Self Check</Header>
      {selfCheck && <pre>{JSON.stringify(selfCheck, null, 2)}</pre>}
    </>
  ));
}

export default AdminSelfCheck;