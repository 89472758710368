import { Emoji } from 'emoji-mart';
import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Button, Grid, Header, List, Popup, Statistic, Table } from 'semantic-ui-react';

import { PlayContextProvider } from '../../contexts/play';
import { ToolsContext } from '../../contexts/tools';
import { FakeLink, TextWithEmoji } from '../../services/html';
import { StudentBackgrounds } from '../../services/students';
import Catch from '../games/Catch';
import GuessMonster from '../games/GuessMonster';
import RevealBehind from '../games/RevealBehind';
import { GameLoader } from '../shared/GameBase';

function HomePage() {
  const tools = useContext(ToolsContext);
  const isMobilePortrait = useMediaQuery({ maxWidth: 768, orientation: "portrait" });

  return (
    <>
      <Grid style={{ overflow: "hidden" }}>
        <Grid.Row>
          <Grid.Column color="violet">
            <Header size="huge" inverted style={{ zIndex: 2 }}>
              <strong>Guess Monster</strong>
              <Header.Subheader style={isMobilePortrait ? {maxWidth: 230} : {}}>The last and only subscription you need for Speech Therapy materials</Header.Subheader>
            </Header>
            <motion.img title="Guess Monster" src="/monster.svg" style={{ position: "absolute", width: 200, top: "-2em", right: 0, zIndex: 1 }} animate={{ x: 80, rotate: -30 }} transition={{ duration: .5 }} whileHover={{ scale: 1.2 }} />
          </Grid.Column>
        </Grid.Row>
        {!isMobilePortrait && (
          <Grid.Row style={{ zIndex: 2 }}>
            <Grid.Column width="16">
              <Statistic.Group widths="5" size="small" style={{zIndex: 2}}>
                <Statistic color="blue" as="a" href="#lessons">
                  <Statistic.Value>300+</Statistic.Value>
                  <Statistic.Label>Lessons</Statistic.Label>
                </Statistic>
                <Statistic color="orange" as="a" href="#items">
                  <Statistic.Value>1000+</Statistic.Value>
                  <Statistic.Label>Items</Statistic.Label>
                </Statistic>
                <Statistic color="purple" as="a" href="#attributes">
                  <Statistic.Value>1000+</Statistic.Value>
                  <Statistic.Label>Attributes</Statistic.Label>
                </Statistic>
                <Popup position="bottom center" content={<span>Seriously, we did the math and there are over 91 <em>quadrillion</em> (that's 15 zeros) game combinations</span>} trigger={(
                  <Statistic color="olive" as="a" href="#games">
                    <Statistic.Value><motion.div style={{ position: "relative", top: "-.2em" }} animate={{ scale: [1, 1.2, 1.3] }} transition={{ scale: { duration: 1, yoyo: Infinity } }}>&infin;</motion.div></Statistic.Value>
                    <Statistic.Label>Games</Statistic.Label>
                  </Statistic>
                )}></Popup>
                <Statistic style={{ cursor: "pointer" }} onClick={() => tools.toggleConfetti()}>
                  <Statistic.Value><motion.div style={{ position: "relative" }} animate={{ scale: 2 }} transition={{ scale: { duration: .5, yoyo: 1 } }}><Emoji emoji="tada" size={30} /></motion.div></Statistic.Value>
                  <Statistic.Label>Instant</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>

      <Grid>
        <Grid.Row id="lessons">
          <Grid.Column width="5" color="blue" style={{ overflow: "hidden", minHeight: 250 }}>
            <Header style={{ textTransform: "uppercase" }} inverted>Let's talk about Lessons</Header>
            <img title="Guess Monster" alt="monster" style={{ width: 100, position: "absolute", bottom: 0 }} src="https://storage.googleapis.com/guess-monster.appspot.com/item-images/thumb_130.png" />
          </Grid.Column>
          <Grid.Column width="11">
            <List size="big" className="home-section-list">
              <List.Item>Go <em>narrow</em> with specific lessons like <strong>Ch /tʃ/ - Blends Medial</strong></List.Item>
              <List.Item>Go <em>wide</em> with a lesson like <strong>Ch /tʃ/ - All</strong></List.Item>
              <List.Item>Get <em>fancy</em> by targeting things like <strong>R Colored Vowels - AIR /ɛr/ - Initial</strong></List.Item>
              <List.Item>Have <em>fun</em> with lessons like <strong>Farm Animals</strong> or <strong>Body Parts</strong></List.Item>
              <List.Item>Dive <em>deep</em> with concepts like <strong>Emotions</strong></List.Item>
              <List.Item>Get <em>colorful</em> with <strong>Color Identification</strong></List.Item>
              <List.Item>Get ready for seasons with <strong>Gardening</strong> and <strong>Beach</strong></List.Item>
              <List.Item className="emphasize">Follow along with book companions like <strong>Little Yellow Bee</strong> <Emoji emoji="bee" size={16} /> and <strong>Good Night, Gorilla</strong></List.Item>
            </List>

            <div style={{ textAlign: "center", marginBottom: "1em" }}><Button positive size="large" as={Link} to="/pricing">I'm Into It! 2 Weeks Free!</Button></div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row stretched id="games">
          <Grid.Column width="11" style={{paddingLeft: 0, paddingRight: 0, marginTop: "-1rem", marginBottom: "-1rem"}}>
            <PlayContextProvider defaultDefinition={{ number_of_items: 3, emphasize: "item name", theme_id: 33 }}>
              <GameLoader game={GuessMonster} loaderText="Loading playable game!" headless />
            </PlayContextProvider>
          </Grid.Column>
          <Grid.Column width="5" color="olive" style={{ overflow: "hidden", minHeight: 250 }} stretched>
            <Header style={{ textTransform: "uppercase" }} inverted>These aren't your mother's games<Header.Subheader>But we've got those too!</Header.Subheader></Header>
            <div><TextWithEmoji emoji="point_left" text="I'm a real game sample! Play me! (Guess what the monster is thinking about)" style={{ fontWeight: "bold" }} /></div>
            <img title="Guess Monster" alt="monster" style={{ width: 100, position: "absolute", bottom: 0, right: 0 }} src="https://storage.googleapis.com/guess-monster.appspot.com/item-images/thumb_113.png" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row stretched id="attributes">
          <Grid.Column width="5" color="purple" style={{ overflow: "hidden", minHeight: 250 }}>
            <Header style={{ textTransform: "uppercase" }} inverted>Say <Emoji emoji="wave" size={24} /> to tagged datasets<Header.Subheader>Don't be scared!</Header.Subheader></Header>
            <img title="Guess Monster" alt="monster" style={{ width: 100, position: "absolute", bottom: 0 }} src="https://storage.googleapis.com/guess-monster.appspot.com/item-images/thumb_139.png" />
          </Grid.Column>
          <Grid.Column width="11">
            <List size="big" className="home-section-list">
              <List.Item>It's a <strong>Machine Learning</strong> term that makes sure that every target is contextual. You are no longer limited to a couple of the same pictures for all your lessons (but you still can use the same pictures for everything if you really want).</List.Item>
              <List.Item className="emphasize">For example, a cute armadillo is never <em>just</em> an armadillo.</List.Item>
              <List.Item>
                <Table celled size="small">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell collapsing>
                        <img title="Armadillo" alt="Armadillo" width="150" src="https://storage.googleapis.com/guess-monster.appspot.com/item-images/thumb_65.png" />
                      </Table.Cell>
                      <Table.Cell>
                        <Header size="tiny">Attributes</Header>
                        <div style={{ fontSize: "smaller", marginBottom: "1em" }}>Color: Brown; Creature Feature: Tail, Ears, Legs, Eyes, Feet, Shell; Creature Name: Armadillo; Eye-Color: Black; Eye-Number: 2; Leg-Number: 4</div>

                        <Header size="tiny">Lessons</Header>
                        <div style={{ fontSize: "smaller" }}>L-Medial, L-Mixed, L-All, D-Medial, D-Mixed, D-All, M-Medial, M-Mixed, M-All, Four or More Syllable Words, R Colored Vowels-AR Initial, R Colored Vowels-AR Mixed, Zoo Theme, Rain Forest</div>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </List.Item>
              <List.Item>That means, we can use the armadillo to talk about a lot of things - not just <strong>Zoo Animals</strong>.</List.Item>
              <List.Item>And there are <em>thousands</em> more pictures and attributes to make sure that every session is <em>exactly</em> what you need.</List.Item>
            </List>

            <div style={{ textAlign: "center", marginBottom: "1em" }}><Button positive size="large" as={Link} to="/pricing">You Had Me At Armadillo!</Button></div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row stretched id="items">
          <Grid.Column width="11">
            <List size="big" className="home-section-list">
              <List.Item><strong>Therapist and educators</strong>, you know those PDFs you're using and <em>think</em> you like where the words have nothing to do with the pictures? <strong>It gets boring and predicatble fast!</strong></List.Item>
              <List.Item>Are you tired of purchasing the same exact set, with the same exact words over and over again just for a slightly different background picture? <strong>Us too!</strong></List.Item>
              <List.Item><strong>There is a better way</strong> - with items that actually fit a <strong>theme</strong>, match a <strong>lesson</strong> and have something to do with <strong>every target</strong> you're working on. And you don't have to pay extra just to do it in the "jungle" or on a "beach" or dozens (soon to be thousands) of other themes.</List.Item>
              <List.Item className="emphasize">Our item library is <strong>big</strong>, but that alone is not enough - that's why it's also <strong>organized and user friendly</strong> so you can tailor your session to a lesson, a theme, the student, and anything else you need - <em>in seconds</em>.</List.Item>
            </List>

            <div style={{ textAlign: "center", marginBottom: "1em" }}><Button positive size="large" as={Link} to="/pricing">Yes! About Time!</Button></div>
          </Grid.Column>
          <Grid.Column width="5" color="orange" style={{ overflow: "hidden", minHeight: 250 }}>
            <Header style={{ textTransform: "uppercase" }} inverted>A library like you've never seen</Header>
            <img title="Guess Monster" alt="monster" style={{ width: 100, position: "absolute", bottom: 0, right: 0 }} src="https://storage.googleapis.com/guess-monster.appspot.com/item-images/thumb_126.png" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row stretched>
          <Grid.Column width="5" color="olive" style={{ overflow: "hidden", minHeight: 250 }} stretched>
            <Header style={{ textTransform: "uppercase" }} inverted>Did we mention games?</Header>
            <div style={{ float: "right" }}><TextWithEmoji emoji="point_right" text="I'm a real game sample! Play me! (Name the item and check behind it)" swap style={{ fontWeight: "bold" }} /></div>
            <img title="Guess Monster" alt="monster" style={{ width: 100, position: "absolute", bottom: 0 }} src="https://storage.googleapis.com/guess-monster.appspot.com/item-images/thumb_116.png" />
          </Grid.Column>
          <Grid.Column width="11" style={{ paddingLeft: 0, paddingRight: 0, marginTop: "-1rem", marginBottom: "-1rem" }}>
            <PlayContextProvider defaultDefinition={{ number_of_items: 3, emphasize: "item name", theme_id: 9, game_options: { reveal_style: "rotate left" } }}>
              <GameLoader game={RevealBehind} loaderText="Loading playable game!" headless />
            </PlayContextProvider>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row stretched id="recipes">
          <Grid.Column width="11">
            <List size="big" className="home-section-list">
              <List.Item>
                A <em>recipe</em> is how you build your games. You can design custom recipes easily or choose from an ever-growing library of popular recipes.
                
                <img title="Recipe builder" alt="Recipe builder" src="/screenshots/recipe.png" width="100%" style={{marginTop: "2em", marginBottom: "2em"}} />

                Here are some examples:
                <List relaxed>
                  <List.Item>I need <strong>8 items</strong> that will work for the <strong>Initial K lesson</strong> and I want them to be <strong>Truck-related</strong>. Also, he likes <strong>aliens</strong> so we're going to do it in <strong>space</strong>. <em>NO PROBLEM</em></List.Item>
                  <List.Item className="emphasize">We're working on <strong>comparisons</strong> so I want <strong>people and animals</strong> that are clearly <strong>children and adults</strong> that we're going to <strong>sort</strong>. She likes <strong>princesses</strong> so let's sort <strong>in a castle</strong> with the <strong>children on a unicorn and the adults on a dragon</strong>. <em>DONE</em></List.Item>
                  <List.Item>He is into <strong>airplanes</strong> and we're working on <strong>Voiceless TH /θ/</strong> so let's put pictures and words that fit the lesson onto airplanes, let's have them <strong>fly above a city</strong> and catch them as we say the words. <em>YEAH - WE CAN DO THAT</em></List.Item>
                </List>
              </List.Item>
              <List.Item>
                And <strong>91 <em>quadrillion</em> more combinations. </strong>
              </List.Item>
            </List>

            <div style={{ textAlign: "center", marginBottom: "1em" }}><Button positive size="large" as={Link} to="/pricing">Let's Get Cooking! 2 Weeks Free!</Button></div>
          </Grid.Column>
          <Grid.Column width="5" color="pink" style={{ overflow: "hidden", minHeight: 250 }}>
            <Header style={{ textTransform: "uppercase" }} inverted>It all starts with recipes</Header>
            <img title="Guess Monster" alt="monster" style={{ width: 150, position: "absolute", bottom: 0, right: 0 }} src="https://storage.googleapis.com/guess-monster.appspot.com/item-images/thumb_123.png" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row stretched>
          <Grid.Column width="5" color="teal" style={{ overflow: "hidden", minHeight: 250 }}>
            <Header style={{ textTransform: "uppercase" }} inverted>We are all unique</Header>
            <img title="Guess Monster" alt="monster" style={{ width: 150, position: "absolute", bottom: 0 }} src="https://storage.googleapis.com/guess-monster.appspot.com/item-images/thumb_127.png" />
          </Grid.Column>
          <Grid.Column width="11" verticalAlign="top">
            <List size="big" className="home-section-list">
              <List.Item>Make each session unique with <strong>custom emojis</strong> <Emoji emoji="unicorn_face" size={16} />.</List.Item>
              <List.Item>Let kids <FakeLink onClick={() => { document.body.style.background = `url(${StudentBackgrounds[Math.floor(Math.random() * StudentBackgrounds.length)].url})`; window.setTimeout(() => document.body.style.background = null, 3000); }}><strong>pick their own backgrounds (click me)</strong></FakeLink>! We have many options and adding more daily.</List.Item>
              <List.Item>Add <strong>custom themes</strong>, <strong>custom images and collections</strong>, <strong>and much more</strong>!</List.Item>
              <List.Item>Enjoy <strong>so many monsters</strong>!</List.Item>
              <List.Item className="emphasize">And, of course, <FakeLink onClick={() => tools.toggleConfetti()}><strong>confetti on demand</strong></FakeLink>!</List.Item>
            </List>

            <div style={{ textAlign: "center", marginBottom: "1em" }}><Button positive size="large" as={Link} to="/pricing">I Love Confetti And Free Trials!</Button></div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row stretched style={{ minHeight: 450 }}>
          <Grid.Column width="11" style={{ paddingLeft: 0, paddingRight: 0, marginTop: "-1rem", marginBottom: "-1rem" }}>
            <PlayContextProvider defaultDefinition={{ number_of_items: 3, emphasize: "item name", theme_id: 8, lesson_id: 228, game_options: { replace_floaters: true } }}>
              <GameLoader game={Catch} loaderText="Loading playable game!" headless />
            </PlayContextProvider>
          </Grid.Column>
          <Grid.Column width="5" color="olive" style={{ overflow: "hidden", minHeight: 250 }} stretched>
            <Header style={{ textTransform: "uppercase" }} inverted>But seriously, the games!</Header>
            <div><TextWithEmoji emoji="point_left" text="I'm a real game sample! Play me! (Catch the words as you say them)" style={{fontWeight: "bold"}} /></div>
            <img title="Guess Monster" alt="monster" style={{ width: 100, position: "absolute", bottom: 0, right: 0 }} src="https://storage.googleapis.com/guess-monster.appspot.com/item-images/thumb_115.png" />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ minHeight: 250 }}>
          <Grid.Column width="16" textAlign="center" verticalAlign="middle">
            <Button positive size="huge" as={Link} to="/pricing">Let's Do It! Start My Trial!</Button>
          </Grid.Column>
        </Grid.Row>

      </Grid>
    </>
  )
}

export default HomePage