import * as firebaseui from 'firebaseui';
import React, { useContext } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import firebase from '../../config/firebase';
import { useRouteMatch } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth';

function Login() {
  const {path} = useRouteMatch();
  const auth = useContext(AuthContext);

  const uiConfig = {
    signInFlow: 'popup',
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    tosUrl: "https://www.guessmonster.com/legal/GuessMonster-TOS.pdf",
    privacyPolicyUrl: "https://www.guessmonster.com/legal/GuessMonster-PP.pdf",
    callbacks: {
      // if on login page, redirect; otherwise stay where you are
      signInSuccessWithAuthResult: () => (path === "/login")
    },
    signInSuccessUrl: "/game",

  } as firebaseui.auth.Config;

  return ! auth.user ? (
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
  ) : null;
}

export default Login;