import React, { useContext } from 'react';
import Confetti from 'react-confetti';
import useResizeAware from 'react-resize-aware';

import { ToolsContext } from '../../contexts/tools';

function ConfettiTool(props: {inGame?:boolean, fullScreen?: boolean}) {
  const [resizeListener, sizes] = useResizeAware();
  const tools = useContext(ToolsContext);
  
  return tools.confetti && (!props.inGame || props.fullScreen) ? (
    <>
      {resizeListener}
      <Confetti style={{position: "fixed", top: 0}} recycle={false} numberOfPieces={300} width={sizes.width} onConfettiComplete={() => tools.toggleConfetti()} />
    </>
  ) : null
}

export default ConfettiTool;