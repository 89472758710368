import _ from 'lodash';
import { useObserver } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button, Card, Input, List, Message, Pagination, Placeholder, Checkbox } from 'semantic-ui-react';

import { Lesson } from '../../../../api/src/models/lesson.entity';
import { AuthContext } from '../../contexts/auth';
import { ErrorContext } from '../../contexts/error';
import { TextWithEmoji } from '../../services/html';
import { updateLesson } from '../../services/lessons';
import { PagedSearchResult, searchSpecific } from '../../services/search';

interface LessonListProps {
  showList: "all" | "my";
  relaxed?: boolean;
  showFilter?: boolean;
}

const placeholder = (
  <Placeholder style={{ width: 200 }}>
    <Placeholder.Image square />
    <Placeholder.Line />
    <Placeholder.Line />
  </Placeholder>
);

function LessonList(props: LessonListProps) {
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagedResults, setPagedResults] = useState<PagedSearchResult<Lesson>>();
  const [filter, setFilter] = useState("");
  const [draftOnly, setDraftOnly] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);

  const { showList } = props;  
  const { path } = useRouteMatch();
  const { processApiError } = useContext(ErrorContext);
  const auth = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);

    const fq = showList === "all" ? {
      "$and": [{
        "$or": [
          { "name": { "$contL": filter } },
          { "description": { "$contL": filter } },
          { "category.name": { "$contL" : filter } }
        ]},
        draftOnly ? { "is_draft": true } : null
      ]
    } : {
      "$and": [{
        "$or": [
          { "name": { "$contL": filter } },
          { "description": { "$contL": filter } },
          { "category.name": { "$contL": filter } }
        ]
      },
      { "is_public": false }]
    }

    searchSpecific<Lesson>("lesson", fq, ["category"], pageNumber, 40)
      .then(r => {
        setPagedResults(r.data);
        setLoading(false)
      })
      .catch(processApiError);
  }, 
    // eslint-disable-next-line
    [filter, pageNumber, showList, draftOnly, forceRefresh]
  );

  return useObserver(() => pagedResults ? (
    <>
      {props.showFilter && (
        <div className="relaxed">
          <Input action={{ icon: 'add', primary: true, as: Link, to: `${path}/lesson` }} placeholder={`Filter ${pagedResults.total} lessons...`} onChange={_.debounce((_, d) => setFilter(d.value), 300)} fluid />
          {auth.user?.is_admin && <Checkbox checked={draftOnly} onChange={() => setDraftOnly(!draftOnly)} label="Draft Only" />}
        </div>
      )}
      
      { loading ? placeholder : (
        <Card.Group itemsPerRow={4}>
          {pagedResults.data.map(l => (
            <Card key={l.lesson_id}>
              <Card.Content>
                <Card.Header>{l.name}</Card.Header>
                <Card.Description>{l.description}</Card.Description>
              </Card.Content>
              <Card.Content>
                {l.category_id && <Card.Meta><TextWithEmoji emoji={l.category.icon || "woman_shrugging"} text={l.category.name} /></Card.Meta>}
                <List>
                  {l.match_items && <List.Item content={`${l.item_count} item${l.item_count === 1 ? "" : "s"}`} />}
                  {/* <List.Item content={`${l.attribute_count} attribute${l.attribute_count === 1 ? "" : "s"}`} /> */}
                  {l.match_attribute_values && <List.Item content={`${l.value_count} value${l.value_count === 1 ? "" : "s"}`} />}
                </List>
                {!(l.match_items || l.match_attribute_values) && <Message warning>This lesson doesn't apply to items or attribute values which makes it pretty useless. Please correct.</Message> }
              </Card.Content>
              <Card.Content extra>
                <Button.Group basic size="mini" color={l.is_draft ? "yellow" : null}>
                  <Button as={Link} to={`${path}/lesson/${l.lesson_id}`} basic size="mini">Edit</Button>
                  {auth.user?.is_admin && <Button icon={l.is_draft ? "eye slash" : "eye"} title={l.is_draft ? "DRAFT" : "PUBLISHED"} onClick={async () => {
                    await updateLesson(l.lesson_id, { is_draft: !l.is_draft });
                    setForceRefresh(!forceRefresh);
                  }} />}
                </Button.Group> 
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      )}

      {pagedResults.total === 0 && <em style={{ marginTop: "1.5em", display: "block" }}>No lessons fit your filter.</em>}

      {pagedResults.pageCount > 1 && (
        <div style={{ marginTop: "3em", display: "flex", justifyContent: "center" }}>
          <Pagination activePage={pageNumber} totalPages={pagedResults.pageCount} onPageChange={(_, d) => setPageNumber(d.activePage as number)} />
        </div>
      )}
    </>
  ) : null);
};

export default LessonList