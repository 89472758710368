import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { AddEditAttribute } from './AddEditAttribute';
import { ManageAttributes } from './ManageAttributes';
import PageHeader from '../shared/PageHeader';

function AttributesHome () {
  let { path } = useRouteMatch();
  
  return (
    <>
      <PageHeader title="Attributes" backText="Back to Attribute Library" />
      
      <Switch>
        <Route path={`${path}/attribute/:attribute_id?`} component={AddEditAttribute} />
        <Route path={path} component={ManageAttributes} />
      </Switch>
    </>
  );
}

export default AttributesHome;