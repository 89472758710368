import { motion, MotionStyle } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import Fullscreen from 'react-full-screen';
import useResizeAware from 'react-resize-aware';
import { Checkbox, Menu, Message } from 'semantic-ui-react';

import { Item } from '../../../../api/src/models/item.entity';
import { ErrorContext } from '../../contexts/error';
import { PlayContext } from '../../contexts/play';
import { getThemeFloaters } from '../../services/gamethemes';
import { TextWithEmoji } from '../../services/html';
import { getImageThumbUrl } from '../../services/images';
import { GameProps } from '../shared/GameBase';
import GameTools from './GameTools';
import ItemEmphasizer from './ItemEmphasizer';

const floater_width = 200;
const stagger = 85;
const gap = 35;

const floaterStyle: MotionStyle = {
  position: "absolute",
  display: "flex",
  cursor: "pointer",
  width: floater_width,
  maxHeight: floater_width,
  justifyContent: "center",
  alignItems: "center",
}

const floaterInsideStyle: MotionStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: floater_width,
  maxHeight: floater_width
}

function Catch(props: GameProps) {
  const { processApiError } = useContext(ErrorContext);
  const { recipeDefinition, theme, updateGameOptions: setGameOptions, playItems, onFinish } = useContext(PlayContext);

  const [resizeListener, sizes] = useResizeAware();
  const [itemDurationMap, setItemDurationMap] = useState<{[id: number]: number}>({});
  const [itemStateMap, setItemStateMap] = useState<{ [id: number]: "active" | "done" }>({});
  const [itemFloaterMap, setItemFloaterMap] = useState<{ [id: number]: Item }>({});
  const [itemStartMap, setItemStartMap] = useState<{ [id: number]: {} }>({});
  const [justFinished, setJustFinished] = useState(false);
  const [floaters, setFloaters] = useState<Item[]>();
  const [replaceFloaters, setReplaceFloaters] = useState(recipeDefinition.game_options?.replace_floaters != null ? recipeDefinition.game_options?.replace_floaters : true);
  const [isFullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    setGameOptions({
      game_name: "Catch", 
      game_options: {
        replace_floaters: replaceFloaters
      }
    });
  }, 
    // eslint-disable-next-line
    [replaceFloaters]
  );

  useEffect(() => {
    const allDone = Object.values(itemStateMap).length === playItems.items.length && Object.values(itemStateMap).every(i => i === "done");
    
    if (allDone && onFinish && !justFinished) {
      setJustFinished(true);
      onFinish();
    }
  }, 
    // eslint-disable-next-line
    [itemStateMap, justFinished, playItems]
  );

  useEffect(() => {
    setFloaters(null);
    setItemFloaterMap({});
    setItemStateMap({});

    if (theme && theme.floater_ids) {
      getThemeFloaters(theme).then(f => {
        setFloaters(f.data);

        if (! f.data || f.data.length === 0) {
          setReplaceFloaters(true);
        }
        else {
          setReplaceFloaters(recipeDefinition.game_options?.replace_floaters);
        }
      }).catch(processApiError);
    }
    else {
      setReplaceFloaters(true);
    }
  }, 
    // eslint-disable-next-line
    [theme]
  );

  useEffect(() => {    
    // TODO: this behaves weirdly if there are less items than recipe.num_items 
    // because the items.length doesn't change when you go up and down by 1 in the recipe.
    if (Object.keys(itemDurationMap).length !== playItems.items.length) {
      const min_time = (sizes.width || 1100) / 30; // the wider the screen, the slower the items
      setItemDurationMap(playItems.items.reduce((acc, cur, i) => { acc[i] = Math.floor(Math.random() * 16) + min_time; return acc }, {}));
    }

    if (floaters && Object.keys(itemFloaterMap).length !== playItems.items.length) {
      setItemFloaterMap(playItems.items.reduce((acc, cur, i) => { acc[i] = floaters[Math.floor(Math.random() * floaters.length)]; return acc }, {}));
    }

    setItemStartMap(playItems.items.reduce((acc, cur, i) => { acc[i] = getStartLocation(cur); return acc }, {}));
  },
    // eslint-disable-next-line
    [playItems, floaters, replaceFloaters]
  );

  useEffect(() => {
    setItemStateMap({});
    setItemStartMap({});
  }, [playItems])

  const getFloaterFor = (index: number) => {
    return itemFloaterMap[index];
  }

  const getDurationFor = (index:number) => {
    return itemDurationMap[index];
  }

  const getStateFor = (id: number) => {
    return itemStateMap[id] || "active";
  }

  const clickFloater = (id: number) => {
    setJustFinished(false);
    setItemStateMap({ ...itemStateMap, [id]: "done" });
  }

  const containerStyle: React.CSSProperties = {
    position: "relative", 
    display: "flex", 
    backgroundImage: `url(${theme?.background_url})`, 
    backgroundSize: "cover", 
    height: !props.headless ? (isFullScreen ? "100vh" : null)  : "100%", 
    minHeight: playItems.items.length * (stagger + gap) + gap,
    width: "100%",

    ...theme.container_style
  }

  const textStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "100%",

    ...theme.font_style
  }

  const variants = ({
    active_r: {
      translateX: [0, sizes.width - floater_width, sizes.width - floater_width, 0, 0],
      rotateY: [0, 0, 180, 180, 360],
      scale: [1, 1, 1, 1, 1],
    },
    active_l: {
      translateX: [sizes.width - floater_width, 0, 0, sizes.width - floater_width, sizes.width - floater_width],
      rotateY: [0, 0, 180, 180, 360],
      scale: [1, 1, 1, 1, 1],
    },
    done: {
      rotateY: 70,
      scale: .3,
      opacity: 0
    }
  });

  const getStartLocation = (item: Item) => {
    switch (item.facing_direction) {
      case "l": return { x: 0 } as React.CSSProperties
      default: return { left: 0 } as React.CSSProperties
    }
  }

  return (!floaters || Object.keys(itemFloaterMap).length === playItems.items.length) && Object.keys(itemDurationMap).length === playItems.items.length ? (
    <>
      {!props.headless && (
        <>
          <Message info className="tour-game-instructions"><TextWithEmoji emoji="point_right" text="Click on the floaters to remove them. You can replace them with the actual items (below) and choose what's emphasized in the recipe." /></Message>

          <Menu attached="top" className="tour-game-menu">
            <Menu.Item>
              <Checkbox toggle checked={replaceFloaters || !floaters || floaters.length === 0} disabled={!floaters || floaters.length === 0} onChange={() => setReplaceFloaters(!replaceFloaters)} label="Replace with items" />
            </Menu.Item>
          </Menu>
        </>
      )}
      
      <Fullscreen enabled={isFullScreen} onChange={f => setFullScreen(f)}>
        <div style={containerStyle} id="game-container">
          {resizeListener}

          {!props.headless && <GameTools screenshot fullscreen confetti draw isFullScreen={isFullScreen} onFullScreenToggle={() => setFullScreen(!isFullScreen)} />}

          {playItems.items.map((item, index) => {
            const main = replaceFloaters || !floaters ? item : getFloaterFor(index);
            
            return (
              <motion.div key={`${item.item_id}-${sizes.width}-${main.item_id}`} style={{ ...floaterStyle, ...itemStartMap[index], top: index * stagger + gap }}
                animate={getStateFor(item.item_id) === "active" ? variants[`active_${main.facing_direction || "r"}`] : variants.done}
                transition={{
                  duration: getStateFor(item.item_id) === "active" ? getDurationFor(index) : 4,
                  times: getStateFor(item.item_id) === "active" ? [0, .4, .5, .9, 1] : null,
                  loop: getStateFor(item.item_id) === "active" ? Infinity : 0
                }}
              >
                <motion.div style={floaterInsideStyle} whileHover={{ scale: 1.3 }} onTap={() => clickFloater(item.item_id)}>
                  <img style={{ maxHeight: floater_width, maxWidth: floater_width }} draggable="false" src={getImageThumbUrl(main.image_url)} alt="" />
                  <div style={textStyle}>
                    <ItemEmphasizer item={item} recipe={recipeDefinition} />
                  </div>
                </motion.div>
              </motion.div>
            )
          })}
          </div>
      </Fullscreen>
    </>
  ) : null
};

export default Catch;