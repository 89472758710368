import { lazyObservable } from 'mobx-utils';

import { AttributeValueLesson } from '../../../api/src/models/attribute.entity';
import { ItemLesson } from '../../../api/src/models/item.entity';
import { Lesson, LessonCategory } from '../../../api/src/models/lesson.entity';
import { SearchableTypes } from '../../../api/src/types/search';
import axios from '../config/axios';

/** these are LIVE lessons only */
export const allLessons = lazyObservable<Lesson[]>(sink => getLessons().then(u => sink(u.data)));

export function filterApplicableLessons(lesson_ids: number[], applicable_for: SearchableTypes[]) {
  return allLessons.current()
    .filter(l =>
      lesson_ids.includes(l.lesson_id) &&
      (!applicable_for.includes("item") || l.match_items) &&
      (!applicable_for.includes("value") || l.match_attribute_values));
}

/** DO NOT use this except in admin pages */
export function getLessons(join?: string[]) {
  return axios.get<Lesson[]>(`/lesson?sort=name,ASC&${join ? `join=${join.join("&join=")}` : ''}`);
}

/** null/absent root id for top most root nodes */
export function getLessonCategoriesForTree(root_id: number) {
  return axios.get<LessonCategory[]>(`/lesson-category/for-tree/?root_id=${root_id}`);
}

export function getLessonCategories() {
  return axios.get<LessonCategory[]>(`/lesson-category?&sort=name,ASC`);
}

export function updateLessonCategory(category_id: number, category: Partial<LessonCategory>) {
  return axios.patch<LessonCategory>(`/lesson-category/${category_id}`, category);
}

export function addLessonCategory(category: Partial<LessonCategory>) {
  return axios.post<LessonCategory>(`/lesson-category/`, category);
}

export function getLesson(lesson_id: number, join?: string[]) {
  return axios.get<Lesson>(`/lesson/${lesson_id}?${join ? `join=${join.join("&join=")}` : ''}`);
}

//** includes draft lessons. when we allow users to add lessons, we'll need to update */
export function findLessonsByName(name: string) {
  return axios.get<Lesson[]>(`/lesson?s={"name": "${name}"}`);
}

export function addLesson(lesson: Partial<Lesson>) {
  return axios.post<Lesson>(`/lesson`, lesson);
}

export function updateLesson(lesson_id: number, lesson: Partial<Lesson>) {
  return axios.patch<Lesson>(`/lesson/${lesson_id}`, lesson);
}

export function addLessonItems(lesson_id: number, item_ids: number[]) {
  return axios.post<ItemLesson[]>(`/lesson/${lesson_id}/item`, item_ids);
}

export function addLessonValues(lesson_id: number, value_ids: number[]) {
  return axios.post<AttributeValueLesson[]>(`/lesson/${lesson_id}/value`, value_ids);
}

export function removeLessonItem(lesson_id: number, item_id: number) {
  return axios.delete<void>(`/lesson/${lesson_id}/item/${item_id}`);
}

export function removeLessonValue(lesson_id: number, value_id: number) {
  return axios.delete<void>(`/lesson/${lesson_id}/value/${value_id}`);
}