import { lazyObservable } from 'mobx-utils';

import { GameTheme, ItemDisplayLocation } from '../../../api/src/models/gametheme.entity';
import { Item } from '../../../api/src/models/item.entity';
import { GameName } from '../../../api/src/types/game';
import axios from '../config/axios';
import { getImageThumbUrl } from './images';

export const ItemDisplayLocationsContainerAdjustment : { [key in ItemDisplayLocation]: React.CSSProperties } = {
  left: { flexDirection: "column" },
  right: { flexDirection: "column", alignItems: "flex-end" },
  top: { alignItems: "flex-start" },
  bottom: { alignItems: "flex-end" },
  "vertical middle": { flexDirection: "column", alignItems: "center" },
  "horizontal middle": { alignItems: "center" },
  "random": {}
}

export const ItemDisplayLocationsItemAdjustment: { [key in ItemDisplayLocation]: React.CSSProperties } = {
  left: { margin: 0, padding: ".5em" },
  right: { margin: 0, padding: ".5em" },
  top: {  },
  bottom: {  },
  "vertical middle": { margin: 0, padding: ".5em" },
  "horizontal middle": {  },
  "random": {  }
}

export const getThemeBackgroundStyle = (theme: GameTheme, thumb?: boolean) => {
  if (! theme)
    return null;
    
  const { backgroundSize, backgroundImage, backgroundRepeat, backgroundPosition } = theme.container_style as React.CSSProperties || {};

  return {
    backgroundImage: backgroundImage || `url(${thumb ? getImageThumbUrl(theme.background_url) : theme.background_url})`,
    backgroundSize: backgroundSize || "cover",
    backgroundRepeat,
    backgroundPosition
  }
}

// this is hardcoded and that's bad... but it's a bandaid until we do something better
const CONTAINER_TYPE_ID = 39

export function getItemDisplayLocation(game: GameName, theme: GameTheme, default_location?: ItemDisplayLocation) {
  return theme.more_options?.default_item_locations[game] || default_location || "left";
}

export const allMyGameThemes = lazyObservable<GameTheme[]>(sink => getMyGameThemes().then(u => sink(u.data)));

function getMyGameThemes() {
  return axios.get<GameTheme[]>(`/game-theme?filter=is_public||$eq||false&sort=name,ASC`);
}

/** if theme_id is null, returns a random public theme (or featured theme in the future) */
export function getGameTheme(theme_id: number, join?: string[]) {
  return axios.get<GameTheme>(`/game-theme/${theme_id || 'random'}?${join ? `join=${join.join("&join=")}` : ''}`);
}

export function getThemeFloaters(theme: GameTheme) {
  return axios.get<Item[]>(`/item?filter=item_id||$in||${theme.floater_ids}`);
}

export async function getThemeBuckets(theme: GameTheme) {
  if (theme.more_options?.buckets && theme.more_options?.buckets.length > 0) {
    return await axios.get<Item[]>(`/item?filter=item_id||$in||${theme.more_options?.buckets.map(i => i.item_id).join(",")}`);
  }
  else {
    return await axios.get<Item[]>(`/item?filter=type_id||$eq||${CONTAINER_TYPE_ID}&limit=10`);
  }
}

export function addGameTheme(theme: Partial<GameTheme>) {
  return axios.post<GameTheme>(`/game-theme`, theme);
}

export function updateGameTheme(theme_id: number, theme: Partial<GameTheme>) {
  return axios.patch<GameTheme>(`/game-theme/${theme_id}`, theme);
}

export function updateThemeBackgroundByFile(theme_id: number, file: File) {
  const data = new FormData();
  data.append("file", file);

  return axios.post<string>(`/game-theme/${theme_id}/image/by_file`, data);
}