import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PageHeader from '../shared/PageHeader';
import AddEditTheme from './AddEditTheme';
import ThemeList from './ThemeList';

function GameThemesHome () {
  let { path } = useRouteMatch();
  
  return (
    <>
      <PageHeader title="Themes" backText="Back to Theme Library" />

      <Switch>
        <Route path={`${path}/theme/:theme_id?`} component={AddEditTheme} />
        <Route path={path} component={() => <ThemeList showList="all" showFilter />} />
      </Switch>
    </>
  );
}

export default GameThemesHome;