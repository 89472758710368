import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, Header } from 'semantic-ui-react';

import { SearchResult } from '../../../../api/src/types/search';
import { AuthContext } from '../../contexts/auth';
import { ErrorContext } from '../../contexts/error';
import { TextWithEmoji } from '../../services/html';
import { addItemCollection, allMyCollections, getCollection, updateItemCollection } from '../../services/items';
import MultiItemSelector from '../shared/MultiItemSelector';

function AddEditItemCollection() {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [saveError, setSaveError] = useState<string>(null);

  const [name, setName] = useState("");
  const [items, setItems] = useState<SearchResult[]>([]);

  const { collection_id } = useParams();
  const auth = useContext(AuthContext);
  const { processApiError, setError } = useContext(ErrorContext);

  useEffect(() => {
    if (collection_id) {
      setLoading(true);

      getCollection(parseInt(collection_id), ["collection_items", "collection_items.item"])
        .then(d => {
          if (d.data.created_by === auth.user?.user_id || auth.user?.is_admin) {
            setName(d.data.name);
            setItems(d.data.collection_items.map(i => ({
              id: i.item_id,
              name: i.item.name,
              which: "item",
              image_url: i.item.image_url
            })));
          }
          else {
            setError("401", "You can only edit your own collections.");
          }
        })
        .catch(processApiError)
        .finally(() => setLoading(false));
    }
  },
    // eslint-disable-next-line
    [collection_id, auth.user]
  );
  
  const save = async () => {
    setSaveError(null);
    setSaving(true);

    try {
      if (!collection_id) {
        addItemCollection(name, items.map(i => i.id))
          .then(() => {
            setName("");
            setItems([]);
          })
      }
      else {
        await updateItemCollection(parseInt(collection_id), name, items.map(i => i.id));
      }

      setSuccessShow(true);
      setTimeout(() => setSuccessShow(false), 4000);
      
      allMyCollections.refresh();
    }
    catch (err) {
      processApiError(err);
      setSaveError(err);
    }
    finally {
      setSaving(false);
    }
  }
 
  return (
    <>
      <Header className="relaxed">
        {collection_id ? "Update" : "Add New"}  Collection
        <Header.Subheader>
          Think of collections as folders of items. They can be from our library or items you upload or a combination.
          Collections are really handy for games. Usually you want a game to be based around a lesson, but sometimes what you (or your student) need
          is some of your favorite princesses. That's what collections are for.
        </Header.Subheader>
      </Header>

      <Form loading={loading} onSubmit={save}>
        <Form.Input required label="Collection name" placeholder='Please enter a collection name' fluid value={name} onChange={(_, d) => setName(d.value)} />

        <Form.Field>
          <label>Add some items to your collection</label>
          <MultiItemSelector placeholder="Find items..." items={items} onChange={setItems} />
        </Form.Field>

        <Button primary loading={saving} disabled={saving}>{collection_id ? "Update" : "Add"} Collection</Button>
        {successShow && <div className="saved-message"><TextWithEmoji emoji="tada" text="Saved!" /></div>}
        {saveError && <div className="error-message"><TextWithEmoji emoji="sweat" text={saveError} /></div>}
      </Form>
    </>
  )
}

export default AddEditItemCollection;