import { Picker } from 'emoji-mart';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Confirm, Form, Header, Label, Popup, Segment } from 'semantic-ui-react';

import { Student } from '../../../../api/src/models/student.entity';
import { AuthContext } from '../../contexts/auth';
import { TextWithEmoji } from '../../services/html';
import {
  addStudent,
  allMyStudents,
  CurrentStudent,
  deleteStudent,
  getStudent,
  StudentBackgrounds,
  updateStudent,
} from '../../services/students';
import MustBeSubscribed from '../shared/MustBeSubscribed';
import { ErrorContext } from '../../contexts/error';

function AddEditStudent() {
  const auth = useContext(AuthContext);
  
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [saveError, setSaveError] = useState<string>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [student, setStudent] = useState<Partial<Student>>({
    default_positive_icon: auth.user.default_positive_icon,
    default_negative_icon: auth.user.default_negative_icon
  });
  const [relationship, setRelationship] = useState(20);

  const { student_id } = useParams();
  const { push } = useHistory();
  const { processApiError } = useContext(ErrorContext);

  useEffect(() => {    
    if (student_id) {
      setLoading(true);

      getStudent(parseInt(student_id))
        .then(d => {
          setStudent(d.data);
          setRelationship(d.data.user_students.find(us => us.user_id === auth.user.user_id).relationship_id);
        })
        .catch(processApiError)
        .finally(() => setLoading(false));
    }
  }, 
    // eslint-disable-next-line
    [student_id, auth.user]
  );
  
  const save = async () => {
    setSaveError(null);
    setSaving(true);

    try {
      if (!student_id) {
        await addStudent(student, relationship)
          .then(_ => setStudent({...student, name: ""}));
      }
      else {
        await updateStudent(parseInt(student_id), student);
      }

      setSuccessShow(true);
      setTimeout(() => setSuccessShow(false), 4000);
      
      allMyStudents.refresh();
    }
    catch (err) {
      processApiError(err);
      setSaveError(err);
    }
    finally {
      setSaving(false);
    }
  }

  const remove = async () => {
    setSaveError(null);
    setSaving(true);
    setConfirmOpen(false);

    try {
      if (CurrentStudent?.student?.student_id === parseInt(student_id)) {
        CurrentStudent.set(null);
      }

      await deleteStudent(parseInt(student_id));
      allMyStudents.refresh();
      push("/profile/student");
    }
    catch (err) {
      processApiError(err);
      setSaveError(err);
    }
    finally {
      setSaving(false);
    }
  }
 
  return (
    <>
      <Header className="relaxed">
        {student_id ? "Update" : "Add a"}  Student
        <Header.Subheader>
          A student is anyone you're working with. It's super handy to add them here because then you can customize their favorite emojis,
          backgrounds and other things. Hint: Picking emojis with your students is a fun activity!
           
          <div style={{marginTop: "1em"}}><strong>We're serious about HIPAA. Please add just enough information to identify the student - nickname, initials, etc.</strong></div>
        </Header.Subheader>
      </Header>

      <MustBeSubscribed validateWhen={student_id == null} tryingCount={allMyStudents.current().length + 1} limitsByPlan={{"Pika": 1, "Yeti": 5, "Godzilla": 30}} upgradeMessage="We're super happy that you and your students are enjoying Guess Monster but you need to upgrade to add more students.">
        <Form loading={loading} onSubmit={save}>
          <Form.Input required label="Student's name" placeholder='Please enter a first name or initials' fluid value={student.name || ""} onChange={(_, d) => setStudent({ ...student, name: d.value })} />

          {!student_id && (
            <Form.Select value={relationship} label={`I am ${student.name || 'the student'}'s...`}
              options={[{ value: 10, text: "Relative" }, { value: 20, text: "Educator/Therapist" }]}
              onChange={(_, d) => setRelationship(d.value as number)}
            />
          )}

          <Form.Field>
            <label>Pick {student.name ? `${student.name}'s` : 'his/her'} favorite positive and negative emojis for the games that use them</label>
            <Popup hoverable on="click" trigger={(
              <Label as='a' basic image color="green">
                <TextWithEmoji text="Positive" emoji={student.default_positive_icon} size={30} />
              </Label>
            )}>
              <Popup.Content><Picker title='Pick your emoji!' emoji='point_up' onSelect={e => setStudent({ ...student, default_positive_icon: e.id })} /></Popup.Content>
            </Popup>
            <Popup hoverable on="click" trigger={(
              <Label as='a' basic image color="red">
                <TextWithEmoji text="Negative" emoji={student.default_negative_icon} size={30} />
              </Label>
            )}>
              <Popup.Content><Picker title='Pick your emoji!' emoji='point_up' onSelect={e => setStudent({ ...student, default_negative_icon: e.id })} /></Popup.Content>
            </Popup>
          </Form.Field>

          <Form.Select value={student.other_student_settings?.background_url} label={`How about a background?`} clearable
            options={StudentBackgrounds.map(b => ({ text: b.name, value: b.url, style: { background: `url(${b.url})` }}))}
            onChange={(_, d) => setStudent({ ...student, other_student_settings: { ...student.other_student_settings, background_url: d.value as string } })}
          />

          
          <Button primary loading={saving} disabled={saving}>{student_id ? "Update" : "Add"} Student</Button>
          {successShow && <div className="saved-message"><TextWithEmoji emoji="tada" text="Saved!" /></div>}
          {saveError && <div className="error-message"><TextWithEmoji emoji="sweat" text={saveError} /></div>}

          {student_id && (
            <Segment color="red" style={{ marginTop: "3em" }}>
              <Header>Remove Student</Header>
              If you no longer work with {student.name}, you can remove him/her.
              <br /><br />

              <Button negative loading={saving} disabled={saving} onClick={() => setConfirmOpen(true)}>Remove {student.name}</Button>
              <Confirm open={confirmOpen} onCancel={() => setConfirmOpen(false)} onConfirm={remove} confirmButton={{ content: `Remove ${student.name}`, negative: true }} />
            </Segment>
          )}
        </Form>
      </MustBeSubscribed>
    </>
  )
}

export default AddEditStudent;