import React from 'react';
import { Link } from 'react-router-dom';
import { Header, List } from 'semantic-ui-react';

import { FakeLink, ExternalLink } from '../../services/html';
import { StudentBackgrounds } from '../../services/students';

function FAQ() {
  const info_link = <ExternalLink href="mailto:info@guessmonster.com?subject=I need more Guess Monster in my life!">info@guessmonster.com</ExternalLink>;

  return (
    <>
      <Header className="relaxed">Frequenty Asked Questions</Header>
      
      <List size="large" relaxed className="faq">
        <List.Item>
          <List.Header>Can I use this for teletherapy?</List.Header>
          Absolutely! All the games work great over screen sharing tools like Zoom and Google Meet and you 
          even get the <em>Yay!</em> sounds that kids love (unless you're wearing headphones because then only you'll hear them), 
          but the Catch game might have a little lag on slow screen sharing connections which may make it difficult for some young children.
        </List.Item>
        <List.Item>
          <List.Header>How does subscription work?</List.Header>
          A subscription is for one person. The Pika (smallest) level is perfect for parents or small 
          practices. The Yeti is great for therapists with lots of clients that want to personalize the 
          experience for each child. And Godzilla is just the thing for someone in a school setting. We can 
          also support enterprise clients - please contact us at {info_link} for details. <Link to="/pricing">See full pricing details here.</Link>
        </List.Item>
        <List.Item>
          <List.Header>Why is Guess Monster better than a couple of the other options out there?</List.Header>
          We are taking a completely different approach. Everyone else is just taking the same materials that 
          have been in use for 50 years and putting it online. That's OK, but we can do <em>much better</em> than 
          digital versions of the ditto sheets our parents used. We use modern approaches in 
          Machine Learning to create "tagged datasets" of teachable items. We cover over 300 lessons at a 
          precison higher than anything else out there. For example, we don't just look at "CH words" - 
          here are <em>actual lessons</em> you'll get to choose from with children on CH:
          <List size="small">
            <List.Item><List.Header>Ch /tʃ/ - Initial</List.Header>Words that BEGIN with the phoneme /tʃ/ "ch" like "cheese" and "chowder".</List.Item>
            <List.Item><List.Header>Ch /tʃ/ - Medial</List.Header>Words that have the phoneme /tʃ/ "ch" in the MIDDLE of the word such as the "ch" in "teacher". NOT blends such as "search".</List.Item>
            <List.Item><List.Header>Ch /tʃ/ - Final</List.Header>Words that END in the phoneme /tʃ/ "ch" such as "coach". NOT blends such as "match".</List.Item>
            <List.Item><List.Header>Ch /tʃ/ - Blends Medial</List.Header>Words that have a blend such as 'tch' or 'nch' in the middle of the word such as "crunchy".</List.Item>
            <List.Item><List.Header>Ch /tʃ/ - Blends Final</List.Header>Words that contain a blend such as 'tch' or 'nch' at the END of the word such as "munch" or "itch".</List.Item>
            <List.Item><List.Header>Ch /tʃ/ - Blends Mixed</List.Header>Contains a 'ch' blend in any word position such as "crunch" or "itchy". ONLY blends.</List.Item>
            <List.Item><List.Header>Ch /tʃ/ - Mixed</List.Header>Words that have 'ch' in any word position "chai," "teacher" or "coach". NOT blends.</List.Item>
            <List.Item><List.Header>Ch /tʃ/ - All</List.Header>Any word containing a 'ch' in ANY word position or combination. Blends are included.</List.Item>
          </List>

          But that's not all! We have lessons around Color Identification, Farm Animals, Concepts and more!
        </List.Item>
        <List.Item>
          <List.Header>Can I share a subscription with someone?</List.Header>
          Honestly, we can't stop you and we understand that budgets are tight, and this might be OK for 
          some folks (we know how it is!), but you'd be doing yourself a disservice. There are a lot of 
          customizations and child-specific settings (<FakeLink onClick={() => { document.body.style.background = `url(${StudentBackgrounds[0].url})`; window.setTimeout(() => document.body.style.background = null, 7000);}}>click here for a taste</FakeLink>) that you'd have a hard time managing when sharing an 
          account with someone else. Additionally, <strong>we're very serious about HIPAA</strong> and you don't want to share 
          an account with someone when you're naming your students.
        </List.Item>
        <List.Item>
          <List.Header>What if I have an idea for a game or a feature?</List.Header>
          We add games, features and recipes <em>daily</em> and we LOVE hearing from our users. No idea is bad. Please, <Link to="/help">tell us what you're thinking</Link> and we'll do our best to make it a reality. Really.
        </List.Item>
        <List.Item>
          <List.Header>Do you have a marketplace for me to sell my game or resources?</List.Header>
          We don't have a marketplace right now because we're curating very specific games and lessons - we don't want to turn into just a library of PDFs like everyone else out there - we can <em>all</em> do better. 
          However, if you have a great idea or resources that you think fits into our approach, please reach out and we can find ways to collaborate - {info_link}!
        </List.Item>

      </List>
    </>
  )
}

export default FAQ