import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import useResizeAware from 'react-resize-aware';
import Treemap from 'react-vis/dist/treemap';
import { Button, Dropdown, DropdownProps, Header, Modal } from 'semantic-ui-react';

import { Lesson, LessonCategory } from '../../../../../api/src/models/lesson.entity';
import { AuthContext } from '../../../contexts/auth';
import { ErrorContext } from '../../../contexts/error';
import { PlayContext } from '../../../contexts/play';
import { toDropDownSource } from '../../../services/html';
import { getLesson, getLessonCategoriesForTree } from '../../../services/lessons';
import { PagedSearchResult, searchSpecific } from '../../../services/search';

type LessonSearchDropdownProps = DropdownProps & {
  onItemSelected?: (selected: Lesson) => void;
  inGame?: boolean;
  mode: "dropdown" | "tree";
  trigger?: any;
};

const categories_color_range = ["#4a852f", "#6abd45"];
const lessons_color_range = ["#3c78b1", "#2e5f8d"];

function LessonSearchDropdown(props: LessonSearchDropdownProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState<Lesson>(null);
  const [results, setResults] = useState<PagedSearchResult<Lesson>>();
  const [loading, setLoading] = useState(false);
  const [magicSearchData, setMagicSearchData] = useState<{ title: string, category_id: number, children: LessonCategory[] | any[] }>()
  const [treeColorRange, setTreeColorRange] = useState<string[]>(categories_color_range);
  const [treeBackTo, setTreeBackTo] = useState<number>();
  const [resizeListener, sizes] = useResizeAware();
  const [modalOpen, setModalOpen] = useState(false);
  
  const { processApiError } = useContext(ErrorContext);
  const play = useContext(PlayContext);
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (props.mode === "tree")
      magicDrill();
  },
    // eslint-disable-next-line 
    []
  );

  useEffect(() => {
    if (props.inGame && play?.lesson) {
      if (!selected || play.lesson.lesson_id !== selected.lesson_id) {
        if (results && results.data.includes(play.lesson)) {
          setSelected(play.lesson);
        }
        else {
          ReactDOM.unstable_batchedUpdates(() => {
            setResults({ data: [play.lesson], count: 1, page: 1, total: 1, pageCount: 1 })
            setSelected(play.lesson);
          });
        }
      }
    }
  },
    // eslint-disable-next-line
    [play?.lesson]
  );

  useEffect(() => {
    if (searchQuery.trim() !== "") {
      setLoading(true);

      const fq = {
        "$and": [{
          "$or": [
            { "name": { "$contL": searchQuery } },
            { "description": { "$contL": searchQuery } },
            { "category.name": { "$contL": searchQuery } },
          ]},
          { "is_draft": false }
        ]
      }
        
      searchSpecific<Lesson>("lesson", fq, ["category"], 1, 40)
        .then(r => setResults(r.data))
        .catch(processApiError)
        .finally(() => setLoading(false));
    }
  }, 
    // eslint-disable-next-line
    [searchQuery]
  );

  useEffect(() => {
    if (props.onItemSelected) {
      props.onItemSelected(selected);
    }

    if (props.inGame && play && selected?.lesson_id !== play.lesson?.lesson_id) {
      if (selected.category_id) {
        props.onItemSelected(selected);
      }
      else {
        getLesson(selected.lesson_id).then(r => play.setLesson(r.data));
      }
    }

    if (props.mode === "tree") {
      setModalOpen(false);
    }
  }, 
    // eslint-disable-next-line
    [selected]
  );

  const onUpdateQuery = useCallback(_.debounce(setSearchQuery, 300), [searchQuery]);

  const magicDrill = (node?: { data: LessonCategory, parent: any }, root_id?: number) => {
    root_id = node?.data.category_id || root_id;

    if ((node?.data as any)?.lesson_id != null) {
      setSelected({
        lesson_id: parseInt((node.data as any).lesson_id),
        name: (node.data as any).lesson_name,
      } as Lesson);
    }
    else if (!node || !root_id || node.data.children_count > 0) {
      getLessonCategoriesForTree(root_id)
        .then(r => ReactDOM.unstable_batchedUpdates(() => {
          setMagicSearchData({
            title: "Guess Monster Magic Search",
            category_id: root_id,
            children: r.data
              .filter(c => c.lesson_summary || c.children_count > 0) // filter any that don't have any lessons
              .map((c, i) => ({
                ...c,
                title: <span title={c.name}>{c.name}</span>,
                children: null, //c.children?.map(ch => ({...ch, size: 1, title: c.name, color: ch.category_id})), 
                size: 1,// c.children_count || 1,
                color: i,
              }))
          });

          setTreeColorRange(categories_color_range);
          setTreeBackTo(node?.data?.parent_id);
        }))
        .catch(processApiError);
    }
    else {
      ReactDOM.unstable_batchedUpdates(() => {
        setMagicSearchData({
          title: "Guess Monster Magic Search",
          category_id: root_id,
          children: Object.keys(node.data.lesson_summary).map((l, i) => ({
            name: node.data.lesson_summary[l], // for sorting
            title: <span title={node.data.lesson_summary[l]}>{node.data.lesson_summary[l]}</span>,
            lesson_id: parseInt(l),
            lesson_name: node.data.lesson_summary[l],
            size: 1,
            color: i
          }))
        });

        setTreeColorRange(lessons_color_range);
        setTreeBackTo(node?.data?.parent_id);

        // if only 1 lesson, set it
        if (Object.keys(node.data.lesson_summary).length === 1) {
          setSelected({
            lesson_id: parseInt(Object.keys(node.data.lesson_summary)[0]),
            name: Object.values(node.data.lesson_summary)[0]
          } as Lesson);
        }
      })
    }
  }

  if (props.mode === "dropdown") {
    return (
      <Dropdown {..._.omit(props, ["inGame", "onItemSelected"])} search={props.search ? o => o : null} noResultsMessage="Type to search lessons..."
        selectOnNavigation={false} selectOnBlur={false} loading={loading}
        onSearchChange={(_, d) => onUpdateQuery(d.searchQuery)}
        options={toDropDownSource(results?.data || [], "name", "lesson_id", l => l.category?.icon || "point_right", null, l => auth.user?.is_admin && l.item_count ? `${l.item_count} item(s)` : null, "description")}
        value={selected?.lesson_id} onChange={(_, d) => setSelected((results.data.find(l => l.lesson_id === d.value as number)))}
      />
    )
  }
  else {
    return magicSearchData ? (
      <>        
        <Modal dimmer="inverted" trigger={props.trigger} open={modalOpen} onOpen={() => setModalOpen(true)}>
          <Header icon="magic" content="Lesson Magic Search" subheader={<div className="sub header">Click <strong>greenish categories</strong> below to drill down to <strong>blueish lessons</strong>!</div>} />
          <Modal.Content>
            {resizeListener}
            <Treemap hideRootNode colorRange={treeColorRange} sortFunction={(a: any, b: any) => a.data.name < b.data.name ? -1 : 1} style={{ cursor: "pointer", fontWeight: "bold" }} padding={0} data={magicSearchData} width={sizes.width} height={500} onLeafClick={magicDrill} animation={true} />
          </Modal.Content>
          <Modal.Actions>
            <Button icon="close" content="Cancel" labelPosition="right" onClick={() => setModalOpen(false)} />

            {props.inGame && play.lesson && 
              <Button icon="erase" content="Clear Lesson" labelPosition="right" negative onClick={() => { play.setLesson(null); setModalOpen(false); }} />
            }
                        
            <Button icon="angle double up" content="Restart" labelPosition="right" primary disabled={magicSearchData.category_id == null} onClick={() => magicDrill()} />
            <Button icon="angle up" content="Level Up" labelPosition="right" primary disabled={magicSearchData.category_id == null} onClick={() => magicDrill(null, treeBackTo)} />
          </Modal.Actions>
        </Modal>
      </>
    ) : null
  }


}

export default LessonSearchDropdown;