import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Dimmer,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  List,
  Popup,
  Segment,
} from 'semantic-ui-react';

import { GameName } from '../../../../api/src/types/game';
import { AuthContext } from '../../contexts/auth';
import { ErrorContext } from '../../contexts/error';
import { LookupsContext } from '../../contexts/lookups';
import { PlayContext } from '../../contexts/play';
import { allAttributes, allAttributeValues } from '../../services/attributes';
import { registerGamePlay } from '../../services/gameplays';
import { addGameRecipe, deleteGameRecipe, compareRecipes } from '../../services/gamerecipes';
import { TextWithEmoji, toDropDownSource, useQuery } from '../../services/html';
import { allMyCollections } from '../../services/items';
import { allItemTypes } from '../../services/itemtypes';
import { GamePlanAccess } from '../../services/payment';
import { CurrentStudent } from '../../services/students';
import { GameComponents, GameLoader } from '../shared/GameBase';
import MustBeSubscribed from '../shared/MustBeSubscribed';
import GameThemeSearchDropdown from '../shared/searchers/GameThemeSearchDropdown';
import LessonSearchDropdown from '../shared/searchers/LessonSearchDropdown';
import TourPlayer from '../shared/TourPlayer';
import UpgradeLink from '../shared/UpgradeLink';

function PlayGame () {
  const auth = useContext(AuthContext);
  const { 
    recipeDefinition, theme, lesson, updateRecipeDefinition, setRecipeById, 
    setRecipeByFeatureId, initialFeatured, playItems 
  } = useContext(PlayContext);
  const { processApiError } = useContext(ErrorContext);
  const { myGameRecipes } = useContext(LookupsContext);

  const [newRecipeName, setNewRecipeName] = useState("");
  const [forceTour, setForceTour] = useState(false);

  const query = useQuery();
  const { push } = useHistory();
  
  const initial_recipe_id = query.get("recipe_id");
  const initial_feature_id = query.get("feature_id");
  const { game_name } = useParams();

  const playRegisterer = useRef(null);

  useEffect(() => {
    if (initial_recipe_id) { // like /play/...?recipe_id=123
      setRecipeById(parseInt(initial_recipe_id));
    }
    else if (initial_feature_id) { // like /play/...?feature_id=123
      setRecipeByFeatureId(parseInt(initial_feature_id))
    }
    else if (game_name) { // like /play/catch
      const details = GameComponents.find(g => _.kebabCase(g.name) === game_name);

      if (details.name !== recipeDefinition?.game_name) {
        updateRecipeDefinition({ 
          game_name: details.name,
          ...details.default_recipe
        })
      }
    }
    else { // like /play
      updateRecipeDefinition({
        number_of_items: 8,
        emphasize: "item name",
        game_name: "Guess Monster"
      });
    }

    return () => {
      clearTimeout(playRegisterer.current);
    }
  }, 
    // eslint-disable-next-line
    [initial_recipe_id, initial_feature_id]
  )

  useEffect(() => {
    if (recipeDefinition.game_name && recipeDefinition.game_name !== game_name) {
      // console.log("pushing to", recipeDefinition.game_name)
      push({
        pathname: `/game/play/${_.kebabCase(recipeDefinition.game_name)}`,
        search: query.toString(),
      })
    }
  }, 
    // eslint-disable-next-line
    [recipeDefinition.game_name]
  );

  useEffect(() => {
    if (playRegisterer.current != null) {
      clearTimeout(playRegisterer.current);
    }

    const t = setTimeout(() => {
      registerGamePlay(initial_recipe_id ? parseInt(initial_recipe_id) : null, recipeDefinition, CurrentStudent.student?.student_id)
    }, 45000); // 45 seconds

    playRegisterer.current = t;
  }, [recipeDefinition, initial_recipe_id])

  const saveRecipe = async () => {
    try {
      await addGameRecipe({
        name: newRecipeName.trim() === "" ? (new Date()).toLocaleDateString() : newRecipeName.trim(),
        recipe: recipeDefinition
      }, theme);

      myGameRecipes.refresh();
    }
    catch (err) {
      processApiError(err)
    }
  }

  const deleteRecipe = async (recipe_id: number) => {
    try {
      await deleteGameRecipe(recipe_id);
      myGameRecipes.refresh();
    }
    catch (err) {
      processApiError(err)
    }
  }
  
  return (
    <>
      <Dimmer.Dimmable as={Segment} dimmed={auth?.user?.subscription_level_id == null}>
        <Dimmer inverted active={auth?.user?.subscription_level_id == null}>
          <Header size="medium" style={{color: "black"}}>
            There is a world of Speech Therapy and other educational games behind here.<br/ >
            <em>Quadrillions</em> of combinations that are fun for any child <em>and</em> adult.<br />
            <Divider />
            Enjoy this FREE featured game then <Link to="/pricing">jump into a 14 day free trial!</Link><br />
            Make this the last time you have to pay for Speech Therapy materials.
          </Header>
        </Dimmer>
        
        <Label as="a" color="blue" corner="right" icon="question" onClick={() => setForceTour(true)} />

        <Grid columns="equal" stackable className="tour-recipe recipe-grid" padded="horizontally">
          <Grid.Column width={2}>
            Show me... {playItems?.items && playItems.items.length < recipeDefinition.number_of_items && <Popup trigger={<Icon name="warning sign" color="yellow" link />}>You picked a recipe combination that only has {playItems.items.length} item(s). We're adding new items <em>daily</em>. If you're trying to run a session that needs {recipeDefinition.number_of_items} items, chat us on the bottom right and we'll prioritize your items - promise!</Popup>}<br />
            <Input fluid placeholder='all' type="number" value={recipeDefinition.number_of_items || 8} onChange={(_, d) => updateRecipeDefinition({ number_of_items: d.value !== "" ? parseInt(d.value) : null })} />
          </Grid.Column>
          <Grid.Column color={recipeDefinition.item_type_id && recipeDefinition.item_type_id.length > 0 ? "purple" : null}>
            Of...<br />
            <Dropdown search selection floating fluid multiple placeholder="any&nbsp;category" className="menu-grid wider-dropdown"
              options={allItemTypes.current() ? toDropDownSource(allItemTypes.current(), (i) => i.name, "type_id", (i) => i.icon, "any category") : []}
              value={recipeDefinition.item_type_id || []} onChange={(_, d) => updateRecipeDefinition({ item_type_id: d.value as number[] })}
            />
          </Grid.Column>
          <Grid.Column color={recipeDefinition.collection_id ? "purple" : null}>
            From...<br />
            <Dropdown search selection floating fluid placeholder="any&nbsp;collection" className="wider-dropdown menu-grid"
              options={allMyCollections.current() ? toDropDownSource(allMyCollections.current(), (i) => i.name, "collection_id", null, "any collection") : []}
              value={recipeDefinition.collection_id} onChange={(_, d) => updateRecipeDefinition({ collection_id: d.value as number })}
            />
          </Grid.Column>
          <Grid.Column color={recipeDefinition.attribute_id || (recipeDefinition.attribute_value_id && recipeDefinition.attribute_value_id.length > 0 )? "purple" : null}>
            Specifically...<br />
            <Dropdown search selection floating fluid multiple direction="left" placeholder="any&nbsp;values" className="wider-dropdown menu-grid"
              options={allAttributeValues.current() ? toDropDownSource(allAttributeValues.current(), (i) => `${i.attribute.name}: ${i.value}`, "value_id", (i) => i.attribute.icon, "any value") : []}
              value={recipeDefinition.attribute_value_id || []} onChange={(_, d) => updateRecipeDefinition({ attribute_value_id: d.value as number[], attribute_id: null })}
            />
            {(recipeDefinition.attribute_value_id == null || recipeDefinition.attribute_value_id.length === 0) && (
              <div>
                &nbsp;for&nbsp;
                <Dropdown inline search placeholder="any attribute"
                  options={allAttributes.current() ? toDropDownSource(allAttributes.current(), "name", "attribute_id", "icon", "any attribute") : []}
                  value={recipeDefinition.attribute_id} onChange={(_, d) => updateRecipeDefinition({ attribute_id: d.value as number, attribute_value_id: [] })}
                />
              </div>
            )}
          </Grid.Column>
          <Grid.Column color={recipeDefinition.lesson_id ? "purple" : null}>
            Working on...<br />
            <LessonSearchDropdown inGame mode="tree" trigger={<Dropdown placeholder="any lesson" fluid selection options={lesson ? [{text: lesson.name, value: lesson.lesson_id}] : []} value={lesson ? lesson.lesson_id : null} />} />

            {lesson && (
              <div>
                &nbsp;match on&nbsp;
                <Dropdown inline floating placeholder="anything"
                  options={_.compact([lesson.match_items ? {text: "just the item", value: "item"} : null, lesson.match_attribute_values ? {text: "item and attributes", value: "attribute value"} : null])}
                  value={recipeDefinition.lesson_match_depth} onChange={(_, d) => updateRecipeDefinition({ lesson_match_depth: d.value as any})}
                />
              </div>
            )}
          </Grid.Column>
          <Grid.Column>
            Emphasize...<br />
            <Dropdown selection floating fluid
              options={toDropDownSource([{ value: "item category" }, { value: "item name" }, { value: "attribute value" }, { value: "image" }, { value: "lesson match" }, { value: "nothing" }], "value", "value", null)}
              value={recipeDefinition.emphasize} onChange={(_, d) => updateRecipeDefinition({ emphasize: d.value as any })}
            />
            {recipeDefinition.emphasize === "attribute value" && (
              <>
                &nbsp;for&nbsp;
                <Dropdown inline search placeholder="pick an attribute"
                  options={toDropDownSource(allAttributes.current(), "name", "attribute_id", "icon")}
                  value={recipeDefinition.emphasize_attribute_id} onChange={(_, d) => updateRecipeDefinition({ emphasize_attribute_id: d.value as number })}
                />
              </>
            )}
          </Grid.Column>
        </Grid>
                  
        <Divider />

        <Grid columns="equal" stackable className="recipe-grid" padded="horizontally">
          <Grid.Column>
            <Dropdown button fluid labeled icon="game" className='icon basic tour-games' placeholder="Game"
              selectOnNavigation={false}
              options={toDropDownSource(GameComponents, "name", "name", null, null, null, null, (g) => (auth.user?.subscription_level_id || 0) < GamePlanAccess[g.name])}
              value={recipeDefinition.game_name} onChange={(_, d) => updateRecipeDefinition({ game_name: d.value as GameName })}
            />
          </Grid.Column>
          <Grid.Column>
            <GameThemeSearchDropdown mode="modal" inGame trigger={
              <Dropdown button fluid labeled icon="paint brush" className="icon basic tour-themes" placeholder="Pick a theme..."
                options={theme ? [{text: theme.name, value: theme.theme_id}] : []} value={theme ? theme.theme_id : null}
              />}
            />
          </Grid.Column>
          <Grid.Column>
            <Popup on="click" position="bottom right" trigger={<Button fluid className="tour-save-recipe" icon="star outline" positive content="Recipes" />}>
              <Popup.Content>
                {myGameRecipes.current() && (
                  <>
                    <MustBeSubscribed tryingCount={myGameRecipes.current().length + 1} limitsByPlan={{ "Pika": 1, "Yeti": 5, "Godzilla": Infinity }}
                      fallback={<UpgradeLink><TextWithEmoji size={20} emoji="admission_tickets" text="Upgrade for more recipes!" style={{ marginTop: "-3px" }} /></UpgradeLink>}>

                      <Input value={newRecipeName} onChange={(_, d) => setNewRecipeName(d.value)} placeholder="Recipe name..." action={{ positive: true, icon: "save", onClick: saveRecipe }} />
                    </MustBeSubscribed>
                    <Divider />
                    {myGameRecipes.current().length > 0 && (
                      <List selection verticalAlign='middle'>
                        {myGameRecipes.current().map(r => (
                          <List.Item key={r.recipe_id} onClick={() => setRecipeById(r.recipe_id)}>
                            {!r.is_public && (
                              <List.Content floated='right'>
                                <Icon onClick={() => deleteRecipe(r.recipe_id)} link name='trash alternate outline' color="red" />
                              </List.Content>
                            )}

                            <List.Content>{r.name}</List.Content>
                          </List.Item>
                        ))}
                      </List>
                    )}

                    {myGameRecipes.current().length === 0 && <div>Save a recipe to see it here!</div>}
                  </>
                )}
              </Popup.Content>
            </Popup>
          </Grid.Column>
        </Grid>
      </Dimmer.Dimmable>

      {recipeDefinition.game_name ? (
        <MustBeSubscribed validateWhen={!initial_feature_id || !compareRecipes(initialFeatured?.recipe?.recipe, recipeDefinition, true)} key={`${recipeDefinition.game_name}-${initial_recipe_id}`} minLevel={GamePlanAccess[recipeDefinition.game_name]}>
          <GameLoader game={GameComponents.find(g => g.name === recipeDefinition.game_name).component} />
        </MustBeSubscribed>
      ) : `Please pick a game above to start playing!` }

      <TourPlayer user={auth.user} tour="Game Overview" force={forceTour} onFinish={() => setForceTour(false)} />
    </>
  );
}

export default PlayGame;