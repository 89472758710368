import { Emoji } from 'emoji-mart';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import Fullscreen from 'react-full-screen';
import { Dropdown, Image, Menu, Message } from 'semantic-ui-react';

import { ItemDisplayLocation } from '../../../../api/src/models/gametheme.entity';
import { AuthContext } from '../../contexts/auth';
import { PlayContext } from '../../contexts/play';
import { ToolsContext } from '../../contexts/tools';
import {
  getItemDisplayLocation,
  ItemDisplayLocationsContainerAdjustment,
  ItemDisplayLocationsItemAdjustment,
} from '../../services/gamethemes';
import { TextWithEmoji } from '../../services/html';
import { getImageThumbUrl } from '../../services/images';
import { getCurrentStudentNegativeEmoji, getCurrentStudentPositiveEmoji } from '../../services/students';
import { GameProps } from '../shared/GameBase';
import GameTools from './GameTools';
import ItemEmphasizer from './ItemEmphasizer';

type StateOptions = "active" | "correct" | "incorrect";

function CheckOff(props: GameProps) {
  const { playItems, recipeDefinition, theme, updateGameOptions: setGameOptions, onFinish} = useContext(PlayContext);
  const auth = useContext(AuthContext);
  const tools = useContext(ToolsContext);

  const [itemStateMap, setItemStateMap] = useState<{ [id: number]: StateOptions }>({});
  const [itemLocation, setItemLocation] = useState<ItemDisplayLocation>(recipeDefinition.game_options?.item_location || getItemDisplayLocation("Check Off", theme, "top"));
  const [itemScatterMap, setItemScatterMap] = useState<{ [id: number]: [number, number] }>({});
  const [retries, setRetries] = useState(0);
  const [justFinished, setJustFinished] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);
  
  const positive_icon = getCurrentStudentPositiveEmoji(auth.user);
  const negative_icon = getCurrentStudentNegativeEmoji(auth.user);

  useEffect(() => {
    setGameOptions({ game_name: "Check Off" });
  }, 
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    setItemStateMap({});

    if (Object.keys(itemScatterMap).length !== playItems.items.length) {
      const getR = () => {
        const r = (Math.random() * 100) - 100 / playItems.items.length;
        return r > 0 ? r : 0;
      }

      setItemScatterMap(playItems.items.reduce((acc, cur, i) => { acc[i] = [getR(), getR()]; return acc; }, {}));
    }
  },
    // eslint-disable-next-line
    [playItems]
  );

  const containerStyle: React.CSSProperties = {
    position: "relative", 
    display: "flex", 
    backgroundImage: `url(${theme.background_url})`, 
    backgroundSize: "cover", 
    height: isFullScreen || itemLocation === "random" ? "100vh" : null, 
    width: "100%",
    alignItems: "flex-start",
    paddingTop: "5em",
    paddingBottom: "5em",

    ...theme.container_style,
  }

  const ulStyle: React.CSSProperties = {
    listStyle: "none", 
    padding: 0, 
    margin: 0, 
    position: "relative", 
    display: "flex", 
    flexWrap: "wrap", 
    justifyContent: "space-around", 
    width: "100%",
    height: "100%",

    ...ItemDisplayLocationsContainerAdjustment[itemLocation]
  }

  const liStyle: React.CSSProperties = {
    margin: "1em",
    padding: "1em",
    width: 200,
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",

    ...theme.item_style,
    ...ItemDisplayLocationsItemAdjustment[itemLocation]
  }

  const textStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

    ...theme.font_style
  }

  useEffect(() => {
    const allDone = Object.values(itemStateMap).length === playItems.items.length && Object.values(itemStateMap).every(i => i === "correct");
    
    if (allDone && onFinish && !justFinished) {
      setJustFinished(true);
      onFinish();
    }
  },
    // eslint-disable-next-line
    [itemStateMap, justFinished, playItems]
  );

  const getStateFor = (id: number) => {
    return itemStateMap[id] || "active";
  }

  const getScatterFor = (index: number) => {
    return itemLocation !== "random" ?
      {} as React.CSSProperties :
      {
        position: "absolute",
        left: `${itemScatterMap[index][0]}%`,
        top: `${itemScatterMap[index][1]}%`
      } as React.CSSProperties
  }

  const clickItem = (e: React.MouseEvent, type: "click" | "contextmenu", id: number) => {
    const state = getStateFor(id);
    setJustFinished(false);
    
    if (state === "active" && type === "click") {
      setItemStateMap({ ...itemStateMap, [id]: "correct" });
      tools.playSound("positive");
    }
    else if (state === "active" && type === "contextmenu") {
      setItemStateMap({ ...itemStateMap, [id]: "incorrect" });
      tools.playSound("negative");
    }
    else {
      setItemStateMap({ ...itemStateMap, [id]: "active" });
      setRetries(retries+1);
    }

    if (type === "contextmenu")
      e.preventDefault();
  }

  return Object.keys(itemScatterMap).length === playItems.items.length ? (
    <>
      <Message info className="tour-game-instructions"><TextWithEmoji emoji="point_right" text="Left click to mark an item correct or right click to mark it incorrect. Click again to retry." /></Message>
      
      <Menu attached="top" className="tour-game-menu">
        <Dropdown item icon="align left" trigger={<React.Fragment />}
          options={Object.keys(ItemDisplayLocationsContainerAdjustment).map(s => ({ text: s, value: s }))}
          value={itemLocation} onChange={(_, d) => setItemLocation(d.value as ItemDisplayLocation)}
        />
        
        <Menu.Menu position="right">
          <Menu.Item><Emoji emoji={positive_icon} size={20} />&nbsp;&nbsp;{Object.values(itemStateMap).filter(i => i === "correct").length}</Menu.Item>
          <Menu.Item><Emoji emoji={negative_icon} size={20} />&nbsp;&nbsp;{Object.values(itemStateMap).filter(i => i === "incorrect").length}</Menu.Item>
          <Menu.Item title="Retries"><Emoji emoji='leftwards_arrow_with_hook' size={20} />&nbsp;&nbsp;{retries}</Menu.Item>
        </Menu.Menu>
      </Menu>

      <Fullscreen enabled={isFullScreen} onChange={f => setFullScreen(f)}>
        <div style={containerStyle} id="game-container">
          <GameTools screenshot fullscreen dice confetti draw isFullScreen={isFullScreen} onFullScreenToggle={() => setFullScreen(!isFullScreen)} />

          <ul style={ulStyle}>
            <AnimatePresence initial={false}>
              {playItems.items.map((item, i) => (
                <li style={{...liStyle, ...(getScatterFor(i))}} key={item.item_id} onContextMenu={(e) => clickItem(e, "contextmenu", item.item_id)} onClick={(e) => clickItem(e, "click", item.item_id)}>
                  <motion.div key={`${item.item_id}-${getStateFor(item.item_id)}`} style={{display: "flex", flexDirection: "column", alignItems: "center", height: "100%", justifyContent: "center", width: "100%"}}
                    initial={{opacity: 0}}
                    animate={{opacity: 1}} 
                    exit={{opacity: 0}} 
                    transition={{ duration: 2 }}
                  >
                    {getStateFor(item.item_id) === "active" && <Image draggable="false" src={getImageThumbUrl(item.image_url)} style={{display: "flex", maxHeight: "100%"}} /> }
                    {getStateFor(item.item_id) === "correct" && <Emoji emoji={positive_icon} size={64} />}
                    {getStateFor(item.item_id) === "incorrect" && <Emoji emoji={negative_icon} size={64} />}

                    <div style={textStyle}>
                      <ItemEmphasizer item={item} recipe={recipeDefinition} ignoreImage />
                    </div>
                  </motion.div>

                </li>
              ))}
            </AnimatePresence>
          </ul>
        </div>
      </Fullscreen>
    </>
  ) : null
};

export default CheckOff;