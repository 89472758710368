import firebase from 'firebase';

firebase.initializeApp({
  apiKey: "AIzaSyDc6U_3DtfDe-Q_PtfzYKVEphIHsS80SzE",
  authDomain: "guess-monster.firebaseapp.com",
  databaseURL: "https://guess-monster.firebaseio.com",
  projectId: "guess-monster",
  storageBucket: "guess-monster.appspot.com",
  messagingSenderId: "49543391853",
  appId: "1:49543391853:web:120e20b2dc1bec2feced64",
  measurementId: "G-HQLR9CKX45"
});

firebase.analytics();

export default firebase;