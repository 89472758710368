import React, { useContext, useEffect, useState } from 'react';

import { User } from '../../../api/src/models/user.entity';
import firebase from '../config/firebase';
import { logLoginEvent } from '../services/analytics';
import { PlanDetails } from '../services/payment';
import { loginUser } from '../services/user';
import { ErrorContext } from './error';

export type AuthDetails = {
  firebase_checked: boolean
  firebase_user?: firebase.User,
  user?: User
}

export const AuthContext = React.createContext<AuthDetails & {updateUser: (u:User) => void}>(undefined);

export const AuthContextProvider:React.FC = (props) => {
  const [details, setDetails] = useState<AuthDetails>({
    firebase_checked: false
  });

  const { processApiError } = useContext(ErrorContext);

  const updateUser = (u: User) => setDetails({...details, user: u});

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      if (user && details.user == null) {
        let gmuser:User = null;
        
        try {
          loginUser(user)
            .then(r => {
              gmuser = r.data;
              logLoginEvent(gmuser);

              setDetails({
                firebase_checked: true,
                firebase_user: user,
                user: gmuser
              });
            });
        }
        catch (err) {
          console.log("login problem", err)
        }

        // set up freshchat
        if (gmuser) {
          try {
            const chatSetup = () => {
              window["fcWidget"].setExternalId(`GuessMonster-user-${gmuser.user_id}`);
              window["fcWidget"].user?.setFirstName(gmuser.name.split(" ")[0]);
              window["fcWidget"].user?.setEmail(gmuser.email);

              window["fcWidget"].user?.setProperties({
                plan: gmuser.subscription_level_id ? PlanDetails[gmuser.subscription_level_id].name : "None"
              });
            }
            
            if (window["fcWidget"]) {
              chatSetup();
            }
            else {
              // super hacky - wait 10 seconds
              window.setTimeout(chatSetup, 10000)
            }
          }
          catch(err) {
            console.log("could not link user in chat", err);
          }
        }     
      }
      else {
        setDetails({
          firebase_checked: true,
          firebase_user: null,
          user: null
        });
      }
    }, processApiError);

    return () => {
      unsubscribe();
    };
  }, 
  // eslint-disable-next-line
  []);

  return <AuthContext.Provider value={{...details, updateUser}}>{props.children}</AuthContext.Provider>
}