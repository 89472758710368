import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Label } from 'semantic-ui-react';

import { SearchResult } from '../../../../api/src/types/search';
import { AuthContext } from '../../contexts/auth';
import { getImageThumbUrl } from '../../services/images';

type ItemQuickListProps = {
  items: SearchResult[];
  onRemove?: (item: SearchResult) => void;
}

function ItemQuickList(props: ItemQuickListProps) { 
  const auth = useContext(AuthContext);

  return props.items.length > 0 ? (
    <Label.Group style={{ marginTop: ".5em" }}>
      {props.items.sort((a, b) => a.name < b.name ? -1 : 1).map(f => (
        <Label basic image key={f.id} size="big" style={{ marginTop: ".3em" }}>
          <img alt={f.name} src={getImageThumbUrl(f.image_url)} style={{ padding: ".3em" }} />
          {auth.user?.is_admin ? <Link to={`/admin/item/item/${f.id}`} target="_blank">{f.name}</Link> : f.name}
          {props.onRemove && <Icon name='delete' onClick={() => props.onRemove(f)} /> }
        </Label>
      ))}
    </Label.Group>
  ) : null
}

export default ItemQuickList;