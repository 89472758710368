import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import AttributesHome from '../attributes/Home';
import ItemsHome from '../items/Home';
import ItemTypesHome from '../itemtypes/Home';
import LessonsHome from '../lessons/Home';
import GameThemesHome from '../themes/Home';
import AdminSelfCheck from './AdminSelfCheck';
import AdminSocialMedia from './AdminSocialMedia';

function AdminHome () {
  let { path } = useRouteMatch();
  
  return (
    <Switch>
      <Route path={`${path}/attribute`}><AttributesHome /></Route>
      <Route path={`${path}/item`}><ItemsHome /></Route>
      <Route path={`${path}/lesson`}><LessonsHome /></Route>
      <Route path={`${path}/type`}><ItemTypesHome /></Route>
      <Route path={`${path}/theme`}><GameThemesHome /></Route>
      <Route path={`${path}/social`}><AdminSocialMedia /></Route>
      <Route path={`${path}/self-check`}><AdminSelfCheck /></Route>
    </Switch>
  );
}

export default AdminHome;