import axios from 'axios';
import firebase from '../config/firebase';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT
});

client.interceptors.request.use(req => {
  const u = firebase.auth().currentUser;

  if (! u) {
    return req;
  }
  else {
    return u.getIdToken()
      .then(t => {
        req.headers["Authorization"] = `Bearer ${t}`;
        return req;
      });
  }
});

export default client;