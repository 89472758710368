import React, { useState } from 'react';
import useSound from 'use-sound';

type Sounds = "positive" | "negative";

export enum DrawModes {
  Hidden = -2,
  Behind = 0,
  Active = 2
}

export type ToolContextOptions = {
  confetti: boolean;
  toggleConfetti: () => void;
  dice: boolean
  toggleDice: () => void;
  sound: boolean;
  toggleSound: () => void;
  playSound: (sound: Sounds) => void;
  draw: DrawModes;
  toggleDraw: () => void;
}

export const ToolsContext = React.createContext<ToolContextOptions>(undefined);

export const ToolsContextProvider:React.FC = (props) => {
  const [confetti, setConfetti] = useState(false);
  const [dice, setDice] = useState(false);
  const [sound, setSound] = useState(true);
  const [draw, setDraw] = useState(DrawModes.Hidden);

  const [yay] = useSound("/yay.m4a");
  const [uh] = useSound("/uh.m4a");

  const play = (which: Sounds) => {
    if (!sound) return;
    
    switch(which) {
      case "positive": yay(); break;
      case "negative": uh(); break;
    }
  }

  return <ToolsContext.Provider value={{
    confetti, 
    dice, 
    sound, 
    draw,
    toggleConfetti: () => setConfetti(!confetti),
    toggleDice: () => setDice(!dice),
    toggleSound: () => setSound(!sound),
    toggleDraw: () => setDraw(draw === DrawModes.Hidden ? DrawModes.Active : draw-2),
    playSound: (s) => play(s)
  }}>{props.children}</ToolsContext.Provider>
}