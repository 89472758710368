import { Emoji } from 'emoji-mart';
import { useObserver } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Header, Segment } from 'semantic-ui-react';

import { AuthContext } from '../../contexts/auth';
import { PlanDetails, SubscriptionLevels } from '../../services/payment';
import UpgradeLink from './UpgradeLink';

interface MustBeSubscribedProps {
  minLevel?: SubscriptionLevels;
  tryingCount?: number;
  limitsByPlan?: { "Pika": number, "Yeti": number, "Godzilla": number };
  validateWhen?: boolean;
  fallback?: React.ReactNode;
  upgradeMessage?: string;
}

interface FallbackProps {
  minLevel: SubscriptionLevels;
  message?: string;
}

export const DefaultFallback = (props: FallbackProps) => {
  return (
    <Segment placeholder style={{ marginTop: "2em", minHeight: 0 }}>
      <Header size="small" icon>
        <p><Emoji emoji="admission_tickets" size={60} /></p>
        {!props.message ? (
          <div>This really awesome feature, along with many others, is <UpgradeLink>available in the <strong>{PlanDetails[props.minLevel].name}</strong> plan</UpgradeLink>.</div>
        ) : (
          <div>{props.message} <UpgradeLink>Upgrade to <strong>{PlanDetails[props.minLevel].name}</strong>!</UpgradeLink></div>
        )}
      </Header>
    </Segment>
  )
}

const MustBeSubscribed: React.FC<MustBeSubscribedProps> = props => {
  const auth = useContext(AuthContext);

  let must_upgrade = false;
  let min_level_needed: SubscriptionLevels = props.minLevel || 0;

  if (props.validateWhen == null || props.validateWhen) {
    if (props.minLevel) {
      must_upgrade = (auth.user?.subscription_level_id || 0) < props.minLevel;
    }
    else if (props.tryingCount) {
      if (props.tryingCount > props.limitsByPlan["Godzilla"])
        min_level_needed = null; // they need to call us
      else if (props.tryingCount > props.limitsByPlan["Yeti"])
        min_level_needed = SubscriptionLevels.Large;
      else if (props.tryingCount > props.limitsByPlan["Pika"])
        min_level_needed = SubscriptionLevels.Medium;
      else
        min_level_needed = SubscriptionLevels.Small;

      must_upgrade = min_level_needed === 0 || auth.user?.subscription_level_id < min_level_needed;
    }
  }
  
  return useObserver(() => (
    <>
      { !must_upgrade ? props.children : props.fallback || <DefaultFallback minLevel={min_level_needed} message={props.upgradeMessage} />}
    </>
  ));
}

export default MustBeSubscribed;